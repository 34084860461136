let encodeReserveRE = /[!'()*]/g;
let encodeReserveReplacer = function (c) { return '%' + c.charCodeAt(0).toString(16); };
let commaRE = /%2C/g;

// fixed encodeURIComponent which is more conformant to RFC3986:
// - escapes [!'()*]
// - preserve commas
let encode = function (str) { return encodeURIComponent(str)
	.replace(encodeReserveRE, encodeReserveReplacer)
	.replace(commaRE, ','); };


module.exports = {
	stringifyQuery: function stringifyQuery (obj) {
		let res = obj ? Object.keys(obj).map(function (key) {
			let val = obj[key];

			if (val === undefined) {
				return ''
			}

			if (val === null) {
				return encode(key)
			}

			if (Array.isArray(val)) {
				let result = [];
				val.forEach(function (val2) {
					if (val2 === undefined) {
						return
					}
					if (val2 === null) {
						result.push(encode(key));
					} else {
						result.push(encode(key) + '=' + encode(val2));
					}
				});
				return result.join('&')
			}

			return encode(key) + '=' + encode(val)
		}).filter(function (x) { return x.length > 0; }).join('&') : null;
		return res ? ("?" + decodeURIComponent(res)) : ''
	},
};
