import Vue from 'vue';
import {cloneDeep} from '../../../../helpers/_lodash';
import _laxios from "../../_laxios";

export default {
	namespaced: true,
	state: {
		lib: {},
		hash: {}
	},
	actions: {
		fetch: ({commit, getters, rootGetters}, {category} = {}) => {
			let {'lang/id': langId} = rootGetters;
			if (category && !getters.hash({category, langId})) {
				return _laxios.bodies.expandUrl({category}).request({params: {langId}})
					.then((payload) => {
						if (payload) {
							commit('hash', {category, langId});
							commit('set', {payload, langId, category})
						}
					}).catch(() => {
						console.error('Ошибка получения типа кузова');
					});

			}
		},
	},
	mutations: {
		set(state, {payload, langId, category} = {}) {
			payload
				.forEach(item => {
					const {value, name} = item;
					let {[value]: {name: nameOld = {}} = {}} = state.lib;
					item.name = Object.assign(nameOld, {[langId]: name});
					item.category = category;
					Vue.set(state.lib, value, item);
				});
		},
		hash(state, {category, langId} = {}) {
			Vue.set(state.hash, `${langId}_${category}`, true);
		}
	},
	getters: {
		l10n: (state, getters, rootState, rootGetters) => {
			const {'lang/id': langId} = rootGetters;
			const stateCloned = cloneDeep(state.lib);
			Object
				.values(stateCloned)
				.forEach((item = {}) => {
					const {name: {[langId]: name} = {}, value} = item;
					if (name) {
						item.name = name;
					} else {
						delete stateCloned[value];
					}

				});
			return stateCloned;
		},
		bodiesByCategory: (state, getters, rootState, rootGetters) => {
			return function (category, equipmentType = -1) {
				const {'lang/id': langId} = rootGetters;
				const stateCloned = cloneDeep(state.lib);
				return Object
					.values(stateCloned)
					.map((item = {}) => {
						const {name: {[langId]: name} = {}} = item;
						if (name) {
							item.name = name;
							return item;
						}
					})
					.filter(body => {
						return body && body.category === category &&
							(equipmentType < 0 || body.parentId === equipmentType);
					});
			};
		},
		body(state, getters, rootState, rootGetters) {
			return function (id) {
				const {'lang/id': langId} = rootGetters;
				const body = cloneDeep(state.lib[id]) || {};
				let {name: {[langId]: name} = {}} = body;
				body.name = name;
				return body;
			};
		},
		hash(state) {
			return function ({langId, category}) {
				return state.hash[`${langId}_${category}`];
			};
		}
	}
}


