import _laxios from "../../../_laxios";
import {ADD_GENERATION, DELETE_GENERATION, RESET_GENERATIONS} from "../mutations/mutation-types";
import getGenerationKey from "../helpers/getGenerationKey";

export default {
    async fetchGeneration({commit, rootGetters}, {blockIndex, brandId, modelId, years, exclude }) { // приймає в modelId як массив так і одне значення

        const requestParams = {
            responseType: 'json',
            params: { active: 1, sort: 1 },
        }

        const modelArray = Array.isArray(modelId) ? modelId : [modelId];

        if(years.hasOwnProperty('gte')) requestParams.params.yearFrom = years.gte;
        if(years.hasOwnProperty('lte')) requestParams.params.yearTo = years.lte;

        try {
            const generationsList = await Promise.all(
                modelArray.map(async (id) => {
                    const generationResult = await _laxios
                        .getGenerationByModelId
                        .expandUrl({id})
                        .request(requestParams)

                    return generationResult[0]
                }))

            const generations = generationsList
                .filter(el => el && el.generations.length)
                .reduce((acc, el) => {
                    const blockKey = getGenerationKey({blockIndex, exclude, brandId, modelId: el.id });

                    commit(DELETE_GENERATION, {blockKey});
                    acc[blockKey] = el

                    return acc;
                }, {})


            commit(ADD_GENERATION, generations);

            return generations

        } catch (e) {
            console.error('Error: Get Generation List');
        }
    },
    deleteGeneration({commit}, { blockIndex, exclude, brandId, modelId }){
        const blockKey = getGenerationKey({blockIndex, exclude, brandId, modelId });

        commit(DELETE_GENERATION, {blockKey});
    },
    addGenerationToSeries({commit}, {seriesData, seriesIndex, fieldName, generationId }) {
        let freshSeriesData = Object.assign({}, seriesData);

        if(freshSeriesData.hasOwnProperty(fieldName)) {
            freshSeriesData[fieldName] = [...freshSeriesData[fieldName], generationId];
        } else {
            freshSeriesData[fieldName] = [generationId];
        }

        commit('search/state2/addByIndex',{
            name: 'series',
            index: seriesIndex,
            value: freshSeriesData
        }, { root: true });
    },
    deleteGenerationFromSeries({commit, dispatch}, {seriesData, seriesIndex, fieldName, generationId}){
        if(seriesData[fieldName].length > 1 &&  seriesData[fieldName].includes(generationId)) {
            // Якщо покоління не одне встановлене в полі покоління - то видаляємо тільки id
            seriesData[fieldName] = seriesData[fieldName].filter(el => el !== generationId);
            if(seriesData['modification']?.length) {
                seriesData['modification'] = seriesData['modification'].filter(el => el.generationId !== generationId);
            }
        } else {
            // В іншому випадку ми видаляємо дане покоління - або тільки id або повністю поле
            delete seriesData[fieldName];
            delete seriesData['modification']
        }

        commit('search/state2/addByIndex',{
            name: 'series',
            index: seriesIndex,
            value: seriesData
        }, { root: true })
    },
    resetGeneration({ commit }) {
        commit(RESET_GENERATIONS);
    },
}
