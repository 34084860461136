import _laxios from '../../_laxios/index';

export default {
	fetch: ({commit, getters, rootGetters}, {size = 30, categoryId, type = 'bu'} = {}) => {
		let {'lang/id': langId} = rootGetters;
		const params = {langId, size, categoryId, type};
		const id = JSON.stringify(params);
		if (!getters.getById(id)) {
			return _laxios.transportCategories.request({params})
				.then((body) => {
					if (body) {
						commit('save', {id, payload: body})
					}
				})
				.catch((error) => {
					console.error('Ошибка получения категории транспорта', _laxios.transportCategories.query.url, error.message);
					console.log("params", params)
				})
		}
	}
};
