import _laxios from "../../../_laxios";
import {ADD_MODIFICATION, DELETE_MODIFICATION, RESET_MODIFICATIONS} from "../mutations/mutation-types";
import getModificationKey from "../helpers/getModificationKey";

const checkSeriesByLastGenerationWithModification = ({seriesData,  generationId}) => {
    if(!seriesData?.modification?.length) return true;

    return !Boolean(seriesData.modification.find(el => el.generationId === generationId))
}

export default {
    async fetchModifications({commit, rootGetters}, {blockIndex, brandId, modelId, generationId, exclude }) {
        const requestParams = {
            responseType: 'json',
            params: { active: 1, sort: 1 },
        }
        const blockKey = getModificationKey({blockIndex, exclude, brandId, modelId, generationId });

        try {
            const modifications = await _laxios
                .getModificationByGenerationId
                .expandUrl({id: generationId})
                .request(requestParams);

            if(modifications.length) {
                const modificationData = {
                    brandId,
                    modelId,
                    generationId,
                    modifications
                }

                commit(DELETE_MODIFICATION, { blockKey });
                commit(ADD_MODIFICATION, { [blockKey] : modificationData });

                return modifications.map( el => el.value );
            }
        } catch (e) {
            console.error('Error: Get Modifications List', e);
        }
    },

    async fetchAllModificationsByModel({ commit }, { blockIndex, brandId, modelId, exclude }) {
        const requestParams = {
            responseType: 'json',
            params: { active: 1, sort: 1 },
        }


        try {
            const modifications = await _laxios
                .getAllModificationsByModel
                .expandUrl({modelId})
                .request(requestParams);

            const generationModifications = modifications.reduce((acc, modification) => {
                const { generation } = modification;

                if(acc.hasOwnProperty(generation)) {
                    acc[generation] = {
                        generationId: generation,
                        modifications: [...acc[generation].modifications, modification]
                    }
                } else {
                    acc[generation] = {
                        generationId: generation,
                        modifications: [modification]
                    }
                }

                return acc
            }, {})

            Object.values(generationModifications).forEach(({ generationId, modifications }) => {
                const blockKey = getModificationKey({blockIndex, exclude, brandId, modelId, generationId });

                const modificationData = {
                    brandId,
                    modelId,
                    generationId,
                    modifications
                }
                commit(ADD_MODIFICATION, { [blockKey]: modificationData })
            })

        } catch(e) {
            console.log('FETCH E')

            console.log('Fetch Modification Error:', e)
        }
    },

    deleteModificationList ({state, commit}, { blockIndex, exclude, brandId, modelId, generationId }) {
        const blockKey = getModificationKey({blockIndex, exclude, brandId, modelId, generationId });

        commit(DELETE_MODIFICATION, {blockKey});
    },

    addModificationToSeries({ commit }, { seriesData, seriesIndex, fieldName, generationId, modificationData }) {
        let freshSeriesData = Object.assign({}, seriesData);

        if(freshSeriesData.hasOwnProperty(fieldName)) {
            freshSeriesData[fieldName] = [...freshSeriesData[fieldName], {...modificationData, generationId}];
        } else {
            freshSeriesData[fieldName] = [ {...modificationData, generationId}];
        }

        // Додаємо id покоління якщо вибрали модифікацію раніше покоління
        if(freshSeriesData?.generation) {
            if(!freshSeriesData.generation.includes(generationId)) {
                freshSeriesData.generation = [...seriesData.generation, generationId]
            }
        } else {
            freshSeriesData.generation = [generationId]
        }

        commit('search/state2/addByIndex',{
            name: 'series',
            index: seriesIndex,
            value: freshSeriesData
        }, { root: true });
    },

    deleteModificationFromSeries({ commit }, { seriesData, seriesIndex, fieldName, generationId, modificationData }) {
        const { value: modificationId } = modificationData;

        const modificationIsExistsInSeries = seriesData[fieldName].map(el => el.value).includes(modificationId)

        if(seriesData[fieldName].length > 1 && modificationIsExistsInSeries) {
            // Якщо покоління не одне встановлене в полі покоління - то видаляємо тільки modification
            seriesData[fieldName] = seriesData[fieldName].filter(el => el.value !== modificationId);
        } else {
            // В іншому випадку ми видаляємо данне покоління
            delete seriesData[fieldName];
        }

        const isLastModificationsWithSameGeneration = checkSeriesByLastGenerationWithModification({seriesData, generationId})

        if(isLastModificationsWithSameGeneration) {
            seriesData.generation = seriesData.generation.filter(el => el !== generationId)
        }


        commit('search/state2/addByIndex',{
            name: 'series',
            index: seriesIndex,
            value: seriesData
        }, { root: true })
    },

    resetModification({ commit }){
        commit(RESET_MODIFICATIONS)
    },
}
