import Vue from 'vue';
import _laxios from '../_laxios';

export default {
	namespaced: true,
	state: {
		users: {},
	},
	actions: {
		fetch({ commit, getters }) {
			if (!Object.keys(getters.getAccounts).length) {
				return _laxios.getAllUsers
					.request()
					.then(({users = {}}) => {
						commit('setUsers', users);
					})
					.catch(e => console.error('accountSwitches:fetch', e));
			}
		},
		deleteUserFromGroup({ commit }, {userId}) {
			return _laxios.deleteUserFromGroup
				.expandUrl({ userId })
				.request()
				.then(({status = 0, users = {}}) => {
					if (status && Object.keys(users).length) {
						commit('deleteUser', userId);
					}
				})
				.catch(e => console.error('accountSwitches:deleteUserFromGroup', e));
		},
		checkPspId({}, {userId, params}) {
			return _laxios.checkPspId
				.expandUrl({ userId })
				.request({params})
				.then(data => data)
				.catch((error) => Promise.reject(error.response));

		},
	},
	mutations: {
		setUsers(state, users) {
			Vue.set(state, 'users', users);
		},
		deleteUser(state, id) {
			Vue.delete(state.users, id);
		}
	},
	getters: {
		getAccounts(state) {
			return state.users;
		},
	}
};
