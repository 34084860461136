export default [
	{
		id: 2,
		code: 'ru',
		prefix: '',
		prefixNewAuto: '/ru',
	},
	{
		id: 4,
		code: 'uk',
		prefix: '/uk',
		prefixNewAuto: '',
	},
];
