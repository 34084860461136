import categories from './categories';
import subCategories from './subCategories';
import bodies from './bodies';
import brands from './brands';
import models from './models';
import generation from './generation';
import modification from './modification';
import locations from './locations';
import device from './device';
import url from './url';
import bn from './bn';
import seo from './seo';
import countries from './countries';
import fuels from './fuels';
import colors from './colors';
import drives from './drives';
import ecoStandarts from './ecoStandarts';
import colorTypes from './colorTypes';
import gearboxes from './gearboxes';
import cities from './cities';
import options from './options';
import relation from './relation';
import advertisement from './advertisement';
import gdrp from './gdrp';

export default {
  namespaced: true,
  modules: {
    url,
    brands,
    models,
    generation,
    modification,
    locations,
    categories,
    subCategories,
    device,
    bn,
    seo,
    bodies,
    countries,
    fuels,
    colors,
    drives,
    ecoStandarts,
    colorTypes,
    gearboxes,
    cities,
    options,
    relation,
    gdrp,
    advertisement,
  },
};
