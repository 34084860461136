import Vue from 'vue';
import {advertisement, personalization, aar, statistic, deleteReason, getOrder} from '../../queries';
import _laxios from "../../_laxios";

export default {
	namespaced: true,
	state: {
		search: [],
		personalization: {},
		aar: {},
		statistic: {},
		deleteReason: {},
		order: {}
	},
	actions: {
		search({commit, getters, rootGetters}, {query, order, searchType} = {}) {
			commit('search');

			let {'lang/id': langId} = rootGetters;

			const data = {
				query: require('../../_graphql/queries/advertisements'),
				variables: {
					params: [
						query
					],
					searchType,
					order,
					langId
				}
			};

			return _laxios.graphql.request({data})
				.then(({data: {searchCommon} = {}} = {}) => commit('search', {payload: searchCommon, searchType}));
		},
		fetch({commit, getters, rootGetters}, {id} = {}) {
			let {'lang/id': langId} = rootGetters;
			let promises = [];
			if (!getters.get(id)) {
				promises.push(advertisement({id, langId})
					.then(payload => {
						let {data} = payload;
						commit('set', {payload: data, id});
					}));
			}
			if (!getters.personalization(id)) {
				promises.push(personalization({id, langId})
					.then(payload => {
						let {data} = payload;
						commit('personalization', {payload: data, id});
					}));
			}
			if (!getters.aar(id)) {
				promises.push(aar({id})
					.then(payload => {
						let {data} = payload;
						commit('aar', {payload: data, id});
					}));
			}
			return Promise.all(promises);
		},
		statistic({commit}, {id} = {}) {
			return statistic({id})
				.then(payload => {
					let {data} = payload;
					commit('statistic', {payload: data, id});
				});
		},
		deleteReason({commit}, {id} = {}) {
			return deleteReason({id})
				.then(payload => {
					let {data} = payload;
					commit('deleteReason', {payload: data, id});
				});
		},
		order({commit, rootGetters}, {id} = {}) {
			let {'lang/id': langId} = rootGetters;
			return getOrder({advertisementId: id, langId})
				.then(payload => {
					let {data} = payload;
					commit('order', {payload: data, id});
					console.log('DEBUG: index 59', data);
					return data;
;				});
		}
	},
	mutations: {
		set(state, {payload, id} = {}) {
			Vue.set(state, id, payload);
		},
		personalization(state, {payload, id} = {}) {
			Vue.set(state.personalization, id, payload);
		},
		aar(state, {payload, id} = {}) {
			if (payload) {
				Vue.set(state.aar, id, false);
			} else {
				Vue.set(state.aar, id, true);
			}
		},
		statistic(state, {payload, id} = {}) {
			Vue.set(state.statistic, id, payload);
		},
		deleteReason(state, {payload, id} = {}) {
			Vue.set(state.deleteReason, id, payload);
		},
		order(state, {payload, id} = {}) {
			Vue.set(state.order, id, payload);
		},
		search(state, {payload = []} = {}) {
			state.search.splice(0, state.search.length, ...payload);
		},
	},
	getters: {
		get(state) {
			return function (id) {
				return state[id];
			};
		},
		personalization(state) {
			return function (id) {
				return state.personalization[id];
			};
		},
		aar(state) {
			return function (id) {
				return state.aar[id];
			};
		},
		statistic(state) {
			return function (id) {
				return state.statistic[id];
			};
		},
		deleteReason(state) {
			return function (id) {
				return state.deleteReason[id];
			};
		},
		order(state) {
			return function (id) {
				return state.order[id];
			};
		},
		search(state) {
			return state.search;
		}
	}
};
