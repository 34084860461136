import _laxios from '../_laxios';

export default {
  namespaced: true,
  state: {},
  actions: {
    fetchData({ rootGetters }, { advertisementId, queryType = 'allData', queryParams = {} }) {
      const { 'lang/id': langId } = rootGetters;
      const { 'Common/userData': _auth } = rootGetters;
      const params = { langId };
      Object.assign(params, queryParams);

      return _laxios.stepPublication
        .expandUrl({ advertisementId, queryType })
        .request({ params })
        .then((payload) => payload)
        .catch((e) => {
          console.warn(`[publication/fetchData] ${e.message}`);
          console.error('/api/publication-page/auto/{advertisementId}/{queryType}/', e.message);
        });
    },
    logPublicationStep({ rootGetters }, params = {}) {
      return _laxios.publicationStepLogger.request({ params });
    },
    createPaymentWindow({ rootGetters }, {
      advertisementId, requestData, buttonName, forceAction,
    }) {
      const { 'shared/device/isDesktop': isDesktop } = rootGetters;
      const billingWindow = isDesktop
        ? window.open('https://auto.ria.com/demo/partials/loader/wait.html', '_blank')
        : window;

      return _laxios.publicationPayment
        .expandUrl({ advertisementId, query: forceAction ? '?userForcesAction=1' : '' })
        .request({ data: requestData })
        .then((data) => {
          if (data?.id) {
            const oplatyLink = [
              'https://oplaty.ria.com/auto/order/',
              '?personal_account_project_id=1',
            ].join(data.id);
            // открываем окно оплат если создался счёт
            switch (buttonName) {
              case 'publicate_privat':
                return _laxios.publicationPaymentPrivat24
                  .request({ data: { orderId: data?.id } })
                  .then((response) => {
                    billingWindow.window.location.href = response.paymentUrl;
                    // використовується для логів аналітиків
                    return { billingId: data.id, paymentType: 115, paymentSum: data?.spentPromoMoney };
                  })
                  .catch((e) => {
                    console.warn(`[publication/createPaymentWindow/publicationPaymentPrivat24] ${e.message}`);
                    console.error(e);
                    billingWindow.window.location.href = oplatyLink;
                  });
              case 'publicate':
              default:
                billingWindow.location.href = oplatyLink;
                // використовується для логів аналітиків
                return { billingId: data.id, paymentType: 5000, paymentSum: data?.spentPromoMoney };
            }
          }

          // иначе закрываем окно оплат
          if (isDesktop) billingWindow.window.close();
        }).catch((e) => {
          if (isDesktop) billingWindow.window.close();
          console.warn(`[publication/createPaymentWindow] ${e.message}`);
          console.error(e);

          return e;
        });
    },
  },
};
