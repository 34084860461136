import _laxios from "../../../_laxios";
import {UPDATE_ADVERTISEMENT_IDENTIFIER} from "../mutations/mutation-types";
import normilizeIdentifier from "./normilizeIdentifier";

export default {
    searchAPI({rootGetters}, params) {
        let data = {
            params
        };

        return _laxios.searchAPI
            .request(data)
            .catch(e => {
                console.error('error --->', e.message);
            });
    },
    updateIdentifier({commit}, payload) {
        let {name, value} = payload;
        const fieldData = {name}

        if(value) fieldData.value = normilizeIdentifier(value);

        commit(UPDATE_ADVERTISEMENT_IDENTIFIER, value);

        commit('search/state2/setValue', fieldData, { root: true });
    }
}
