import Vue from 'vue';

import actions from './actions';
import newAuto from './newAuto';

export default {
	namespaced: true,
	modules: {
		newAuto
	},
	state: {
		base: {},
		popular: {},
		suggests: [],
	},
	actions,
	mutations: {
		save(state, payload = {}) {
			Object
				.keys(payload)
				.forEach(key => {
					const itemNew = payload[key];
					const itemOld = state.base[key];
					const {
						country: countryOld,
						category: categoryOld
					} = itemOld || {};
					const {
						country: countryNew,
						category: categoryNew
					} = itemNew || {};

					const country = countryOld || countryNew;
					const category = Array.isArray(categoryOld) ? categoryOld : [];

					if(category.indexOf(categoryNew) === -1) {
						category.push(categoryNew);
					}
					Vue.set(state.base, key, {...itemNew, category, country});
				});
		},
		popular(state, {id, payload} = {}){
			if(Array.isArray(payload)){
				Vue.set(state.popular, id, payload)
			}
		},
		suggest(state, payload) {
			state.suggests.splice(0, state.suggests.length, ...payload)
		}
	},
	getters: {
		getAll: (state) => state.base,
		brand(state){return function(id){return state.base[id]}},
		popular(state){return function(id){return state.popular[id]}},
		getByCategory(state) {
			return function (category) {
				return category ?
					Object
						.keys(state.base)
						.reduce((result, key) => {
							const {[key]: {category: itemCategory} = {}} = state.base;
							if (Array.isArray(itemCategory) && itemCategory.indexOf(category) !== -1) {
								result[key] = state.base[key]
							}
							return result;
						}, {})
					: state.base
			}
		},
		suggests: (state) => state.suggests
	}
}


