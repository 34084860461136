import SelectAutoBlock from '../../Autotest/SelectAuto/index.vue';

export default {
  name: 'CabinetAutoCheck',
  data() {
    return {};
  },
  components: {
    'select-auto-block': SelectAutoBlock,
  },
};
