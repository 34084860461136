export default {
	beforeMount() {
		const {query: {noads} = {}} = this.$route;
		if (!noads) {
			googletag = googletag || {};
			googletag.cmd = googletag.cmd || [];
			googletag.cmd.push(function () {
				googletag.pubads().enableSingleRequest();
				googletag.pubads().collapseEmptyDivs();
				googletag.pubads().enableLazyLoad({
					fetchMarginPercent: 300,
					renderMarginPercent: 100,
					mobileScaling: 2.0
				});
				googletag.enableServices();
			});
		}
	}
}
