module.exports = {
	name: 'store',
	props: ['value'],
	render(createElement) {
		try {
			return createElement(
				'div',
				Object.assign({}, this.$attrs, {style: {display: 'none'}}),
				JSON.stringify(this.value)
			);
		} catch (error) {
			console.error(error);
			return createElement('div');
		}
	},
	beforeMount() {
		try {
			this.$emit('input', JSON.parse(this.$el.innerText));
		} catch (error) {
			console.error(error);
		}
	},
};
