export default function getAutoInterSearchRequestParams (ctx, field) {
    let { getters, rootGetters } = ctx;
    const {
        category,
        brand,
        model,
        country,
        year,
        region,
        price
    } = getters

    const  {'lang/id': langId, 'shared/device/isDesktop': isDesktop } = rootGetters;
    let requestParams = {
        responseType: 'json',
        params: {
            langId,
            value: 0,
            site_id: 0,
            currentDevice: isDesktop ? 'desktop' : 'mobile',
            vehicleClass_id: category?.vehicleId || 0,
            category_id: 0,
            make_id: brand?.id || 0,
            model_id: model?.id || 0,
            country_int: country?.id || 0,
        }
    }


    switch(field) {
        case 'category': {
            requestParams.params = {
                ...requestParams.params,
                catalog_ranges: 5,
                vehicleClass_id: 0
            }
            break;
        }
        case 'brand': {
            requestParams.params = {
                ...requestParams.params,
                catalog_ranges: 1,
            };
            break;
        }
        case 'model': {
            let { brand } = getters;
            if(!brand) {
                requestParams = null;
            }
            requestParams.params = {
                ...requestParams.params,
                catalog_ranges: 2,
            };
            break;
        }
        case 'year': {
            requestParams.params = {
                catalog_ranges: 10,
            };
            break;
        }
        case 'country': {
            requestParams.params = {
                catalog_ranges: 3,
            };
            break;
        }
        default: {
            return null
        }
    }
    return requestParams;
}
