import currencies from '../../constants/currencies';

export default {
	namespaced: true,
	state: {},
	getters: {
		currencyByName() {
			return function (name) {
				return currencies.find(({name: itemName}) => itemName === name);
			};
		},
		getCurrencyLabelByName() {
			return function (name) {
				const {label} = currencies.find(({name: itemName}) => itemName === name) || {};
				return label;
			};
		}
	}
};
