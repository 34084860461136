export default {
    getCurrentTab: (state) => state.currentSearchTab,
    getTabsData: (state) => Object.values(state.searchTabs),
    getAllFields: (state) => {
        const tabsData = Object.values(state.searchTabs);

        return tabsData.reduce((acc, tab) => {
            acc = { ...acc,...tab.fields }
            return acc
        }, {})
    },
    getCurrentTabFields: (state) => state.searchTabs[state.currentSearchTab].fields,
    getPeriodFieldData: (state) => state.period,
    getOmniIdData: (state) => state.searchTabs["byOmniId"].fields['omniId'].value,
    getLoggerData: (state) => state.loggerData,
    getLoggerDataDefault: (state) => state.loggerDataDefault,
    getFormData: (state) => {
        return Object.values(state.searchTabs[state.currentSearchTab].fields).reduce((acc, field) => {
            if (field.value && typeof field.value !== 'object') acc[field.fieldName] = field.value;
            if (typeof field.value === 'object' && field.value !== null) {
                Object.keys(field.value).forEach(el => {
                    if (field.value[el]) {
                        if (acc[field.fieldName]) {
                            acc[field.fieldName] = Object.assign(
                                {},
                                acc[field.fieldName],
                                {[el]: field.value[el]}
                            )
                        } else {
                            acc[field.fieldName] = {
                                [el]: field.value[el]
                            }
                        }
                    }
                })
            }
            return acc
        }, {})
    }
}
