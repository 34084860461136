import {
    UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_MESSAGE,
    UPDATE_AVERAGE_PRICE_FIELD_VALIDATOR
} from "../mutations/mutations-types";

export const checkRequiredField = ({commit}, {fields}) => {
    const fieldsEntries = Object.entries(fields)
    const invalidFields = fieldsEntries.reduce(
        (acc, [key, data]) => {
            if(data.validation.validators.hasOwnProperty('required')) {
                if (!data.validation.validators.required.valid) {
                    acc.push(key)
                }
            }
            return acc
        },
        []);
    if (invalidFields.length) {
        commit(UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_MESSAGE, { fieldName: invalidFields[0], messageStatus: true})
    }
    return !Boolean(invalidFields.length)
};

export const checkValidators = ({commit}, {fieldData, value}) => {
    try {
    const validators = Object.keys(fieldData.validation.validators);

    if (!validators.length) return true

    const validatorTypes = {
        required: checkRequired
    };

    return validators.every((key) => {
        const validatorIsExist = validatorTypes.hasOwnProperty(key);
        if (!validatorIsExist) console.warn(`Validator ${key} is not exist.`);
        const validationResult = validatorTypes[key](value);
        commit(UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_MESSAGE, { fieldName: fieldData.fieldName, messageStatus: false})
        commit(UPDATE_AVERAGE_PRICE_FIELD_VALIDATOR, { fieldName: fieldData.fieldName, validationData: { [key]: { valid: validationResult }}});
        return validationResult
    })
    } catch (e) {
        console.log("Error validations", e)
    }
};

export const checkRequired = (value) => Boolean(value);
