<template>
    <div>
        <div class="modal-scrollable">
            <div class="modal-backdrop fade in" @click.prevent="$emit('closePopupX'); close()"></div>
            <div class="modal fade in" :style="style" :class="modalClass" ref="popup">
                <div class="modal-header">
                    <a
                        href="javascript:void(0)" class="close" style="z-index: 1"
                        :id="closeId" @click.prevent="$emit('closePopupX'); close()">
                      ×
                    </a>
                    <div class="size18" :class="titleClass">
                        <a href="javascript:void(0)" class="close-sms hide" @click.prevent="close">← Назад</a>
                        <span v-html="title" />
                    </div>
                </div>
                <slot name="body"></slot>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  props:[ 'width', 'title', 'titleClass', 'modalClass', 'closeFn', 'closeId', 'logData' ],
  data: function () {
    return {
      height: 200
    }
  },
  computed: {
    style() {
      return {
        width: `${this.width}px`,
      }
    }
  },
  methods: {
    ...mapActions({
      closeAction: 'cabinet/advertisements/popups/close',
    }),
    close(...props) {
      this.popupLogger({ request_type: 2 });
      if (this.isMobile){
        const body = document.querySelector('body');
        body.classList.remove('modal-open');
      }
      if (typeof this.closeFn === 'function') {
        return this.closeFn(...props)
      }
      return this.closeAction(...props);
    },
    popupLogger(data = {}) {
      try {
        if (typeof this.logData === 'object')this._slonik({ ...this.logData, ...data });
      } catch (e) {
        console.error(`=== ERROR in popupLogger ===`, e.toString());
      }
    },
  },
  mounted() {
    this.popupLogger({ request_type: 1 });
    if (this.isMobile){
      const body = document.querySelector('body');
      body.classList.add('modal-open');
    }
  },
}
</script>
