import Vue from 'vue';
import _laxios from '../../_laxios';

export default {
	namespaced: true,
	state: {
		view: {}
	},
	actions: {
		view({commit, rootGetters}, id) {
			return _laxios.NaViewAuto.request(
				{
					data: {
						_source: [
							'marka',
							'marka_eng',
							'model',
							'model_eng',
							'base',
							'equip',
							'year',
							'priceUah',
							'gear',
							'fuel',
							'photoMain',
							'photos',
							'city_i18n'
						],
						query: {
							bool: {
								must: [
									{
										term: {
											autoId: id
										}
									}
								]
							}
						}
					}
				})
				.then(({hits: {hits: [payload] = []} = {}} = {}) => payload ? commit('view', {id, payload}) : undefined)
				.catch((error)=> console.error('Ошибка получения нового авто', error));
		}
	},
	mutations: {
		view: (state, {id, payload}) => Vue.set(state.view, id, payload),
	},
	getters: {
		view: function (state) {
			return function (id) {
				return state.view[id];
			}
		}
	}
}
