module.exports = `
query get($ids: [ID], $langId: ID) {
  get(ids: $ids, langId: $langId) {
    id
    title
    uri
    VIN
    createdAt
    race
    year
    brand {
      id
      eng
    }
    fuel {
      name
    }
    gearbox {
      name
    }
    location {
      city {
        name
      }
    }
    model {
      id
      eng
    }
    photos {
      main {
        id
      }
    }
    price {
      all {
        USD {
          value
          currency {
            sign
            abbreviated
          }
        }
        UAH {
          value
          currency {
            sign
            abbreviated
          }
        }
      }
    }
  }
}
`;
