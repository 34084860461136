import _laxios from './../../../../../../_laxios';

import {
  UPDATE_AUTH_DIIA_STATUS,
  UPDATE_AUTH_DIIA_POPUP_VISIBLE,
  SET_AUTH_DIIA_TOKEN,
  SET_AUTH_DIIA_AUTH_LINK,
  SET_DIIA_STAGE_INDEX,
  SET_DIIA_SHARE_REGUEST_ID,
  SET_DIIA_SHARE_LINK,
  SET_DIIA_SHARE_ADV_ID,
  UPDATE_DIIA_STATUS_CONFIRMED_CAR,
} from '../mutations/mutations-types';

export default {
    updateVisibleDiiaPopup({commit}, payload) {
        commit(UPDATE_AUTH_DIIA_POPUP_VISIBLE, payload);
        payload && commit(SET_DIIA_SHARE_ADV_ID, 0);
    },
    updateVisibleDiiaSharePopup({ commit }, { show, advId = 0 }) {
        commit(UPDATE_AUTH_DIIA_POPUP_VISIBLE, show);
        commit(SET_DIIA_SHARE_ADV_ID, advId);
    },
    async bindUser({ commit, rootGetters }, token) {
        const { 'cabinet/userInfo/userData': { id: userId } } = rootGetters;
        const request = _laxios.postBindLoggedUserToDiia.expandUrl({ userId });
        try {
            const response = await request.request({ data: { userId, token } });
            commit(UPDATE_AUTH_DIIA_STATUS, response.status === 1);
            return { binded: response.status === 1 };

        } catch (error) {
            console.error(error);
            return { binded: null };
        }
    },
    async fetchUserAuthDiiaStatus({ commit, rootGetters }){
        const { 'cabinet/userInfo/userData': { id: userId } } = rootGetters;
        try {
            const response = await _laxios.getDiiaAuthStatus.expandUrl({ userId }).request();

            commit(UPDATE_AUTH_DIIA_STATUS, response.binded);
            return response;
        } catch (e) {
            console.error('Diia cheking', e);
            return { binded: null };
        }
    },
    async fetchDiiaAuthLink({ commit, rootGetters }, returnLink){
        const { 'lang/id': langId } = rootGetters;
        const params = { langId, returnLink };

        if (returnLink) {
            params.returnLink = returnLink;
        }

        try {
            const response = await _laxios.getDiiaAuthLink.request({ params });
            if (response && response.status) {
                response.token && commit(SET_AUTH_DIIA_TOKEN, response.token)
                response.deeplink && commit(SET_AUTH_DIIA_AUTH_LINK, response.deeplink)
            }
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    fetchConfirmationFromDiia({ commit, getters }, token) {
        const delay = 2000;

        const timeOutId = setInterval(async () => {
            if (getters.stopPing) {
                clearInterval(timeOutId);
                return;
            }

            try {
                const response = await _laxios.getAuthAppStatus.expandUrl({ token }).request();

                if(response.status === 4) {
                    clearInterval(timeOutId);
                    commit(SET_DIIA_STAGE_INDEX, 3);
                } else if(response.status === 1) {
                    clearInterval(timeOutId);
                }
            } catch(e) {
                if(e.status === 500) {
                    commit(SET_AUTH_DIIA_TOKEN, null);
                    commit(UPDATE_AUTH_DIIA_POPUP_VISIBLE, false);
                    clearInterval(timeOutId);
                } else {
                    console.error('Diia app binding err', e)
                }
            }
        }, delay);
    },
  async fetchDiiaShareLink({commit, rootGetters}) {
    const {'cabinet/userInfo/userData': {id: userId}} = rootGetters;

    try {
      const response = await _laxios.getDiiaShareLink.expandUrl({userId}).request();
      if (response && response.requestId) {
        response.requestId && commit(SET_DIIA_SHARE_REGUEST_ID, response.requestId)
        response.deepLink && commit(SET_DIIA_SHARE_LINK, response.deepLink)
      }
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  fetchConfirmationShareFromDiia({commit, getters}, {requestId, advId}) {
    const delay = 2000;

    const timeOutId = setInterval(async () => {
      if (getters.stopPing) {
        clearInterval(timeOutId);
        return;
      }

      try {
        const response = await _laxios.getShareAppStatus
        .expandUrl({requestId})
        .request({params: {advId}});

        // status = 0 - документи не надано в додатку ДІЯ або надано доки на авто якого нема на сайті
        // status = 1 - документи співпадають з опублікованим авто
        // status = 2 - документи співпали з іншим опублікованим авто на сайті
        if (response.status === 1) {
          clearInterval(timeOutId);
          commit(UPDATE_DIIA_STATUS_CONFIRMED_CAR, advId); // ID авто для відображення "Власник авто" без перезавантаження сторінки
          commit(SET_DIIA_STAGE_INDEX, 0);
        } else if (response.status === 2) {
          clearInterval(timeOutId);
          commit(SET_DIIA_STAGE_INDEX, 5);
        }
      } catch (e) {
        if (e.status === 500) {
          clearInterval(timeOutId);
          commit(SET_DIIA_SHARE_REGUEST_ID, null);
          commit(SET_DIIA_STAGE_INDEX, 4);
        } else {
          console.error('Diia app binding err', e)
        }
      }
    }, delay);
  },
  async fetchConfirmedOwner({commit, rootGetters}, advId) {
    const {
      'cabinet/userInfo/userData': {id: userId},
    } = rootGetters;

    commit(SET_DIIA_SHARE_ADV_ID, advId);

    try {
      return await _laxios.getConfirmedOwner
      .expandUrl({userId})
      .request({params: {advId: advId}})
      .then((response) => response.confirmedOwner);
    } catch (e) {
      console.error('fetchConfirmedOwner', e.message);
      return null;
    }
  },
};

