import _laxios from '../_laxios';
import Vue from "vue";

module.exports = {
	state: {
		segment: ""
	},
	actions: {
		loadSegment({commit, rootGetters}, Cookie) {
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
            function get(cooks, name) {
                var matches = cooks && cooks.match(new RegExp(
                    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
                ));
                return matches ? decodeURIComponent(matches[1]) : undefined;
            }
			let headers = {};
			if (Cookie) {
				headers.Cookie = Cookie;
			}

            if(get(Cookie, "Kind16Check")){
                return Promise.resolve(get(Cookie, "Kind16Check"))
					.then(payload => {
                        commit('segment', { segment: payload, user: 0});
                    })
                    .catch(e => {
                        console.error('/cabinet/api/segment/', e.message);
                    });
            }else{
                return _laxios.segment
                    .request({headers})
                    .then(payload => {
                       commit('segment', payload);
					   return payload;
                    })
                    .catch(e => {
                        console.error('/cabinet/api/segment/', e.message);
                    });
            }


		}
	},
	mutations: {
		segment(state, {segment}) {
			Vue.set(state, 'segment', segment);
		}
	},
	getters: {
		segment({segment}) {
			return segment;
		}
	},
	namespaced: true
};
