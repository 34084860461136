import _laxios from "../../_laxios";
import {
    UPDATE_COUNTRY_PARTNERS,
    UPDATE_AUTO_INTER_CARS,
    UPDATE_AUTO_INTER_BRANDS, UPDATE_AUTO_INTER_NEWS
} from "../mutations/mutations-types";

export default {
    async fetchAutoInterCars({state, commit, rootGetters }, { page }) {
        const  {'lang/id': langId, 'shared/device/isDesktop': isDesktop, getters } = rootGetters;

        let data = [];
        if(!isDesktop) {
            data.loading = true
            commit(UPDATE_AUTO_INTER_CARS, { data, isDesktop } );
        }
        try {
            const responseAPI = await _laxios
                .searchAPI
                .request({
                    responseType: 'json',
                    params: {
                        langId,
                        page,
                        indexName: 'order_auto',
                        category_id: 1,
                        abroad:1,
                        custom: 2,
                        countpage: 5,
                        currentDevice:  !isDesktop ? 'desktop' : 'mobile'
                    }
                })
            const queryGraphQL = {query: require('../../_graphql/queries/advertisementAutoInter'), variables: {ids: responseAPI.result.search_result.ids, langId}};
            const responseGraphQL = await _laxios.graphql.request({ data:queryGraphQL})
            data = responseGraphQL.data.get
        } catch (e) {
            data.errors = e;
        } finally {
            commit(UPDATE_AUTO_INTER_CARS, { data, isDesktop });
        }
    },
    async fetchCountryPartners({ commit, rootGetters }) {
        const  {'lang/id': langId, 'shared/device/isDesktop': isDesktop } = rootGetters;

        let data = [];

        data.loading = true
        commit(UPDATE_COUNTRY_PARTNERS, { data } );

        try {
             let response = await _laxios
                .countryPartners
                .request({
                    responseType: 'json',
                    params: {
                        value:0,
                        size: 100,
                        langId,
                        catalog_ranges: 3,
                        currentDevice:  !isDesktop ? 'desktop' : 'mobile'
                    }
                })
            data = response.data
        } catch (e) {
            data.errors = e;
        } finally {
            commit(UPDATE_COUNTRY_PARTNERS, { data });
        }
    },
    async fetchAutoInterBrands({ commit, rootGetters }) {
        const  {'lang/id': langId, 'shared/device/isDesktop': isDesktop } = rootGetters;
        let data = [];

        data.loading = true
        commit(UPDATE_AUTO_INTER_BRANDS, { data } );

        try {
            let response = await _laxios
                .autoInterBrands
                .request({
                    responseType: 'json',
                    params: {
                        value:0,
                        langId,
                        catalog_ranges: 1,
                        currentDevice:  !isDesktop ? 'desktop' : 'mobile'
                    }
                })
            data = response.data
        } catch (e) {
            data.errors = e;
        } finally {
            commit(UPDATE_AUTO_INTER_BRANDS, { data });
        }
    },
    async fetchAutoInterNews({ commit, rootGetters }) {
        const  {'lang/id': langId, 'shared/device/isDesktop': isDesktop } = rootGetters;
        let data = [];
        data.loading = true
        commit(UPDATE_AUTO_INTER_NEWS, { data } );

        try {
            let response = await _laxios
                .autoInterNews
                .request({
                    responseType: 'json',
                    params: {
                        langId,
                        from: 0,
                        size: 6,
                        tag_id: 1256
                    }
                })
            data = response.data
        } catch (e) {
            data.errors = e;
        } finally {
            commit(UPDATE_AUTO_INTER_NEWS, { data });
        }
    },

}

