export default function getPageMeta({rootGetters}, extra) {
	let {
		'lang/code': langCode,
		'shared/device/width': deviceWidth,
		'shared/device/height': deviceHeight,
		'shared/device/name': deviceName,
		'Common/userData': userData = {},
	} = rootGetters;
	if (extra && extra.name) userData.userName = extra.name;
	if (extra && extra.email) userData.userEmail = extra.email;
	const {userName: name = 'Гость', userId = '', userEmail: email} = userData;

	return {
		userAgent: navigator.userAgent,
		platform: navigator.platform,
		cookieEnabled: navigator.cookieEnabled,
		href: document.location.href,
		referrer: document.referrer,
		langCode,
		innerWindow: `${deviceWidth}x${deviceHeight}`,
		device: deviceName,
		email,
		userId,
		name,
		...extra,
	};
};
