export default function (value) {
    let result = value
                .toUpperCase()
                .replaceAll(' ', '')

    // Перевірка на символи кирилиці
    const regexp = /[а-яА-ЯёЁ]/i;
    if(regexp.test(result)) {
        // Приведення до латиниці (приведення тільки для символів які викристовуюсть в держ номерах)
        const normilizeObject = [
            {
                cyclicSymbol: 'А',
                latinSymbol: 'A'
            },
            {
                cyclicSymbol: 'В',
                latinSymbol: 'B'
            },
            {
                cyclicSymbol: 'Е',
                latinSymbol: ' E'
            },
            {
                cyclicSymbol: 'І, И, Ы',
                latinSymbol: 'I'
            },
            {
                cyclicSymbol: 'К',
                latinSymbol: 'K'
            },
            {
                cyclicSymbol: 'М',
                latinSymbol: 'M'
            },
            {
                cyclicSymbol: 'Н',
                latinSymbol: 'H'
            },
            {
                cyclicSymbol: 'О',
                latinSymbol: 'O'
            },
            {
                cyclicSymbol: 'Р',
                latinSymbol: 'P'
            },
            {
                cyclicSymbol: 'С',
                latinSymbol: 'C'
            },
            {
                cyclicSymbol: 'Т',
                latinSymbol: 'T'
            },
            {
                cyclicSymbol: 'Х',
                latinSymbol: 'X'
            },
            {
                cyclicSymbol: 'У',
                latinSymbol: 'Y'
            },
        ]
        result = result
            .split('')
            .map(el => {
                if(!regexp.test(el)) return el;

                const literalOj = normilizeObject.find(item => {
                    return item.cyclicSymbol.includes(el);
                })
                return literalOj ? literalOj.latinSymbol : el;
            })
            .join('');
    }
    return result;
}
