import getSSIInclude from './getSSIInclude';

export default {
	name: 'PromoAppSSI',
	computed: {
		rawHtml() {
			return getSSIInclude();
		}
	}
};
