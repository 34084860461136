const number = [
  'categories.main.id', 'abroad.not', 'custom.not', 'credit', 'confiscated', 'damage.not', 'spareParts', 'sellerType', 'country.import.id',
  'installment',
  'verified.VIN', 'plateNumber.length.gte',
  'fuel.consumption.city.gte', 'fuel.consumption.city.lte',
  'fuel.consumption.route.gte', 'fuel.consumption.route.lte',
  'fuel.consumption.combine.gte', 'fuel.consumption.combine.lte',
  'fuel.consumption.powerReserve.gte', 'fuel.consumption.powerReserve.lte',
  'fuel.consumption.accumulatorCapacity.gte', 'fuel.consumption.accumulatorCapacity.lte',
  'engine.gte', 'engine.lte',
  'power.hp.gte', 'power.hp.lte', 'power.kW.gte', 'power.kW.lte', 'mileage.gte', 'mileage.lte',
  'carrying.gte', 'carrying.lte', 'axle', 'wheel.id',
  'doors.gte', 'doors.lte', 'seats.gte', 'seats.lte', 'color.metallic', 'top', 'size', 'photos.all.id.gte', 'video.key.gte', 'with_autotest_tech_check', 'with_autotest_vin_check', 'with_autotest_corporate_check',
  'price.USD.gte', 'price.USD.lte', 'price.EUR.gte', 'price.EUR.lte', 'price.UAH.gte', 'price.UAH.lte', 'price.currency',
  'post.exchanges.type.id', 'auction', 'post.exchanges.payment.id', 'post.exchanges.filters.category', 'post.exchanges.filters.subCategory',
  'post.exchanges.filters.brand', 'post.exchanges.filters.model', 'technicalCondition', 'paintCondition', 'country.import.usa.not', 'vat',
  'newSubscribe', 'channelId', 'firstTime', 'headlights.id', 'interiorMaterials.id', 'interiorColors.id', 'seatAdjustment.id',
  'seatVentilation.id', 'seatsHeated.id', 'memorySeatModule.id', 'windowLifter.id', 'conditioner.id', 'powerSteering.id',
  'steeringWheelAdjustment.id', 'spareWheel.id',
];

const string = ['sort[0].order', 'dates.sold.not', 'dates.sold', 'indexName', 'editSubscribe'];

const array = ['body.id[i]', 'fuel.id[i]', 'gearbox.id[i]', 'drive.id[i]', 'eco_standart[i]', 'color.type[i]', 'color.id[i]', 'options.id[i]'];

const locations = ['region.id[i]', 'city.id[i]', 'distance_from_city_km[i]'];

const series = [
  'year[i].gte', 'year[i].lte', 'year[i].gte.not', 'year[i].lte.not',
  'brand.id[i]', 'brand.id[i].not', 'model.id[i]', 'model.id[i].not',
  'generation.id[i][i]', 'generation.id[i][i].not',
  'modification.id[i][i][i]', 'modification.id[i][i][i].not',
];

const object = {
  'country.origin.id[i]': 'country.origin',
  'country.origin.id[i].not': 'country.origin',
};

const replaceMatrix = (string) => {
  const matrixRegx = /\[(\d+)\]/;

  if (matrixRegx.test(string)) {
    return replaceMatrix(string.replace(matrixRegx, '[i]'));
  }

  return string;
};

module.exports = {
  state(query) {
    const state = {};

    Object.keys(query).map((param) => {
      let param_;

      param_ = replaceMatrix(param);

      if (number.indexOf(param) < 0 && string.indexOf(param) < 0 && array.indexOf(param_) < 0 && locations.indexOf(param_) < 0 && series.indexOf(param_) < 0 && !object[param_]) {
        return;
      }

      if (number.indexOf(param) >= 0) {
        state[param] = Number(query[param]);
      } else if (string.indexOf(param) >= 0) {
        state[param] = String(query[param]);
      } else {
        // масив
        const
          value = Number(query[param]);
        if (value) {
          if (array.indexOf(param_) >= 0) {
            state[param_] = state[param_] || [];
            if (state[param_].indexOf(value) === -1) {
              state[param_].push(value);
            }
            state[param_] = state[param_].sort((a, b) => a - b);
          } else {
            const index = Number(param.match(/\[(\d+)\]/)[1]);
            if (locations.indexOf(param_) >= 0) {
              state.locations = state.locations || [];
              state.locations[index] = state.locations[index] || {};
              if (param.indexOf('region') === 0) {
                state.locations[index].region = value;
              } else if (param.indexOf('city') === 0) {
                state.locations[index].city = value;
              } else if (param.indexOf('distance_from_city_km') === 0) {
                state.locations[index].distance_from_city_km = value;
              }
            } else if (series.indexOf(param_) >= 0) {
              state.series = state.series || [];
              state.series[index] = state.series[index] || {};
              if (param.indexOf('not') >= 0) {
                state.series[index].not = true;
              }
              if (param.indexOf('brand') === 0) {
                state.series[index].brand = value;
              } else if (param.indexOf('model') === 0) {
                state.series[index].model = value;
              } else if (param.indexOf('generation') === 0) {
                state.series[index].generation = state.series[index]?.generation ? [...state.series[index]?.generation, value] : [value];
              } else if (param.indexOf('modification') === 0) {
                const generationIndex = Number(param.match(/\[(\d+)\]\[(\d+)\]\[(\d+)\]/)[2]);
                const generationId = state.series[index]?.generation[generationIndex];

                if (generationId) {
                  state.series[index].modification =										state.series[index]?.modification
                    ? [...state.series[index]?.modification, { generationId, value }]
                    :											[{ generationId, value }];
                }
              } else if (param.indexOf('year') === 0) {
                state.series[index].year = state.series[index].year || {};
                if (param.indexOf('gte') >= 0) {
                  state.series[index].year.gte = value;
                } else if (param.indexOf('lte') >= 0) {
                  state.series[index].year.lte = value;
                }
              }
            } else if (object[param_]) {
              state[object[param_]] = state[object[param_]] || [];
              state[object[param_]][index] = state[object[param_]][index] || {};
              if (value) {
                state[object[param_]][index].id = value;
                if (param.indexOf('not') >= 0) {
                  state[object[param_]][index].not = true;
                }
              }
            }
          }
        }
      }
    });

    state.locations = state.locations || [];
    state.locations = state.locations.filter(Boolean);
    if (!state.locations.length) {
      delete state.locations;
    }
    state.series = state.series || [];
    state.series = state.series.filter(Boolean);
    if (!state.series.filter((item) => item.not).length) {
      state.series.push({ not: true });
    }
    if (!state.series.filter((item) => !item.not).length) {
      state.series.push({});
    }
    // згрупувати
    const hash = {};
    let _index = 0;
    for (let i = 0; i < state.series.length; i++) {
      if (state.series[i]) {
        let { brand = 0, model = 0, year: { gte = 0, lte = 0 } = {} } = state.series[i];
        model = Boolean(model);
        const key = `brand:${brand}_model:${model}_year.gte:${gte}_year.lte${lte}`;
        if (!hash.hasOwnProperty(key)) {
          hash[key] = _index;
          _index++;
        }
        state.series[i].index = hash[key];
      }
    }

    state['country.origin'] = state['country.origin'] || [];
    state['country.origin'] = state['country.origin'].filter(Boolean);
    if (!state['country.origin'].length) {
      delete state['country.origin'];
    }

    return state;
  },
};
