const { get } = require('../helpers/cookie');

module.exports = {
	methods: {
		getCurrencyTypeId(currency) {
			switch (currency) {
				case 'USD':
					return 1;
				case 'EUR':
					return 2;
				case 'UAH':
					return 3;
				default:
					return 0;
			}
		},
		getWebId() {
			let webId;
			try {
				const ui = get('ui');
				if (ui && ui.length > 8) {
					webId = parseInt(ui.substring(0, ui.length - 8), 16);
				}
			} catch (e) {
				console.error('=== ERROR in getWebId ===', e.toString());
			}
			return webId;
		},
		getUserId() {
			let userId;
			try {
				const pspId = get('PSP_ID');
				if (pspId && pspId.length > 64) {
					userId = parseInt(pspId.slice(64), 10);
				}
			} catch (e) {
				console.error('=== ERROR in getUserId ===', e.toString());
			}
			return userId;
		},
		clearAutoRiaUrl(url) {
      return typeof url === 'string' ? url.replace('https://autolocal.ria.com', '').replace('https://auto.ria.com', '').replace('https://dev-auto.ria.com', '').replace('/uk/', '/').trim() : url;
		},
		getPathUrl(urlString) {
			try {
				const { host, pathname } = new URL(urlString);
				return host ? host.replace('www.', '') + pathname : pathname;
			} catch (e) {}
			return urlString;
		},
		formingInfotechLabels(data = {}) {
			const labels = {};
			try {
				if (data?.reportId) labels.autotest_infotech_report_id = data.reportId;
				if (data?.year) labels.autotest_infotech_year = data.year;
				if (data?.engineVolume) labels.autotest_infotech_engine_volume = data.engineVolume;
				if (data?.wanted) labels.autotest_infotech_wanted = data.wanted;
				if (data?.operationCode) labels.autotest_infotech_operation_code = data.operationCode;
				if (data?.autoBrandId) labels.autotest_infotech_marka_id = data.autoBrandId;
				if (data?.autoCategoryId) labels.autotest_infotech_category_id = data.autoCategoryId;
				if (data?.autoModelId) labels.autotest_infotech_model_id = data.autoModelId;
				if (data?.autoFuelId) labels.autotest_infotech_fuel_id = data.autoFuelId;
				if (data?.autoColorId) labels.autotest_infotech_color_id = data.autoColorId;
				if (data?.ownersCounter) labels.autotest_infotech_owners_counter = data.ownersCounter;
				if (data?.vin) labels.vin = data.vin;
				if (data?.plateNumber) labels.numberplate = data.plateNumber?.replace(/\s/g, '');
			} catch (e) {
				console.error('=== ERROR in slonDataLogger[formingInfotechLabels] ===', e.toString());
			}
			return labels;
		},
	},
};
