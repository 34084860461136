import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
	props: ['data', 'appendFlickityElement', 'logDataDefault'],
	data() {
		return ({});
	},
	computed: {
		...mapGetters({
			getIsSetByAutoId: 'others/autotest/isSetByAutoId',
			advertisements: 'graphql/advertisements',
			userData: 'Common/userData'
		}),
		id() {
			return this.data.id;
		},
		availableChecks() {
			return this.getIsSetByAutoId(this.id) || {};
		},
		advertisementRaw() {
			return this.advertisements(this.id);
		},
		advertisement() {
			return this.advertisementRaw || {};
		},
		url() {
			const {uri = ''} = this.advertisement;
			return uri;
		},
		img() {
			const {brand: {eng: brand = ''} = {}, model: {eng: model = ''} = {}, photos: {main} = {}} = this.advertisement;
			const {id} = main || {};
			return id ? `https://cdn.riastatic.com/photosnew/auto/photo/${brand}_${model}__${id}b.jpg` : 'https://img6.auto.ria.com/images/nophoto/no-photo-620x495.jpg';
		},
		titleAuto() {
			const {brand: {name: brand = ''} = {}, model: {name: model = ''} = {}, year = ''} = this.advertisement;
			return `${brand} ${model} ${year}`;
		},
		race() {
			const {race = ''} = this.advertisement;
			return race;
		},
		priceMain() {
			const {price: {all: {UAH = {}} = {}, main = {}} = {}} = this.advertisement;
			const {value, currency: {id, sign} = {}} = main;
			const {value: valueUAH, currency: {id: idUAH, abbreviated} = {}} = UAH;
			return idUAH === id ? {value: valueUAH, sign: abbreviated} : {value, sign};
		},
		priceUAH() {
			const {price: {all: {UAH = {}} = {}, main = {}} = {}} = this.advertisement;
			const {currency: {id} = {}} = main;
			const {value: valueUAH, currency: {id: idUAH, abbreviated} = {}} = UAH;
			return idUAH === id ? undefined : {value: valueUAH, sign: abbreviated};
		},
	},
	methods: {
		...mapActions({
			isSetByAutoId: 'others/autotest/isSetByAutoId',
			fetchAdvertisements: 'graphql/advertisements',
			fetchSeo: 'shared/seo/fetch'
		}),
		...mapMutations({
			autotestLoggerData: 'others/autotest/autotestLoggerData',
		}),
		order() {
			this.$emit('order', {id: this.id});
		},
		init() {
			return this.fetchAdvertisements([this.id]).then(() => {
				if (this.appendFlickityElement) {
					this.appendFlickityElement(this.$el);
				}
			}).catch(console.error);
		},
		autotestLogger(logData = {}) {
			this.autotestLoggerData({ ...this.logDataDefault, ...logData, proposal_id: this.id })
		},
	},
	mounted() {
		this.init([this.id]);
	},
	i18n: {
		messages: {
			ru: {
				'common.thousandKm': 'тыс. км',
				'html.buAuto.zakazatProverku': 'Проверить',
			},
			uk: {
				'common.thousandKm': 'тис. км',
				'html.buAuto.zakazatProverku': 'Перевірити',
			},
		}
	}
};
