import Vue from 'vue';
import _laxios from '../../_laxios';
import newAuto from "./newAuto";

export default {
	namespaced: true,
	modules: {
		newAuto
	},
	state: {
		v2: {}
	},
	actions: {
		v2({commit, rootGetters, getters}, id) {
			if (!getters.v2(id)) {
				let {'lang/id': langId} = rootGetters;
				return _laxios.searchV2ViewAuto.expandUrl({id}).request({params: {langId}})
					.then((payload) => payload ? commit('v2', {id, payload}) : undefined)
					.catch((error) => console.error('Ошибка получения элемента поиска v2', error));
			}
		}
	},
	mutations: {
		v2: (state, {id, payload}) => {
			Vue.set(state.v2, id, payload)
		},
	},
	getters: {
		v2: function (state) {
			return function (id) {
				return state.v2[id];
			}
		}
	}
}
