import _laxios from '../_laxios';

export default {
	counter: ({commit, getters}) => {
		_laxios.market_getProductCounter.stay = () => getters.counter.length;
		return _laxios.market_getProductCounter.request({responseType: 'json'})
			.then((response) => {
					if (response) {
						const {items} = response;
						items ? commit('counter', items) : undefined
					}
				}
			)
			.catch(() => console.error('Ошибка получения счетчиков для информера маркета'))
	},
};
