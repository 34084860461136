import _laxios from '../_laxios';
const averagePrice = import('./averagePrice.graphql');
const compareCarDetailed = import('./compareCarDetailed.graphql');
const feedRecomendation = import('./feedRecomendation.graphql');
const advertisementsForRotator = import('./checkSelection.graphql');

export default {
	fetchFeedRecomendation: (store, {history, langId = 2, limit = 48, version = ''} = {}) => {
		return feedRecomendation
			.then(({default: query}) => query)
			.then((query) => {
				const variables = {history, langId, limit, version};
				return _laxios.graphql
					.request({data: {query, variables}})
					.catch((error) => {
						console.error(error);
					});
			})
			.then(({data: {feed} = {}} = {}) => feed)
			.catch((error) => {
				console.error(error);
			});
	},
	getAveragePrice(store, {langId = 2, body, mark, model, yearFrom, yearTo} = {}) {
		return averagePrice
			.then(({default: query}) => query)
			.then((query) => {
				const variables = {langId, body, mark, model, yearFrom, yearTo};
				return _laxios.graphql
					.request({data: {query, variables}})
					.catch((error) => {
						console.error(error);
					});
			})
			.then(({data: {averagePrice} = {}} = {}) => averagePrice)
			.catch((error) => {
				console.error(error);
			});
	},
	blockFeedItem(store, data) {
		return _laxios.graphql.request({data})
			.catch((error) => {
				console.error(error);
			});
	},
	getCompareCarDetailed(store, { comparison = [] } = {}) {
		return compareCarDetailed
			.then(({default: query}) => query)
			.then((query) => {
				const variables = {
					autos: comparison.map(({mark, model}) => ({
						brand: mark,
						model: model
					}))
				};
				return _laxios.graphql
					.request({data: {query, variables}})
					.catch((error) => {
						console.error(error);
					});
			})
			.then(({data: {compareCar} = {}} = {}) => compareCar)
			.catch((error) => {
				console.info('failed query graphql/compareCar @vue#17AD3E0B8E5');
				console.error(error);
			});
	},
	getAdvertisementsForRotator(store, {ids = []} = {}) {
		return advertisementsForRotator
			.then(({default: query}) => query)
			.then((query) => _laxios
				.graphql
				.request({data: {query, variables: {ids}}})
			)
			.then(({data: {advertisements} = {}} = {}) => advertisements)
			.catch((error) => {
				console.info('failed query graphql/checkSelection @vue#17AD3E0B8E5');
				console.error(error);
			});
	}
};
