import {
  SET_MAIN_BANNER_DATA,
  SET_MAIN_BRK_DATA,
  SET_MAIN_TODAY_DATA,
} from '../mutations/mutation-types';
import _laxios from '../../../_laxios';

export default {
  async fetchMainBanner({ commit }) {
    try {
      const req = _laxios.getMainBanner;
      const banner = await req.request();

      commit(SET_MAIN_BANNER_DATA, banner);
    } catch (e) {
      commit(SET_MAIN_BANNER_DATA, {});
      console.error('=== ERROR in fetchMainBanner ===', e.toString());
    }
  },
  async fetchMainBRK({ commit }) {
    try {
      const req = _laxios.getMainBRK;
      const brk = await req.request();

      commit(SET_MAIN_BRK_DATA, brk);
    } catch (e) {
      commit(SET_MAIN_BRK_DATA, {});
      console.error('=== ERROR in fetchMainBRK ===', e.toString());
    }
  },
  async fetchMainToday({ commit }) {
    try {
      const req = _laxios.getMainToday;
      const mainToday = await req.request();

      commit(SET_MAIN_TODAY_DATA, mainToday);
    } catch (e) {
      commit(SET_MAIN_TODAY_DATA, {});
      console.error('=== ERROR in fetchMainToday ===', e.toString());
    }
  },
};
