import {zipObject} from '../../../helpers/_lodash';
import _laxios from './../_laxios';
import Vue from 'vue';
import modules from './modules'

let state = {
	userData: {},
	userPhone: '',
	loadedData: undefined,
	currenciesRates: {},
	webId: 0,
};

export default {
	modules,
	state,
	namespaced: true,
	mutations: {
		set: (state, payload) => {
			try {
				for (let name in payload) {
					name in state
						? (state[name] = payload[name])
						: console.error(`Not correct Data with name <<${name}>> in the state, set default value in store/Common.js; Payload =>`, payload);
				}
				state.loadedData = payload.userData || {};

			} catch (e) {
				console.error('Set data to store : ', e);
			}
		},
		clearUserData: (state, payload) => {
			Vue.set(state, 'userData', {});
		},
		webId(state, identifier) {
			Vue.set(state, 'webId', identifier);
		},
	},
	actions: {
		webId({ commit }, identifier) {
			commit('webId', identifier);
		},
		userData({commit, rootGetters, getters}, { Cookie, withSegment } = {}) {
			let {'lang/id': langId} = rootGetters,
				sendRequest = false;
			const params = {langId};
			if (withSegment) {
				Object.assign(params, { withSegment })
			}
			const headers = {};
			if (Cookie) {
				headers.Cookie = Cookie;
			}

			if (!Object.keys(getters.userData).length) {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.userData.request({params, responseType: 'json', headers})
					.then((userData) => {
						commit('set', {userData});
						return userData;
					})
					.catch((err) => console.error('Ошибка при получении userData', err.message));
			} else {
				return Promise.resolve(getters.userData);
			}

		},
		googleLoginEndpoint(context, payload) {
			const data = {
				credential: payload.credential
			}

			return _laxios.googleOneTapLogin
				.request({data})
				.then((result) =>  {
					if (result.status===2) {
						return result
					} else if (result.status===7) {
						console.error("!!! INVALID JWT !!!")
					} else if (result.status===5) {
						console.error("!!! UNKNOWN ERROR !!!")
					}
				})
				.catch(e => console.error(e));
	    },
	},
	getters: {
		...zipObject(Object.keys(state), Object.keys(state)
			.map((name) => {
				return (state) => {
					return state[name];
				};
			})),
		webId({ webId } = {}) {
			return webId;
		},
		isAuth(getters) {
			const {userData} = getters;
			return Boolean(userData?.userId)
		},
		getUserPhone(getters){
			const { userPhones: [userPhone] = [] } = getters.userData;
			return `${userPhone || ''}`.split(',')[0].replace(/[^0-9$]/g, '');
		}
	}
};
