
// Функція потрібна для приведення даних отриманих з бекенду для адекватної роботи елементів форми Середня ціна
export default function normalizeData({ data, fieldData }){
    const needNormalize = Boolean(fieldData?.api?.get?.needNormalize);
    if(!needNormalize || !data.length || !Boolean(data)) return data;

    const availableNormalizations = {
        'generationId': generationNormalize,
        'bodyId': bodyNormalize
    }

    return availableNormalizations.hasOwnProperty(fieldData.fieldName) ? availableNormalizations[fieldData.fieldName](data) : 'Normalize is not exist.';
};

function generationNormalize(data) {
   if(data[0])
   return data[0].generations.map((el) => (
       {
           name: el.name,
           value: el.generationId,
           img: el.generationPhoto,
           yearFrom: el.yearFrom,
           yearTo: el.yearTo
       }
   ))
};

function bodyNormalize(data) {
    return data.map((el) => ({name: el.name, value: el.sub_cat_id }))
}
