import Vue from 'vue';
import VueGtm from 'vue-gtm';
import VueGtag from "vue-gtag";

import createApp from './app';
import recursiveComponentInit from './helpers/recursiveComponentInit';

import resource from 'vue-resource';

const target = 'web';

const {app, router, store} = createApp(target, {});

Vue.use(resource);

/* eslint-disable */
if (window.__INITIAL_STATE__) {
	store.replaceState(window.__INITIAL_STATE__);
}
/* eslint-enable */

Vue.use(VueGtm, {
	id: 'GTM-P46SHG8', // Your GTM ID
	enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	debug: false, // Whether or not display console logs debugs (optional)
	vueRouter: router, // Pass the router instance to automatically sync with router (optional)
	ignoredViews: ['technicalcheck', 'lawyercheck'] // If router, you can exclude some routes name (case insensitive) (optional)
});

// GA4
Vue.use(VueGtag, {
	config: {
		id: 'G-KGL740D7XD',
		params: {
			send_page_view: true,
			cookie_flags: 'SameSite=None;Secure;Partitioned',
		}
	}
});

router.onReady((to) => {
	const matched = router.getMatchedComponents(to);
	const data = {app, store, router, route: {to}, target};

	Promise.all(matched.map((Component) => recursiveComponentInit(Component, 'init', data)))
		.catch(console.error).then(() => app.$mount('#app'));

	app.$i18n.locale = Number(app.langId) === 4 ? 'uk' : 'ru';

	router.beforeResolve((to, from, next) => {
		const matched = router.getMatchedComponents(to);
		const prevMatched = router.getMatchedComponents(from);

		// формируем список еще не скачанных компонентов
		let diffed = false;
		const matchedComponents = matched.filter((component, index) => diffed || (diffed = (prevMatched[index] !== component)));

		if (!matchedComponents.length) {
			return next();
		}

		const data = {app, store, router, route: {to, from}, target};

		// @todo устанавливаем индикатор загрузки
		Promise.all(matchedComponents.map((Component) => recursiveComponentInit(Component, 'init', data)))
			.then(() => {
				// останавливаем индикатор загрузки
				next();
			})
			.catch(next);
	});
});
