/* eslint-disable import/no-extraneous-dependencies,global-require,consistent-return */
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { YearsRange } from '../../../../helpers/Range';
import openBillingWindow from '../../../../helpers/openBillingWindow';
import slonDataLogger from '../../../../mixins/slonDataLogger';
import conversionPaidCheckGTM from '../../../../mixins/conversionPaidCheckGTM';

import {
  getCitySTOsV2,
  autotestCreateOrderForeignAuto,
  getSTOPricesPackagesV2,
} from '../../../../store/queries';

import {
  tel as telPattern,
  vin as vinPattern,
} from '../../../../../constants/patterns';
import Cookies from '../../../../helpers/cookie';

const { event787 } = require('../../../../enums/autotestLogger').default;

const { _checkCarStat } = require('../../../../store/_laxios');

const { get } = require('../../../../helpers/cookie');

const CARFAX = 'CARFAX';
const AUTO_CHECK = 'AutoCheck';

export default {
  i18n: require('./i18n').default,
  name: 'AnotherAdvertisement',
  components: {
    'vue-login': require('../../../Common/LoginForm/index.vue').default,
  },
  mixins: [slonDataLogger, conversionPaidCheckGTM],
  init({ store, context }) {
    if (context) {
      const cookies = { Cookie: context.request.headers.cookie || {} };
      return Promise.allSettled([
        store.dispatch('others/autotest/autotestVinCheckPrices', { cookies }),
        store.dispatch('shared/brands/fetchByCategory', this.autoCategoryId),
        store.dispatch('shared/locations/fetchZones'),
      ]);
    }
  },
  props: {
    showItems: {
      type: Object,
      default() {
        return {
          showCheckType: true,
          showAutoInfo: true,
          showAutoOwner: true,
          showLocation: true,
          showCustomer: true,
          from: '',
        };
      },
    },
    formDataInfotech: {
      type: Object,
      default: null,
    },
    logDataDefault: {
      type: Object,
      default: null,
    },
    validationVIN: {
      type: String,
      default: null,
    },
  },
  data() {
    return ({
      autoCategoryId: 1,
      mounted: false,
      status: 'FillingOutForm',
      orderId: '',

      order: {
        auto: {
          category: this.autoCategoryId,
          brand: 0,
          model: 0,
          year: 0,
          region: 0,
          city: 0,
          vin: '',
        },
        owner: {
          name: '',
          phone: '',
        },
        customer: {
          phone: this.userPhone,
        },
        sto: 0,
        orders: {
          vin: true,
          vinMax: false,
          USAReport: false,
          technichal: false,
        },
      },
      stoList: [],
      // stationId -> packageId -> brandId -> 1 to get package price for brand
      stoPackagesData: {},
      testCookie: _TARGET_ === 'client' && get('testNewPricePromoPage'),
      validation: true,
      isInfotechReport: false,
      isReportsExist: false,
      checkCarfax: false,
      checkAutocheck: false,
      USAReportPrice: 0,
      required: false,
      USAReportProfit: 0,
      defaultLogDataAnotherAdv: {},
      filterLogChangeFields: {
        vin: false,
        year: false,
        brand: false,
        model: false,
      },
    });
  },
  computed: {
    ...mapGetters({
      brandsForCategory: 'shared/brands/getByCategory',
      modelsForBrand: 'shared/models/getByBrand',
      zonesPlain: 'shared/locations/zonesPlain',
      citiesInState: 'shared/locations/citiesInState',
      userData: 'Common/userData',
      getUserPhone: 'Common/getUserPhone',
      vinChecksPrices: 'others/autotest/autotestVinCheckPrices',
    }),
    userPhone() {
      return this.getUserPhone;
    },
    brandsList() {
      return this.brandsForCategory(this.order.auto.category);
    },
    modelsList() {
      return this.modelsForBrand(this.order.auto.brand, this.order.auto.category);
    },
    yearsList() {
      const range = new YearsRange(1900, (new Date().getFullYear()));
      return Array.from(range).reverse();
    },
    regionsList() {
      return this.zonesPlain;
    },
    citiesList() {
      return this.citiesInState(this.order.auto.region);
    },
    carfaxCheckTitle() {
      if (!this.checkCarfax && !this.checkAutocheck) return `${CARFAX} / ${AUTO_CHECK}`;

      return this.checkCarfax ? CARFAX : AUTO_CHECK;
    },
    carfaxNotifyKey() {
      if (!this.isReportsExist) {
        if (!this.checkCarfax && !this.checkAutocheck && Boolean(this.order.auto.vin)) {
          return 'За даною машиною перевірка CarFax/AutoCheck недоступна';
        } if (!this.isInfotechReport || !this.validationVIN) {
          return 'Для створення заявки на отримання звіту введіть VIN-коду';
        }
      }
    },
    technichalPrice() {
      const stationPackage = this.stoPackagesData[this.order.sto];
      return stationPackage
        ? Object.values(stationPackage)
          .map((brandsPrices) => brandsPrices[this.order.auto.brand]?.[1])
          .reduce((acc, val) => acc + Number(val || 0), 0)
        : 0;
    },
    technicalVerifications() {
      const stationPackage = this.stoPackagesData[this.order.sto];

      return stationPackage
        ? Object
          .entries(stationPackage)
          .map(([packageId, brandsPrices]) => brandsPrices[this.order.auto.brand]?.[1] && packageId)
          .reduce((acc, val) => {
            if (val) acc.push(Number(val));
            return acc;
          }, [])
        : [];
    },
    vinPrice() {
      if (this.validationVIN) {
        this.USAReportsExist(this.validationVIN);
        this.order.auto.vin = this.validationVIN;
        this.order.auto.brand = 0;
        this.order.auto.year = 0;
      } else if (this.formDataInfotech) {
        this.autoCategoryId = this.formDataInfotech.autoCategoryId;
        this.filterLogChangeFields.vin = true;
        this.order.auto.vin = this.formDataInfotech.vin;
        this.filterLogChangeFields.year = true;
        this.order.auto.year = this.formDataInfotech.year;
        this.filterLogChangeFields.brand = true;
        this.order.auto.brand = this.formDataInfotech.autoBrandId;
        this.filterLogChangeFields.model = true;
        this.order.auto.model = this.formDataInfotech.autoModelId;
        this.isInfotechReport = true;
        this.USAReportsExist(this.formDataInfotech.vin);
      }
      this.order.customer.phone = this.userPhone;

      return this.vinChecksPrices?.vinPlus?.price || 0;
    },
    vinMaxPrice() {
      return this.vinChecksPrices?.vinMax?.price || 0;
    },
    totalPrice() {
      const {
        order: {
          orders: {
            technichal, vin, vinMax, USAReport,
          },
        },
      } = this;

      return (
        (vin ? this.vinPrice : 0)
        + (vinMax ? this.vinMaxPrice : 0)
        + (technichal ? this.technichalPrice : 0)
        + (USAReport ? this.USAReportPrice : 0)
      );
    },
    submitStatus() {
      const {
        orders: { technichal, vin, vinMax },
        auto: { brand, model, year },
      } = this.order;

      if ((vin || vinMax) && !technichal) {
        return this.order.customer.phone && brand && model && year;
      }

      return (
        (this.isReportsExist && this.order.orders.USAReport)
        || (Object.values(this.order.auto).some((value) => value)
        && this.order.sto
        && Object.values(this.order.orders).some((value) => value))
      );
    },
    customerUserId() {
      const { userId } = this.userData || {};
      return userId;
    },
    isPhoneValid() {
      return telPattern.test(this.order.owner.phone);
    },
    telPatternString() {
      return telPattern.toString().split('/')[1];
    },
    showSTOList() {
      return this.stoList.length === 0
        || (this.stoList.length !== 0 && this.stoList[0].value !== 0);
    },
    isCheckCarPage() {
      return this.$route.name === 'VinCheckedPage';
    },
  },
  methods: {
    ...mapActions({
      brandFetchByCategory: 'shared/brands/fetchByCategory',
      modelFetchByBrand: 'shared/models/fetchByBrand',
      fetchZones: 'shared/locations/fetchZones',
      fetchCitiesInState: 'shared/locations/citiesInState',
      fetchInfotechReport: 'others/autotest/fetchInfotechReport',
      fetchUSAReportsExist: 'others/autotest/fetchUSAReportsExist',
    }),
    ...mapMutations({
      autotestLoggerData: 'others/autotest/autotestLoggerData',
    }),
    getSTO() {
      this.order.sto = 0;
      this.stoList.splice(0, this.stoList.length);
      const {
        order: {
          auto: {
            category, brand, model, year, city,
          },
        },
      } = this;
      if (model && year && city) {
        return getCitySTOsV2({
          categoryId: category,
          brandId: brand,
          modelId: model,
          year,
          cityId: city,
          langId: this.langId,
        })
          .then((response = {}) => {
            const { sto } = response;
            Array.isArray(sto) && sto.length
              ? this.stoList.splice(0, this.stoList.length, ...sto)
              : this.stoList.splice(0, this.stoList.length, { value: 0, name: this.$t('нет возможности проверить') });
          });
      }
    },
    async getSTOPrices() {
      const { order: { auto: { model, year, city }, sto } } = this;
      if (model && year && city && sto) {
        const stoPackages = await getSTOPricesPackagesV2({ stationId: sto });
        Vue.set(this.stoPackagesData, sto, stoPackages);
      }
    },
    makeOrder(event) {
      event.stopPropagation();
      event.preventDefault();

      if (!this.submitStatus) {
        // eslint-disable-next-line no-return-assign
        return this.required = true;
      }

      this.status = 'WaitingForResponse';

      const {
        order: {
          auto,
          orders,
          sto,
          owner,
        },
      } = this;
      const {
        userId = 0,
        userName = '',
      } = this.userData || {};

      const diagnosticTypes = [];
      if (orders.vin) {
        diagnosticTypes.push(1);
      }
      if (orders.vinMax) {
        diagnosticTypes.push(2);
      }
      if (orders.USAReport && this.checkCarfax) {
        diagnosticTypes.push(3);
      } else if (orders.USAReport && this.checkAutocheck) {
        diagnosticTypes.push(4);
      }

      const priceVinCheckLabels = this.formingPriceVinCheckLabels();
      const orderLogData = {
        autotest_infotech_marka_id: auto.brand,
        autotest_infotech_model_id: auto.model,
        autotest_infotech_year: auto.year,
        vin: auto.vin,
        ...priceVinCheckLabels,
      };
      this.autotestLogger({
        click_action: 12,
        request_type: event787.anotherCarCheckOrdering,
        ...orderLogData,
      });
      this.sendOrderPriceGTM(priceVinCheckLabels.payment_summ);

      return autotestCreateOrderForeignAuto(
        {
          markaId: auto.brand,
          modelId: auto.model,
          years: auto.year,
          userId,
          vin: auto.vin,
        },
        {
          // TODO: email text input
          email: '',
          name: userName,
          phone: this.order.customer.phone,
        },
        {
          name: owner.name,
          phone: owner.phone,
          // TODO: email text input
          email: '',
        },
        {
          technical: orders.technichal && {
            sto: {
              stoId: sto,
            },
            verifications: this.technicalVerifications,
          },
          vin: (orders.vin || orders.vinMax || orders.USAReport) && {
            // Vin check
            diagnostic_type: this.order.orders.vinMax ? 2 : 1,
            diagnostic_types: diagnosticTypes,
          },
        },
        // TODO: comment input
        '',
        userId,
      )
        .then((res) => {
          const {
            orders: {
              vin: {
                id = 0,
                billingOrderId = 0,
                items: {
                  carFax = {},
                } = {},
              } = {},
            } = {},
          } = res || {};
          // коли обирається carFax, в нього таке саме ід, як у він vin, тому така перевірка
          const isVinAndCarFax = Boolean(carFax.id && (carFax.id !== id));
          /*
            відкриваємо вікно білінгу, лише в випадку коли обраний один з варіантів,
            або VIN або carFax. В іншому випадку, не відкриваємо
           */
          if (!isVinAndCarFax) openBillingWindow(carFax.billingOrderId || billingOrderId, this.$t('Перевірити'));
          this.autotestLogger({
            request_type: event787.successAnotherCarCheck,
            billing_order_id: billingOrderId,
            order_spare_id: id,
            ...orderLogData,
          });
          this.status = 'OrderSuccess';
          this.orderId = id;
        })
        .catch((error) => {
          this.autotestLogger({
            request_type: event787.failedAnotherCarCheck,
            ...orderLogData,
          });
          console.error(error);
          this.status = 'OrderError';
        });
    },
    ordersVin() {
      this.order.orders.vinMax = false;
    },
    ordersVinMax() {
      this.order.orders.vin = false;
    },
    USAReportsExist(vin) {
      this.fetchUSAReportsExist({
        vin,
      }).then((data) => {
        const carfax = data.carfax === 200 || false;
        const autocheck = data.autocheck === 200 || false;
        this.checkCarfax = false;
        this.checkAutocheck = false;
        this.validation = true;
        if (carfax || autocheck) {
          this.isReportsExist = true;
          if (autocheck) {
            this.checkAutocheck = true;
            this.USAReportPrice = this.vinChecksPrices?.autocheck?.price || 0;
            this.USAReportProfit = this.vinChecksPrices?.autocheck?.profit || 0;
          }
          if (carfax) {
            this.checkCarfax = true;
            this.USAReportPrice = this.vinChecksPrices?.carfax?.price || 0;
            this.USAReportProfit = this.vinChecksPrices?.carfax?.profit || 0;
          }
        } else {
          this.order.orders.USAReport = false;
          this.isReportsExist = false;
        }
      }).catch((e) => {
        console.error('fetchUSAReportsExist query error =>', e.message);
      });
    },
    preparePlateNumber(plateNumber) {
      const pattern = [/Е/gi, /Т/gi, /І/gi, /О/gi, /А/gi, /Н/gi, /К/gi, /С/gi, /В/gi, /М/gi, /Р/gi, /Х/gi];
      const replacement = ['E', 'T', 'I', 'O', 'A', 'H', 'K', 'C', 'B', 'M', 'P', 'X'];
      let result = plateNumber.toUpperCase().trim();
      for (let i = 0; i < pattern.length; i++) {
        result = result.replaceAll(pattern[i], replacement[i]);
      }
      result = result.replace(/\s+/g, '');
      const cyrillicRegex = /[А-Яа-яЁё]/g;
      if (result.match(cyrillicRegex)) {
        return false;
      }
      return result;
    },
    inputNumber() {
      this.validation = true;
    },
    handleFocusout() {
      const validVinNumber = this.preparePlateNumber(this.order.auto.vin);
      const validVIN = this.order.auto.vin.length === 17
        && validVinNumber && vinPattern.test(validVinNumber);
      this.autotestLogger({ click_action: 6, ...this.formingVinPlateNumberLabel() });
      if ((this.order.auto.vin.length === 8 && validVinNumber) || validVIN) {
        this.fetchInfotechReport({
          type: this.order.auto.vin.length === 8 ? 'plateNumber' : 'vin',
          number: validVinNumber,
        }).then((data) => {
          this.autoCategoryId = data.autoCategoryId;
          this.filterLogChangeFields.brand = true;
          this.order.auto.brand = data.autoBrandId;
          this.filterLogChangeFields.year = true;
          this.order.auto.year = data.year;
          this.filterLogChangeFields.model = true;
          this.order.auto.model = data.autoModelId;
          this.order.auto.vin = data.vin;
          this.isInfotechReport = true;
          if (this.filterLogChangeFields?.vin) {
            this.filterLogChangeFields.vin = false;
          } else {
            const infotechLogData = this.formingInfotechLabels(data);
            this.autotestLogger({
              request_type: event787.availableInfotechData,
              ...infotechLogData,
            });
            _checkCarStat.request({ params: { ...this.logDataDefault, ...infotechLogData } })
              .catch(console.error);
          }
        }).catch((e) => {
          console.error('fetchInfotechReport query error =>', e.message);
          this.filterLogChangeFields.brand = true;
          this.order.auto.brand = 0;
          this.filterLogChangeFields.year = true;
          this.order.auto.year = 0;
          this.validation = false;
          this.isInfotechReport = false;
          if (this.filterLogChangeFields?.vin) {
            this.filterLogChangeFields.vin = false;
          } else {
            this.autotestLogger({
              request_type: event787.notAvailableInfotechData,
              ...this.formingVinPlateNumberLabel(),
            });
          }
        });
      } else if (this.order.auto.vin.length) {
        this.validation = false;
        this.isInfotechReport = false;
        this.autotestLogger({
          request_type: event787.validationError,
          ...this.formingVinPlateNumberLabel(),
        });
      }
      if (validVIN) {
        this.USAReportsExist(validVinNumber);
      }
    },
    formingVinPlateNumberLabel() {
      if (this.order.auto.vin.length === 8) return { numberplate: this.order.auto.vin };
      if (this.order.auto.vin.length) return { vin: this.order.auto.vin };
      return {};
    },
    autotestLogger(logData) {
      this.autotestLoggerData({
        ...this.logDataDefault,
        ...this.defaultLogDataAnotherAdv,
        ...logData,
      });
    },
    formingCheckedVerificationLabels() {
      const labels = {};
      const { order: { orders: { vin, vinMax, USAReport } } } = this;
      if (vin) {
        labels.autotest_vin_plus_verification = 1;
        const { vinPlus: { price: vinPlusPrice = 0 } = {} } = this.vinChecksPrices || {};
        labels.autotest_price_vin_plus_verification = Math.round(vinPlusPrice);
      }
      if (vinMax) {
        labels.autotest_vin_max_verification = 1;
        const { vinMax: { price: vinMaxPrice = 0 } = {} } = this.vinChecksPrices || {};
        labels.autotest_price_vin_plus_verification = Math.round(vinMaxPrice);
      }
      if (USAReport) {
        labels.autotest_price_usa_verifications = Math.round(this.USAReportPrice) || 0;
        if (this.checkCarfax) {
          labels.autotest_usa_verifications_carfax = 1;
        } else {
          labels.autotest_usa_verifications_autocheck = 1;
        }
      }
      this.defaultLogDataAnotherAdv = labels;
    },
    formingPriceVinCheckLabels() {
      const labels = { payment_summ: 0, balance_promotional: 0 };
      const { order: { orders: { vin, vinMax, USAReport } } } = this;
      if (vin) {
        labels.payment_summ += Math.round(this.vinChecksPrices?.vinPlus?.price) || 0;
        labels.balance_promotional += Math.round(this.vinChecksPrices?.vinPlus?.profit) || 0;
      }
      if (vinMax) {
        labels.payment_summ += Math.round(this.vinChecksPrices?.vinMax?.price) || 0;
        labels.balance_promotional += Math.round(this.vinChecksPrices?.vinMax?.profit) || 0;
      }
      if (USAReport) {
        labels.payment_summ += Math.round(this.USAReportPrice) || 0;
        labels.balance_promotional += Math.round(this.USAReportProfit) || 0;
      }
      return labels;
    },
  },
  mounted() {
    this.formingCheckedVerificationLabels();
  },
  watch: {
    'order.auto.brand': function (brand) {
      if (this.filterLogChangeFields?.brand) {
        this.filterLogChangeFields.brand = false;
      } else {
        this.autotestLogger({ click_action: 8, autotest_infotech_marka_id: this.order.auto.brand });
      }
      if (!this.isInfotechReport) {
        this.order.auto.model = 0;
      }
      return this.modelFetchByBrand({ category: this.order.auto.category, brand });
    },
    'order.auto.model': function () {
      if (this.filterLogChangeFields?.model) {
        this.filterLogChangeFields.model = false;
      } else {
        this.autotestLogger({ click_action: 9, autotest_infotech_model_id: this.order.auto.model });
      }
      this.getSTO();
    },
    'order.auto.year': function () {
      if (this.filterLogChangeFields?.year) {
        this.filterLogChangeFields.year = false;
      } else {
        this.autotestLogger({ click_action: 10, autotest_infotech_year: this.order.auto.year });
      }
      this.getSTO();
    },
    'order.auto.region': function (region) {
      this.order.auto.city = 0;
      return this.fetchCitiesInState({ id: region });
    },
    'order.auto.city': function () {
      this.getSTO();
    },
    'order.sto': function () {
      this.getSTOPrices();
    },
    'order.orders.vin': function (newValue) {
      if (newValue) {
        this.formingCheckedVerificationLabels();
        this.autotestLogger({ click_action: 7, ...this.formingVinPlateNumberLabel() });
      }
    },
    'order.orders.vinMax': function (newValue) {
      if (newValue) {
        this.formingCheckedVerificationLabels();
        this.autotestLogger({ click_action: 7, ...this.formingVinPlateNumberLabel() });
      }
    },
    'order.orders.USAReport': function (newValue) {
      if (newValue) {
        this.formingCheckedVerificationLabels();
        this.autotestLogger({ click_action: 7, ...this.formingVinPlateNumberLabel() });
      }
    },
    validationVIN: {
      handler(newValue) {
        if (newValue) {
          this.isInfotechReport = false;
        }
      },
      immediate: true,
    },
  },
};
