export default {
    '': (state) => state,
    news(state) {
        return function (id) {
            return state.news[id];
        };
    },
    toggle(state) {
        return function (id) {
            return state.responses[state.toggles[id]];
        };
    },
    response(state) {
        return function (id) {
            return state.responses[id];
        };
    },
    toggleReviews(state) {
        return function (id) {
            return state.responsesReviews[state.togglesReviews[id]];
        };
    },
    responseReviews(state) {
        return function (id) {
            return state.responsesReviews[id];
        };
    },
    LandingMainLink(state) {
        return state.LandingMainLink[state.toggleLandingMainLink];
    },
    slonikInfo: ({slonikInfo}) => slonikInfo,
    autoleaderInfo: ({autoleaderInfo}) => autoleaderInfo,
    favoritesIds: (state) => state.favoritesIds
}
