const _la = require('../_laxios');

const transformRequest = (data) =>
	Object
		.entries(data)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&');

module.exports = {
	namespaced: true,
	state: {},
	actions: {
		sendMail({commit, getters}, {from, to, name, message}) {
			return _la.sendContactMessage.request({data: transformRequest({mail_to: to, email: from, fio: name, message})})
				.catch(console.error);
		}
	}
};
