import Vue from 'vue';

import actions from './actions';

export default {
	namespaced: true,
	state: {
		toggles: {},
		responses: {}
	},
	mutations: {
		toggle(state, {toggle, id}) {
			Vue.set(state.toggles, toggle, id)
		},
		save(state, {id, payload} = {}) {
			Vue.set(state.responses, id, payload)
		}
	},
	actions,
	getters: {
		'': (state) => state,
		toggle(state) {
			return function (id) {
				return state.responses[state.toggles[id]]
			}
		},
		response(state) {
			return function (id) {
				return state.responses[id]
			}
		},
	}
}


