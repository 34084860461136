export default {
	namespaced: true,
	state: {
		category: 1,
		series: [],
		cities: [],
		price: {from: undefined, to: undefined, currency: undefined},
	},
	actions:{
		setCategory: ({commit, getters}, payload) => {
			if (getters.getCategory !== payload) {
				if (payload) {
					commit('resetSeries');
				}
				commit('setCategory', payload);
			}
		},
		setOnlyBrand: ({commit}, payload) => {
			commit('setBrand', {index: 0, payload});
		},
		setOnlyModel: ({commit}, payload) => {
			commit('setModel', {index: 0, payload});
		},
		setOnlyYears: ({commit}, payload) => {
			commit('setYears', {index: 0, payload});
		},
		setOnlyRegion: ({commit}, payload) => {
			commit('resetCities');
			commit('setRegion', {index: 0, payload});
		},
		setOnlyCity: ({commit}, payload) => {
			commit('resetCities');
			commit('setCity', {index: 0, payload});
		},
	},
	mutations:{
		setCategory: (state, payload) => {
			const value = parseInt(payload);
			state.category = Number.isFinite(value) && value ? value : undefined
		},
		setBrand: (state, {index, payload = {}}) => {
			const value = parseInt(payload);
			const brand = Number.isFinite(value) && value ? value : undefined;
			let {[index]: {brand: brandOld, years} = {}} = state.series;
			if (brandOld !== brand) {
				state.series.splice(index, 1, {brand, years})
			}
		},
		setModel: (state, {index, payload}) => {
			const value = parseInt(payload);
			const model = Number.isFinite(value) && value ? value : undefined;
			const {[index]: {brand, model: modelOld, years} = {}} = state.series;
			if (modelOld !== model) {
				state.series.splice(index, 1, {brand, model, years})
			}
		},
		setYears: (state, {index, payload}) => {
			const years = payload;
			const {[index]: {brand, model} = {}} = state.series;
			state.series.splice(index, 1, {brand, model, years})
		},
		setRegion: (state, {index, payload}) => {
			const value = parseInt(payload);
			state.cities.splice(index, 1, {region: value})
		},
		setCity: (state, {index, payload}) => {
			const value = parseInt(payload);
			const {[index]: {region} = {}} = state.series;
			state.cities.splice(index, 1, {region, city: value})
		},
		resetSeries: (state) => state.series.splice(0, state.series.length),
		resetCities: (state) => state.cities.splice(0, state.cities.length),
		setPrice: (state, payload) => state.price = payload,
	},
	getters: {
		getCategory: (state) => state.category,
		series(state) {
			return state.series
		},
		getSpecificSeries(state, getters) {
			return function (index) {
				return getters.series[index]
			}
		},
		getSpecificBrand(state) {
			return function (index) {
				let {[index]: {brand: value} = {}} = state.series;
				return value
			}
		},
		getSpecificModel(state) {
			return function (index) {
				let {[index]: {model: value} = {}} = state.series;
				return value
			}
		},
		getSpecificYears(state) {
			return function (index) {
				let {[index]: {years: value} = {}} = state.series;
				return value
			}
		},
		getSpecificLocation(state, getters) {
			return function (index) {
				return getters.getLocations[index]
			}
		},
		getLocations(state) {
			return state.cities
		},
		getPrice: (state) => state.price,

		getSearchParamsNewAuto(state, getters) {
			const category = getters.getCategory;
			const {from: priceFrom, to: priceTo, currency: priceCurrency} = getters.getPrice;

			const result = {
				'categoryId': category,
				'currencyId': priceCurrency,
				'priceFrom': priceFrom,
				'priceTo': priceTo,
			};

			const location = getters.getLocations || [];
			location.forEach((item = {}, index) => {
				const {city, region} = item;
				if (region) {
					result[`stateId[]`] = region;
				}
				if (city) {
					result[`cityId[]`] = city;
				}
			});

			const series = getters.series || [];
			series.forEach((item = {}, index) => {
				const {brand, model, years: {from: yearsFrom, to: yearsTo} = {}} = item;
				result[`markaId[]`] = brand;
				result[`modelId[]`] = model;
				result[`yearFrom`] = yearsFrom;
				result[`yearTo`] = yearsTo;
			});

			return result
		},
		getSearchParamsNewAutoAdvanced(state, getters) {
			const category = getters.getCategory;
			const {from: priceFrom, to: priceTo, currency: priceCurrency} = getters.getPrice;

			const result = {
				'category_id': category,
				'currency_id': priceCurrency,
				'price_from': priceFrom,
				'price_to': priceTo,
			};

			const location = getters.getLocations || [];
			location.forEach((item = {}, index) => {
				const {city, region} = item;
				if (region) {
					result[`stateId`] = region;
				}
				if (city) {
					result[`cityId`] = city;
				}
			});

			const series = getters.series || [];
			series.forEach((item = {}, index) => {
				const {brand, model, years: {from: yearsFrom, to: yearsTo} = {}} = item;
				result[`marka_id[]`] = brand;
				result[`model_id[]`] = model;
				result[`yearFrom[${index}]`] = yearsFrom;
				result[`yearTo[${index}]`] = yearsTo;
			});

			return result
		},
	}
}
