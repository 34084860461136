import Vue from 'vue';
import _laxios from '../../_laxios';

export default {
	namespaced: true,
	state: {
		ids: [],
		count: undefined
	},
	actions: {
		search: ({commit}, params = {}) => {
			return _laxios.queryConvertNew.request({params}).then(({converted}) => {
				return _laxios.search.expandUrl({indices: params.indexName}).request({params: converted})
					.then((payload) => (commit('set', {payload})));
			});
		}
	},
	mutations: {
		set(state, {payload}) {
			let {result: {search_result: {count}}, result: {search_result: {ids}}} = payload;
			Vue.set(state, 'ids', ids.map(Number));
			Vue.set(state, 'count', count);
		}
	},
	getters: {
		count(state) {
			return state.count;
		},
		ids(state) {
			return state.ids;
		}
	}
};
