export default {
  messages: {
    uk: {
      'Пакети перевірок': 'Пакети перевірок',
      Всі: 'Всі',
      Активні: 'Активні',
      Архів: 'Архів',
      Деталі: 'Деталі',
      'Пакет активовано': 'Пакет активовано',
      'Придбати пакет': 'Придбати пакет',
    },
    ru: {
      'Пакети перевірок': 'Пакеты проверок',
      Всі: 'Все',
      Активні: 'Активные',
      Архів: 'Архив',
      Деталі: 'Детали',
      'Пакет активовано': 'Пакет активирован',
      'Придбати пакет': 'Приобрести пакет',
    },
  },
};
