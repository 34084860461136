import Vue from 'vue';
import axios from 'axios';

// @todo винести в окремий модуль
let host;
let scheme;
switch (process.env.NODE_ENV) {
  case 'development':
    host = 'autolocal.ria.com';
    scheme = process.env.RIA_SCHEME || 'https';
    break;
  case 'dev-cluster':
    host = 'dev-auto.ria.com';
    scheme = 'https';
    break;
  case 'production':
  default:
    host = 'auto.ria.com';
    scheme = 'https';
    break;
}
const base = `${scheme}://${host}`;

export default {
  namespaced: true,
  state: {
    lib: {},
    byCategory: {},
    hash: {},
  },
  actions: {
    fetch: ({
      commit,
      rootGetters,
      getters,
    }, { category } = {}) => {
      const { 'lang/id': langId } = rootGetters;
      if (category && getters.hash({ category }) !== langId) {
        commit('hash', {
          category,
          langId,
        });
        axios(`${base}/api/eco_standart?langId=${langId}`)
          .then((payload) => {
            commit('set', {
              // шоб стандарти виводились від більшого до меншого
              payload: payload.data.reverse(),
              category,
            });
          })
          .catch((err) => {
            console.error('Помилка отримання екостандартів:', err);
          });
      }
    },
  },
  mutations: {
    hash(state, {
      category,
      langId,
    } = {}) {
      Vue.set(state.hash, category, langId);
    },
    set(state, {
      payload,
      category,
    } = {}) {
      Vue.set(state.byCategory, category, []);
      payload.forEach((iten) => {
        Vue.set(state.lib, iten.value, iten);
        state.byCategory[category].push(iten.value);
      });
    },
  },
  getters: {
    hash: (state) => function ({ category }) {
      return state.hash[category];
    },
    ecoStandarts: (state) => function ({ category }) {
      if (state.byCategory[category]) {
        return state.byCategory[category]
          .map((id) => state.lib[id]);
      }
      return [];
    },
    ecoStandart(state) {
      return function (id) {
        return state.lib[id];
      };
    },
  },
};
