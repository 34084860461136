import _laxios from '../../_laxios';
import newAuto from './newAuto';
import Vue from 'vue';

export default {
	namespaced: true,
	modules: {
		newAuto
	},
	state: {
		category: 1,
		series: [],
		locations: [],
		verifiedVIN: 0,
		price: {from: undefined, to: undefined, currency: undefined}
	},
	actions: {
		setVerifiedVIN: ({commit}, payload) => {
			commit('setVerifiedVIN', Number(payload));
		},
		setIndices: ({commit}, payload) => {
			commit('setIndices', payload);
		},
		setCategory: ({commit, getters}, payload) => {
			if (getters.getCategory !== payload) {
				if (payload) {
					commit('resetSeries');
				}
				commit('setCategory', payload);
			}
		},
		setOnlyBrand: ({commit}, payload) => {
			commit('setBrand', {index: 0, payload});
		},
		setOnlyModel: ({commit}, payload) => {
			commit('setModel', {index: 0, payload});
		},
		setOnlyYears: ({commit}, payload) => {
			commit('setYears', {index: 0, payload});
		},
		setOnlyState: ({commit, dispatch}, id) => {
			commit('resetLocations');
			dispatch('setState', {index: 0, id});
		},
		setState: ({commit}, {index, id}) => {
			if (id) {
				commit('setLocation', {index, payload: {state: id}});
			}
		},
		setOnlyCity: ({commit, dispatch}, id) => {
			commit('resetLocations');
			dispatch('setCity', {index: 0, id});
		},
		setCity: ({commit, rootGetters}, {index, id}) => {
			if (id) {
				let {'shared/locations/city': getCity} = rootGetters;
				const {state} = getCity(id) || {};
				commit('setLocation', {index, payload: {state, city: id}});
			}
		},
		getCatalogParams({getters}) {
			let data = getters.getCatalogDispatcherParams;
			return _laxios.dispatchCatalog.request({data});
		},
		getSearchData({getters}) {
			let params = getters.getSearchQuery;
			return _laxios.search.request({params});
		},
		reset({commit}) {
			commit('reset');
		}
	},
	mutations: {
		reset(state) {
			Vue.set(state, 'category', 1);
			Vue.set(state, 'verifiedVIN', 0);
			Vue.set(state, 'series', []);
			Vue.set(state, 'locations', []);
			Vue.set(state, 'price', {from: undefined, to: undefined, currency: undefined});
		},
		setVerifiedVIN(state, payload) {
			Vue.set(state, 'verifiedVIN', payload);
		},
		setIndices(state, value) {
			switch (value) {
				case 'all':
					Vue.set(state, 'indexName', 'auto,order_auto,newauto_search');
					break;
				case 'bu':
					Vue.set(state, 'indexName', 'auto');
					break;
				case 'order':
					Vue.set(state, 'indexName', 'order_auto');
					break;
				default:
					Vue.set(state, 'indexName', '');
					break;
			}
		},
		setCategory: (state, payload) => {
			const value = parseInt(payload);
			state.category = Number.isFinite(value) && value ? value : undefined;
		},
		setBrand: (state, {index, payload = {}}) => {
			const value = parseInt(payload);
			const brand = Number.isFinite(value) && value ? value : undefined;
			let {[index]: {brand: brandOld, years} = {}} = state.series;
			if (brandOld !== brand) {
				state.series.splice(index, 1, {brand, years});
			}
		},
		setModel: (state, {index, payload}) => {
			const value = parseInt(payload);
			const model = Number.isFinite(value) && value ? value : undefined;
			const {[index]: {brand, model: modelOld, years} = {}} = state.series;
			if (modelOld !== model) {
				state.series.splice(index, 1, {brand, model, years});
			}
		},
		setYears: (state, {index, payload}) => {
			const years = payload;
			const {[index]: {brand, model} = {}} = state.series;
			state.series.splice(index, 1, {brand, model, years});
		},
		setLocation: (state, {index, payload}) => {
			state.locations.splice(index, 1, payload);
		},
		resetSeries: (state) => state.series.splice(0, state.series.length),
		resetLocations: (state) => state.locations.splice(0, state.locations.length),
		setPrice: (state, payload) => state.price = payload,
	},
	getters: {
		getVerifiedVIN: (state) => state.verifiedVIN,
		getIndices: (state) => state.indexName,
		getCategory: (state) => state.category,
		series(state) {
			return state.series;
		},
		getSpecificSeries(state, getters) {
			return function (index) {
				return getters.series[index];
			};
		},
		getSpecificBrand(state) {
			return function (index) {
				let {[index]: {brand: value} = {}} = state.series;
				return value;
			};
		},
		getSpecificModel(state) {
			return function (index) {
				let {[index]: {model: value} = {}} = state.series;
				return value;
			};
		},
		getSpecificYears(state) {
			return function (index) {
				let {[index]: {years: value} = {}} = state.series;
				return value;
			};
		},
		getLocations(state) {
			return state.locations;
		},
		getSpecificLocation(state, getters) {
			return function (index) {
				return getters.getLocations[index];
			};
		},
		getPrice: (state) => state.price,
		getCatalogDispatcherParams(state, getters) {
			const category = getters.getCategory;
			const {from: priceFrom, to: priceTo, currency: priceCurrency} = getters.getPrice;

			const result = {
				category_id: category,
				currency: priceCurrency,
				price_ot: priceFrom,
				price_do: priceTo
			};

			{
				const {city, state} = getters.getSpecificLocation(0) || {};
				if (state) {
					result['state[0]'] = state;
				}
				if (city) {
					result['city[0]'] = city;
				}
			}

			const {brand, model, years: {from: yearsFrom, to: yearsTo} = {}} = getters.getSpecificSeries(0) || {};
			result['marka_id'] = brand;
			result['model_id'] = model;
			result['s_yers[0]'] = yearsFrom;
			result['po_yers[0]'] = yearsTo;

			const verifiedVin = getters.getVerifiedVIN;
			if (verifiedVin) {
				result['verified.VIN'] = verifiedVin;
			}

			return result;
		},
		getSearchParamsUsedAutoV1(state, getters) {
			const category = getters.getCategory;
			const {from: priceFrom, to: priceTo, currency: priceCurrency} = getters.getPrice;

			const result = {
				category_id: category,
				currency: priceCurrency,
				price_ot: priceFrom,
				price_do: priceTo
			};

			const location = getters.getLocations || [];
			location.forEach((item = {}, index) => {
				const {city, region} = item;
				if (region) {
					result[`state[${index}]`] = region;
				}
				if (city) {
					result[`city[${index}]`] = city;
				}
			});

			const series = getters.series || [];
			series.forEach((item = {}, index) => {
				const {brand, model, years: {from: yearsFrom, to: yearsTo} = {}} = item;
				result[`marka_id[${index}]`] = brand;
				result[`model_id[${index}]`] = model;
				result[`s_yers[${index}]`] = yearsFrom;
				result[`po_yers[${index}]`] = yearsTo;
			});

			return result;
		},
		getSearchParamsUsedAutoV2(state, getters) {
			const indexName = getters.getIndices;
			const category = getters.getCategory;
			const {from: priceFrom, to: priceTo, currency: priceCurrency} = getters.getPrice;

			let result = {
				'categories.main.id': category,
				'price.currency': priceCurrency,
				'price.USD.gte': priceFrom, // @todo добавить другие валюты
				'price.USD.lte': priceTo,
				'indexName': indexName,
			};

			const location = getters.getLocations || [];
			location.forEach((item = {}, index) => {
				const {city, state} = item;
				if (state) {
					result[`region.id[${index}]`] = state;
				}
				if (city) {
					result[`city.id[${index}]`] = city;
				}
			});

			const series = getters.series || [];
			series.forEach((item = {}, index) => {
				const {brand, model, years: {from: yearsFrom, to: yearsTo} = {}} = item;
				result[`brand.id[${index}]`] = brand;
				result[`model.id[${index}]`] = model;
				result[`year[${index}].gte`] = yearsFrom;
				result[`year[${index}].lte`] = yearsTo;
			});

			const verifiedVin = getters.getVerifiedVIN;
			if (verifiedVin) {
				result['verified.VIN'] = verifiedVin;
			}

			return result;
		}
	}
};
