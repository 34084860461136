export class Range {
	constructor(from, to) {
		this.from = Number.isFinite(from) ? from : Number.MIN_VALUE;
		this.to = Number.isFinite(to) ? to : Number.MAX_VALUE;
		this.resetCursor();
	}

	resetCursor() {
		let current = this.from;
		let cursor = Symbol.for('cursor');
		this[cursor] = function* () {
			while (current <= this.to) {
				yield current++;
			}
		}
	}

	[Symbol.iterator]() {
		let cursor = Symbol.for('cursor');
		return this[cursor]();
	}
}
export default Range;

export class YearsRange extends Range {
	constructor(from, to) {
		from = Number.isFinite(from) ? from : 0;
		to = Number.isFinite(to) ? to : (new Date()).getFullYear();
		super(from, to);
	}
}
