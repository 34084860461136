import Vue from 'vue';
import axios from 'axios';

// @todo винести в окремий модуль
let host;
let scheme;
switch (process.env.NODE_ENV) {
  case 'development':
    host = 'autolocal.ria.com';
    scheme = process.env.RIA_SCHEME || 'http';
    break;
  case 'dev-cluster':
    host = 'dev-auto.ria.com';
    scheme = 'https';
    break;
  case 'production':
  default:
    host = 'auto.ria.com';
    scheme = 'https';
    break;
}
const base = `${scheme}://${host}`;

export default {
  namespaced: true,
  state: {
    options: {},
    byCategory: {},
    hash: {},
    additional: [],
  },
  mutations: {
    set(state, {
      payload,
      category
    } = {}) {
      Vue.set(state.byCategory, category, []);
      for (let type in payload) {
        if (payload.hasOwnProperty(type)) {
          payload[type].forEach(function (option) {
            option = Object.assign(option, { type });
            Vue.set(state.options, option.value, option);
            state.byCategory[category].push(option.value);
          });
        }
      }
    },
    hash(state, {
      category,
      langId
    } = {}) {
      Vue.set(state.hash, category, langId);
    },
    additional(state, data) {
      Vue.set(state, 'additional', data);
    }
  },
  actions: {
    fetch: ({
      commit,
      rootGetters,
      getters
    }, { category } = {}) => {
      let { 'lang/id': langId } = rootGetters;
      if (category && getters.hash({ category }) !== langId) {
        commit('hash', {
          category,
          langId
        });
        axios(`${base}/api/categories/${category}/auto_options/_group?langId=${langId}`)
          .then(function (payload) {
            commit('set', {
              payload: payload.data,
              category
            });
          })
          .catch(function (err) {
            console.error('Ошибка получения опцый:', err);
          });
      }
    },
    additionalOptions: ({
      commit,
      rootGetters,
      getters,
    }, { category } = {}) => {
      const { 'lang/id': langId } = rootGetters;
      if (category === 1) {
        return axios(`${base}/demo/api/v2/public/options/additional?langId=${langId}`)
          .then(({ data = [] } = {}) => {
            commit('additional', data);
          })
          .catch((error) => console.error('Ошибка получения опцый:', error));
      }
      commit('additional', []);
      return Promise.resolve([]);
    },
  },
  getters: {
    additionalOptions: (state) => {
      return state.additional;
    },
    hash: (state) => {
      return function ({ category }) {
        return state.hash[category];
      };
    },
    options: (state) => {
      return function ({
        category,
        type,
      }) {
        if (state.byCategory[category]) {
          return state.byCategory[category]
            .map(id => {
              return state.options[id];
            })
            .filter(option => option.type === type);
        } else {
          return [];
        }
      };
    },
    option(state) {
      return function (id) {
        return state.options[id];
      };
    }
  }
};
