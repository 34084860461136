import {zipObject} from '../../helpers/_lodash';
import Vue from 'vue';

let state = {
};

export default {
  state,
  namespaced: true,
  mutations: {
    set: (state, payload) => {
      try {
        for (let name in payload) {
          name in state
              ? (state[name] = payload[name])
              : console.error(`Not correct Data with name <<${name}>> in the state, set default value in store/AddForm.js; Payload =>`, payload)
        }
      } catch (e) {
        console.error("Set data to store : ", e);
      }
    }
  },
  getters: zipObject(Object.keys(state), Object.keys(state)
      .map((name) => {
        return (state) => {
          return state[name];
        }
      })),
  actions: {
    uploadImage(context, payload) {
      return Vue.http.post('/plupload', payload)
          .then(({body} = {}) => body)
          .catch(console.error);
    }
  }
}
