import getModificationKey from "../helpers/getModificationKey";

export default {
    allModificationsData: (state) => state.modifications || {},
    modificationsByIndexBlock: (state) => (blockIndex, exclude, brandId) => {
        if(!brandId) return [];
        const blockKey = getModificationKey({blockIndex, exclude, brandId})

        return Object.entries(state.modifications).reduce((acc, [key, data]) => {
            if(key.includes(blockKey)) acc.push(data);
            return acc
        },[])
    },
    modificationsIds(state){
        if(!Object.values(state.modifications).length) return null;
        return Object.values(state.modifications).reduce((acc, el) => {
            el.modifications.forEach(modification => {
                acc[modification.value] = {
                    ...modification,
                    generationId: el.generationId
                }
            })
            return acc;
        }, {});
    }
}
