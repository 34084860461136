export function cloneDeep(obj, hash = new WeakMap()) {
	if (Object(obj) !== obj) {
		return obj;
	} // primitives
	if (obj instanceof Set) {
		return new Set(obj);
	} // See note about this!
	if (hash.has(obj)) {
		return hash.get(obj);
	} // cyclic reference
	const result = obj instanceof Date ? new Date(obj)
		: obj instanceof RegExp ? new RegExp(obj.source, obj.flags)
			: obj.constructor ? new obj.constructor()
				: Object.create(null);
	hash.set(obj, result);
	if (obj instanceof Map) {
		Array.from(obj, ([key, val]) => result.set(key, cloneDeep(val, hash)));
	}
	return Object.assign(result, ...Object.keys(obj).map(
		key => ({[key]: cloneDeep(obj[key], hash)})));
}

export function get(object, keys, defaultVal) {
	keys = Array.isArray(keys) ? keys : keys.split('.');
	object = object[keys[0]];
	if (object && keys.length > 1) {
		return get(object, keys.slice(1));
	}
	return object === undefined ? defaultVal : object;
}

export function set(object, keys, val) {
	keys = Array.isArray(keys) ? keys : keys.split('.');
	if (keys.length > 1) {
		object[keys[0]] = object[keys[0]] || {};
		return set(object[keys[0]], keys.slice(1), val);
	}
	object[keys[0]] = val;
}

export function zipObject(props, values) {
	return props.reduce((prev, prop, i) => {
		return Object.assign(prev, {[prop]: values[i]});
	}, {});
}

export function throttle(func, ms) {

	let isThrottled = false,
		savedArgs,
		savedThis;

	function wrapper() {

		if (isThrottled) { // (2)
			savedArgs = arguments;
			savedThis = this;
			return;
		}

		func.apply(this, arguments); // (1)

		isThrottled = true;

		setTimeout(function () {
			isThrottled = false; // (3)
			if (savedArgs) {
				wrapper.apply(savedThis, savedArgs);
				savedArgs = savedThis = null;
			}
		}, ms);
	}

	return wrapper;
}
