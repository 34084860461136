let timeData = {};
module.exports = {
	/**
	 * заполняет данные для компоненты, если это редактировани
	 * @param data
	 * @param createdAdvertisement
	 */
	buildComponentData: function (data, createdAdvertisement = {}) {
		if (this.$route.name === 'editAuto') {
			for (let name in data) {
				data[name] = createdAdvertisement[name] || data[name];
			}
		}
		return data;
	},
	getResponseBody: function (response) {
		return response && response.body;
	},
	setData: function (data) {
		for (let key in data) {
			this[key] = data[key];
		}
		return data;
	},
	setDefaultSelectValue: function (data, selectedFieldName, checkFieldName, emitEventFunction) {
		this.selected[selectedFieldName] = Number(this.selected[selectedFieldName]) || 0;

		let selectedValueInData = Boolean(
			data.filter((item) => {
				return item[checkFieldName] === this.selected[selectedFieldName];
			}).length);


		!selectedValueInData && (this.selected[selectedFieldName] = 0);
		!selectedValueInData &&
		emitEventFunction &&
		emitEventFunction();
	},
	errorHandler: (message, defaultValue) => {
		return (error) => {
			console.error(message, error);
			return defaultValue;
		}
	},
	timerStart: function (name) {
		timeData[name] = new Date().getTime();
		return name;

	},
	timerStop: function (name) {
		let res = [name, (new Date().getTime() - timeData[name]).toLocaleString().split(' ').join(), 'second'].join(' == ');
		delete timeData[name];
		return res;

	}
};
