export default {
    searchType: (state) => state.searchType.value || state.searchType.defaultValue,
    searchTypes: (state) => getFieldList(state, 'searchType'),
    category : (state) => state.category?.value || state.category.defaultValue,
    categories : (state) => getFieldList(state, 'category'),
    brand : (state) => state.brand?.value || state.brand.defaultValue,
    brands : (state) => getFieldList(state, 'brand'),
    model : (state) => state.model?.value || state.model?.defaultValue,
    models: (state) => getFieldList(state, 'model'),
    country : (state) => state.country?.value || state.country.defaultValue,
    countries : (state) => getFieldList(state, 'country'),
    year : (state) => state.year.value || state.year.defaultValue,
    years : (state) => getFieldList(state, 'year'),
    price : (state) => state.price?.value || state.price.defaultValue,
    getAutoInterSearchParams : (state, getters) => {
        const {
            category,
            brand,
            model,
            year,
            price,
            country
        } = getters;
        let params = {
            currency:2
        };
        if(!!category)     params['vehicleClass_id'] = category.id;
        if(!!brand)        params['make_id'] = brand.id;
        if(!!model)        params['model_id'] = model.id;
        if(!!year?.from)   params['yearFrom'] = year.from.id;
        if(!!year?.to)     params['yearTo'] = year.to.id;
        if(!!price?.from)  params['priceFrom'] = price.from;
        if(!!price?.to)    params['priceTo'] = price.to;
        if(!!country)      params['country_int'] = country.id;
        return params
    }
}

function getFieldList(state, field){
    const dependency = state[field].dependencies.on;
    if(dependency.length && !state[field].fieldList?.length) {
        const dependencyFieldIsFilled = dependency
            .reduce((acc, el) => {
                acc.push(state[el].value || false)
                return acc
            }, [])
            .every(el => el);
        return dependencyFieldIsFilled ? state[field].fieldList : []
    } else {
        return state[field].fieldList || []
    }
}
