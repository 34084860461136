export default [
    {
        id: 0,
        title: 'Всі',
        value: 0,
    },
    {
        id: 1,
        title:  'Перевірки VIN+, VIN MAX, CARFAX',
        value: 'with_autotest_vin_check',
    },
    {
        id: 2,
        title: 'Технічно на СТО',
        value: 'with_autotest_tech_check',
    },
    {
        id: 3,
        title: 'Виїзна перевірка AUTO.RIA',
        value: 'with_autotest_corporate_check',
    }
]
