import { mapGetters } from 'vuex';
import _laxios from '../../../../store/_laxios';
import { get } from '../../../../helpers/cookie';

export default {
  metaInfo() {
    return {
      script: [
        { src: 'https://chat.ria.com/js/minichat.js', async: 'async' },
        { innerHTML: 'window.chatUrl = "https://chat.ria.com"' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userData: 'Common/userData',
    }),
    href() {
      return this.langId ? `https://chat.ria.com/3/0/0/?lang_id=${this.langId}` : '';
    },
  },
  data() {
    return { data: {}, activated: false };
  },
  watch: {
    userData(val = {}) {
      if (!this.activated) {
        if (val.userId || val.webClientId) {
          this.chatStatus();
          this.activated = true;
        }
      }
    },
  },
  methods: {
    sendStatistic() {
      const device = this.isDesktop ? 'desktop' : 'mobile';
      this.gaEvent(`Heder_${device}`, `Icon_Chat_on_Heder_${device}`, 'Chat_auto_ria');
      this._slonik({
        event_id: 354,
        chat_position: 3,
        utm_source: get('slonik_utm_source'),
        utm_medium: get('slonik_utm_medium'),
        utm_campaign: get('slonik_utm_campaign'),
        cpc_source: get('gclid') ? 1 : undefined,
        screentype: this.isDesktop ? 1 : (this.isMobile ? 2 : 3),
        chat_project_id: 3,
      });
    },
    chatStatus() {
      const { userId, webClientId } = this.userData || {};
      return _laxios.chatRia.expandUrl({ userId: userId || webClientId }).request()
        .then((data) => {
          this.data = data;
        }).catch((e) => {
          console.error('chatRia', e.message);
        });
    },
  },
};
