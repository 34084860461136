import Vue from 'vue';
import _laxios from '../../../_laxios';
import advertisementBase from '../../../_graphql/queries/advertisementBase';

import {
  isSetByAutoId,
  autotestPriceLaw,
  autotestVinMaxBrands,
  autotestVinCheckPrices,
  getCities,
  getCitySTOs,
  autotestPackageVerifications,
  autotestManager,
  usaReportsExist,
  getBidfaxData,
  setNewPhoto,
  sendPhotoToAiRecognition,
  updatePhotosOrder,
  getOrderById,
  autotestGetSTOVerificationsPriceForBrandYear,
  cebiaReportLink,
} from '../../../queries';

export default {
  namespaced: true,
  state: {
    isSetByAutoId: {},
    priceVin: false,
    usaReportsExist: undefined,
    priceJuristical: false,
    cities: {},
    citiesSTOs: {},
    packageVerifications: {},
    manager: 0,
    autotestVinMaxBrands: [],
    autotestVinCheckPrices: null,
    outsiteAutotest: [],
    bidfaxData: {},
    lastOrdersAutoData: [],
    autotestLoggerData: {},
    autotestLoggerDefaultData: {},
    autotestLoggerDefaultDataCheckCar: {},
    orders: {},
    cebiaReportLink: null,
  },
  actions: {
    nais({ rootGetters }, { plateNumber, hash, autoId }) {
      const params = {
        plateNumber,
        hash,
        autoId,
      };
      return _laxios.naisReport
        .expandUrl({ plateNumber })
        .request({ params });
    },
    fetchInfotechReport({ rootGetters }, { type, number }) {
      const { 'lang/id': langId } = rootGetters;
      const params = { langId };
      return _laxios.getInfotechReport
        .expandUrl({ type, number })
        .request({ params });
    },
    fetchUSAReportsExist({ rootGetters }, { vin }) {
      return _laxios.getUSAReportsExist
        .expandUrl({ vin })
        .request();
    },
    async fetchOrders({ commit, rootGetters }) {
      const { 'lang/id': langId } = rootGetters;
      const orders = await _laxios.autotestOrders.request({ params: { fromSite: 1 } });
      const ids = [];
      const ordersObject = orders.reduce((memo, order) => {
        const { auto_id: id } = order;
        ids.push(id);
        Object.assign(memo, {
          [id]: order,
        });
        return memo;
      }, {});
      const data = { query: advertisementBase, variables: { ids, langId } };
      try {
        const { data: { get: advertisements } = {} } = await _laxios.graphql.request({ data });
        const payload = advertisements.map((adv) => ({
          ...adv,
          order: ordersObject[adv.id],
        }));
        commit('lastOrdersAutoData', payload);
      } catch (error) {
        console.log('thats graphql error =====>', error.message);
        console.error(error);
      }
    },
    autotestVinMaxBrands({ commit, getters }) {
      return getters.autotestVinMaxBrands.length
        ? Promise.resolve()
        : autotestVinMaxBrands()
          .then((response) => commit('autotestVinMaxBrands', response));
    },
    autotestVinCheckPrices({ commit, getters }, args) {
      return getters.autotestVinCheckPrices
        ? Promise.resolve()
        : autotestVinCheckPrices(args)
          .then((response) => commit('autotestVinCheckPrices', response));
    },
    getOutsiteAutotest({ commit, getters, rootGetters }, args) {
      const { 'lang/id': langId } = rootGetters;
      return getters.outsiteAutotest.length
        ? Promise.resolve()
        : autotestGetSTOVerificationsPriceForBrandYear({ ...args, langId })
          .then((response) => {
            commit('outsiteAutotest', response);
          });
    },
    isSetByAutoId({ commit, rootGetters, getters }, id) {
      return getters.isSetByAutoId(id)
        ? Promise.resolve()
        : isSetByAutoId(id)
          .then((response) => commit('isSetByAutoId', { id, response }));
    },
    usaReportsExist({ commit, getters }, { autoId, cookies }) {
      return getters.usaReportsExist
        ? Promise.resolve()
        : usaReportsExist(autoId, cookies)
          .then((response) => commit('usaReportsExist', response));
    },
    async fetchBidfax({ commit, getters }, advId) {
      const [res = {}] = await getBidfaxData(advId);
      commit('setBidfax', res);
      return res;
    },
    priceJuristical({ commit, rootGetters, getters }) {
      return getters.priceJuristical
        ? Promise.resolve()
        : autotestPriceLaw().then((response) => commit('priceJuristical', response));
    },
    cities({ commit, rootGetters, getters }, parameters) {
      return getters.cities(parameters)
        ? Promise.resolve()
        : getCities(parameters).then((response) => commit('cities', { parameters, response }));
    },
    citiesSTOs({ commit, rootGetters, getters }, parameters) {
      return getters.citiesSTOs(parameters)
        ? Promise.resolve()
        : getCitySTOs(parameters).then((response) => commit('citiesSTOs', { parameters, response }));
    },
    packageVerifications({ commit, rootGetters, getters }, id = 1) {
      const { 'lang/id': langId } = rootGetters;
      return getters.packageVerifications(id)
        ? Promise.resolve()
        : autotestPackageVerifications(id, langId).then((response) => commit('packageVerifications', { id, response }));
    },
    manager({ commit, rootGetters, getters }, id = 1) {
      const { 'lang/id': langId } = rootGetters;
      return getters.manager
        ? Promise.resolve()
        : autotestManager(langId).then((response) => commit('manager', response));
    },
    STOList({}, params) {
      return _laxios.STOList.request({ params })
        .then((list) => list)
        .catch((error) => console.error('ERROR:::STOList', error.message));
    },
    reviewsAutotest({}, params) {
      return _laxios.reviewsAutotest.request({ params })
        .then((reviews) => reviews)
        .catch((error) => console.error('ERROR:::reviewsAutotest', error.message));
    },
    setNewReportPhoto({}, { reportId, sortingIndex }) {
      return setNewPhoto(`reportId=${reportId}&sortingIndex=${sortingIndex}`);
    },
    recognizePhoto({}, id){
      return sendPhotoToAiRecognition(id);
    },
    changePhotosOrder({}, photos) {
      return updatePhotosOrder(photos);
    },
    fetchOrderById({ commit }, id) {
      return getOrderById(id)
        .then((order) => {
          commit('setOrderById', { id, order });
          return order;
        });
    },
    fetchCebiaReportLink({ commit, getters }, advId) {
      return getters.cebiaReportLink
          ? Promise.resolve()
          : cebiaReportLink(advId)
              .then((response) => commit('cebiaReportLink', response));
    },
  },
  mutations: {
    isSetByAutoId(state, { id, response } = {}) {
      return response
        ? Vue.set(state.isSetByAutoId, id, response)
        : undefined;
    },
    autotestVinMaxBrands(state, response) {
      return Vue.set(state, 'autotestVinMaxBrands', response);
    },
    lastOrdersAutoData(state, lastOrdersAutoData) {
      Vue.set(state, 'lastOrdersAutoData', lastOrdersAutoData);
    },
    autotestVinCheckPrices(state, response) {
      return Vue.set(state, 'autotestVinCheckPrices', response);
    },
    outsiteAutotest(state, response) {
      return Vue.set(state, 'outsiteAutotest', response);
    },
    usaReportsExist(state, response) {
      return response
        ? Vue.set(state, 'usaReportsExist', response)
        : undefined;
    },
    priceJuristical(state, response) {
      return response
        ? Vue.set(state, 'priceJuristical', response)
        : undefined;
    },
    cities(state, { parameters, response }) {
      return response
        ? Vue.set(state.cities, JSON.stringify(parameters), response)
        : undefined;
    },
    citiesSTOs(state, { parameters, response }) {
      return response
        ? Vue.set(state.citiesSTOs, JSON.stringify(parameters), response)
        : undefined;
    },
    packageVerifications(state, { id, response }) {
      return response
        ? Vue.set(state.packageVerifications, id, response)
        : undefined;
    },
    manager(state, payload) {
      return payload
        ? Vue.set(state, 'manager', payload)
        : undefined;
    },
    setBidfax(state, payload) {
      Vue.set(state, 'bidfaxData', payload);
    },
    autotestLoggerData(state, payload) {
      Vue.set(state, 'autotestLoggerData', payload);
    },
    autotestLoggerDefaultData(state, payload) {
      Vue.set(state, 'autotestLoggerDefaultData', { ...state.autotestLoggerDefaultData, ...payload });
    },
    autotestLoggerDefaultDataCheckCar(state, payload) {
      Vue.set(state, 'autotestLoggerDefaultDataCheckCar', { ...state.autotestLoggerDefaultDataCheckCar, ...payload });
    },
    setOrderById(state, { id, order }) {
      Vue.set(state.orders, id, order);
    },
    cebiaReportLink(state, response) {
      return Vue.set(state, 'cebiaReportLink', response);
    },
  },
  getters: {
    isSetByAutoId(state) {
      return function (id) {
        return state.isSetByAutoId[id];
      };
    },
    autotestVinMaxBrands(state) {
      return state.autotestVinMaxBrands;
    },
    lastOrdersAutoData({ lastOrdersAutoData }) {
      return lastOrdersAutoData;
    },
    autotestVinCheckPrices(state) {
      return state.autotestVinCheckPrices;
    },
    outsiteAutotest(state) {
      return state.outsiteAutotest;
    },
    usaReportsExist(state) {
      return state.usaReportsExist;
    },
    hasUSAReport(state, getters) {
      const bidfax = getters.getBidfax?.link;
      const carfax = state.usaReportsExist?.carfax?.status === 200;
      const autocheck = state.usaReportsExist?.autocheck?.status === 200;

      return state.usaReportsExist?.checkSum && (carfax || autocheck || bidfax);
    },
    nameCompanyUSAReport(state, getters) {
      const bidfax = getters.getBidfax?.link && 'CARFAX';
      const carfax = state.usaReportsExist?.carfax?.status === 200 && 'CARFAX';
      const autocheck = state.usaReportsExist?.autocheck?.status === 200 && 'AutoCheck';

      return !getters.hasUSAReport ? null : (carfax || autocheck || bidfax);
    },
    priceJuristical(state) {
      return state.priceJuristical;
    },
    cities(state) {
      return function (parameters) {
        return state.cities[JSON.stringify(parameters)];
      };
    },
    citiesSTOs(state) {
      return function (parameters) {
        return state.citiesSTOs[JSON.stringify(parameters)];
      };
    },
    packageVerifications(state) {
      return function (id) {
        return state.packageVerifications[id];
      };
    },
    manager(state) {
      return state.manager;
    },
    getBidfax(state) {
      return state.bidfaxData;
    },
    autotestLoggerData(state) {
      return state.autotestLoggerData;
    },
    autotestLoggerDefaultData(state) {
      return state.autotestLoggerDefaultData;
    },
    autotestLoggerDefaultDataCheckCar(state) {
      return state.autotestLoggerDefaultDataCheckCar;
    },
    getOrder(state) {
      return function (id) {
        return state.orders[id] || {};
      };
    },
    cebiaReportLink: (state) => state.cebiaReportLink,
  },
};
