const singleton = require('../../helpers/singleton');
const IntersectionObserverWrapper = require('./IntersectionObserverWrapper');

const options = {
	root: null,
	rootMargin: '333px',
	threshold: 0.01
};

module.exports = singleton(IntersectionObserverWrapper)(options);
