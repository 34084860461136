export default {
  messages: {
    ru: {
      'нет возможности проверить': 'Увы, у нас нет возможности проверить авто в указанном вами городе',
      'Виберіть тип перевірки': 'Выберите тип проверки',
      Технічна: 'Техническая',
      'Дані авто, яке хочете перевірити': 'Данные авто, которое хотите проверить',
      'Введіть дані авто, яке хочете перевірити': 'Введите данные авто, которое хотите проверить',
      Выберите: 'Выберите',
      'Рік випуску': 'Год выпуска',
      'Вкажіть де знаходиться авто': 'Укажите где находится авто',
      Місто: 'Город',
      'Контакти власника (за можливості)': 'Контакты владельца (по возможности)',
      владельца: 'владельца',
      Імя: 'Имя',
      Введіть: 'Введите',
      'VIN-код або держ.номер': 'VIN-код или гос.номер',
      'До сплати': 'К оплате',
      Перевірити: 'Проверить',
      проверки: 'проверки',
      Итог: 'Итог',
      'Заказать проверку': 'Заказать проверку',
      Техническая: 'Техническая',
      Юридическая: 'Юридическая',
      'Ожидайте ответ': 'Заявка отправлена. Ожидайте ответ',
      'Ошибка при обработке заявки': 'Ошибка при обработке заявки. Пожалуйста, попробуйте позже.',
      'Заявка принята': 'Спасибо, номер вашего заказа',
      'Мы отправим запрос на оплату на ваш номер': 'Сбор информации начнется после оплаты проверки. При отсутствии оплаты с вами свяжется менеджер',
      'Заполните форму': 'Заполните все поля формы',
      Марка: 'Марка',
      Модель: 'Модель',
      Телефон: 'Телефон',
      Область: 'Область',
      'Номер для зв\'язку': 'Номер для связи',
      'За даним VIN-кодом або держ.номером відсутня інформація в цифрових реєстрах МВС': 'По данному VIN-коду или гос.номеру отсутствует информация в цифровых реестрах МВД',
      'По держ.номеру / VIN ми автоматично заповнили параметри згідно з базами даних МВС': 'По гос.номеру / VIN мы автоматически заполнили параметры согласно базам данных МВД',
      'Оберіть марку, модель та рік авто, щоб підібрати СТО': 'Выберите марку, модель и год авто, чтобы подобрать СТО',
      'Для створення заявки на отримання звіту введіть VIN-коду': 'Чтобы создать заявку и получить отчет – введите VIN-код',
      'За даною машиною перевірка CarFax/AutoCheck недоступна': 'Для этого авто проверка CarFax / AutoCheck недоступна',
    },
    uk: {
      'нет возможности проверить': 'На жаль, у нас немає можливості перевірити авто в зазначеному вами місті',
      'Виберіть тип перевірки': 'Виберіть тип перевірки',
      Технічна: 'Технічна',
      'Дані авто, яке хочете перевірити': 'Дані авто, яке хочете перевірити',
      'Введіть дані авто, яке хочете перевірити': 'Введіть дані авто, яке хочете перевірити',
      Выберите: 'Виберіть',
      'Рік випуску': 'Рік випуску',
      'Вкажіть де знаходиться авто': 'Вкажіть де знаходиться авто',
      Місто: 'Місто',
      'Контакти власника (за можливості)': 'Контакти власника (за можливості)',
      владельца: 'власника',
      Імя: 'Iм\'я',
      Введіть: 'Введіть',
      'VIN-код або держ.номер': 'VIN-код або держ.номер',
      'До сплати': 'До сплати',
      Перевірити: 'Перевірити',
      проверки: 'перевірки',
      Итог: 'Підсумок',
      'Заказать проверку': 'Заказати перевірку',
      Техническая: 'Технічна',
      Юридическая: 'Юридична',
      'Ожидайте ответ': 'Заявку відправлено. Чекайте на відповідь',
      'Ошибка при обработке заявки': 'Помилка при обробці заявки. Будь ласка, спробуйте пізніше.',
      'Заявка принята': 'Дякуємо, номер вашого замовлення',
      'Мы отправим запрос на оплату на ваш номер': 'Збір інформації почнеться після оплати перевірки. За умови відсутності оплати з вами зв\'яжеться менеджер',
      'Заполните форму': 'Заповніть всі поля форми',
      Марка: 'Марка',
      Модель: 'Модель',
      Телефон: 'Телефон',
      Область: 'Область',
      'Номер для зв\'язку': 'Номер для зв\'язку',
      'За даним VIN-кодом або держ.номером відсутня інформація в цифрових реєстрах МВС': 'За даним VIN-кодом або держ.номером відсутня інформація в цифрових реєстрах МВС',
      'По держ.номеру / VIN ми автоматично заповнили параметри згідно з базами даних МВС': 'По держ.номеру / VIN ми автоматично заповнили параметри згідно з базами даних МВС',
      'Оберіть марку, модель та рік авто, щоб підібрати СТО': 'Оберіть марку, модель та рік авто, щоб підібрати СТО',
      'Для створення заявки на отримання звіту введіть VIN-коду': 'Щоб створити заявку та отримати звіт – введіть VIN-код',
      'За даною машиною перевірка CarFax/AutoCheck недоступна': 'Для цього авто перевірка CarFax / AutoCheck недоступна',
    },
  },
};
