const { getResponseBody, errorHandler, setData } = require('../../app/helpers/functionHelper');
const _laxios = require('../store/_laxios');

module.exports = {
  methods: {
    setData,
    getResponseBody,
    getNotifications() {
      return _laxios.notificationsRingbell.request({})
        .then((data) => data)
        .catch((e) => {
          console.error('ERROR notificationsRingbell', e.toString());
          return { count: 0, data: [] };
        });
    },
    getUserData() {
      return this.$http
        .get('/users/information/')
        .then(this.getResponseBody)
        .catch(errorHandler('getUserData', {}));
    },
    getNotepadData() {
      const EXPIRE_TIME_IN_MINUTES = 60;

      return new Promise((resolve, reject) => {
        try {
          throw new Error('переписать вытяжку с локал стореджа для избранных');
          const userNotepadData = JSON.parse(window.localStorage.userNotepadData);
          if (
            userNotepadData
            && (userNotepadData.addTimestamp / 60000 - new Date().getTime() / 60000) < EXPIRE_TIME_IN_MINUTES) {
            return resolve(userNotepadData.items);
          }
          throw new Error('Cache time is expired');
        } catch (e) {
          const url = '/notepad/api/bookmark/list/?content_type_ids=1&content_type_ids=3&limit=9999&offset=0';

          return this.$http
            .get(url)
            .then((data) => {
              window.localStorage.userNotepadData = JSON.stringify({
                addTimestamp: new Date().getTime(),
                items: data.body,
              });
              return resolve(data.body);
            })
            .catch(reject);
        }
      });
    },
  },
};
