import cabinet from './cabinet';
import main from './main';

export default {
  namespaced: true,
  modules: {
    cabinet,
    main,
  },
};
