/* eslint-disable global-require */
import getSSIInclude from './getSSIInclude';
import { mapGetters } from 'vuex';

export default {
  props: ['full', 'mounted'],
  name: 'VueFooterSSI',
  computed: {
    ...mapGetters({
      isWebView: 'shared/device/isWebView',
    }),
    rawHtml() {
      return getSSIInclude(this.full, { langId: this.langId, mounted: this.mounted });
    },
  },
};
