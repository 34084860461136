import Vue from 'vue';
import _laxios from '../../_laxios';
import searchParam from '../../../../constants/searchParams';
import paramHelper from '../../../helpers/param';
import filterByCheckingOptions from '../../../components/AdvancedSearch/ResultBlock/FilterByChecking/options';

const FILTER_BY_CHECKING_OPTIONS_VALUES = filterByCheckingOptions.map((el) => el.value).filter((el) => el);
const { get } = require('../../../helpers/cookie');

export default {
  namespaced: true,
  state: {
    ABSearchForm: false,
  },
  actions: {
    ABSearchForm: ({ commit }, value) => {
      commit('ABSearchForm', value);
    },
    queryConvert: ({ commit }, query) => _laxios
      .queryConvert
      .request({ params: query })
      .then((payload) => {
        if (payload) {
          if (query.channelId) {
            Object.assign(payload.converted, { channelId: query.channelId });
          }

          if (query.editSubscribe) {
            Object.assign(payload.converted, { editSubscribe: query.editSubscribe });
          }

          if (query.newSubscribe) {
            Object.assign(payload.converted, { newSubscribe: query.newSubscribe });
          }

          commit('setMany', paramHelper.state(payload.converted));
        }
      }).catch((error) => {
        console.error('Ошибка конвертации параметров', error);
      }),
    getSearchData({ getters }) {
      const params = getters.getSearchQuery;
      return _laxios.search.request({ params });
    },
    setMany: ({ commit }, payload) => {
      commit('setMany', payload);
    },
    setCategory({ commit, dispatch, getters }, value) {
      commit('setValue', { name: 'body.id[i]' });
      commit('setValue', { name: 'options.id[i]' });
      commit('setValue', { name: 'drive.id[i]' });
      commit('setValue', { name: 'eco_standart[i]' });
      commit('setValue', { name: 'color.type[i]' });
      commit('setValue', { name: 'gearbox.id[i]' });
      commit('setValue', { name: 'fuel.id[i]' });
      commit('setValue', { name: 'equipment.type.id' });
      commit('setSeats', { gte: 0, lte: 0 });
      commit('setDoors', { gte: 0, lte: 0 });
      commit('setFuelConsumption', { gte: 0, lte: 0 });
      commit('setPower', { gte: 0, lte: 0 });
      commit('setCarrying', { gte: 0, lte: 0 });
      commit('setEngine', { gte: 0, lte: 0 });
      commit('setAxle', 0);
      commit('setWheel', 0);

      getters.getValue({ name: 'series' }).forEach((s, index) => {
        delete s.brand;
        delete s.model;
        if (s.hasOwnProperty('generation')) delete s.generation;
        if (s.hasOwnProperty('modification')) delete s.modification;
        commit('addByIndex', {
          name: 'series',
          index,
          value: s,
        });
      });
      dispatch('shared/generation/resetGeneration', null, { root: true });
      dispatch('shared/modification/resetModification', null, { root: true });

      commit('setCategory', value);
    },
    setEquipmentType({ commit }, value) {
      commit('setValue', { name: 'body.id[i]' });
      commit('setEquipmentType', value);
    },
    setBody({ commit }, payload) {
      const value = Number(payload.value);
      const { set } = payload;
      if (set) {
        commit('addValue', {
          value,
          name: 'body.id[i]',
        });
      } else {
        commit('delValue', {
          value,
          name: 'body.id[i]',
        });
      }
    },
    setCountryOrigin({ commit }, payload) {
      const value = Number(payload.value);
      const { set } = payload;
      if (set) {
        commit('addValue', {
          value,
          name: 'country.origin.id[i]',
        });
      } else {
        commit('delValue', {
          value,
          name: 'country.origin.id[i]',
        });
      }
    },
    setCountryOrigin0({ commit, getters }, payload) {
      const index = Number(payload.index);
      payload.value = payload.value || {};
      payload.value.id = Number(payload.value.id) || 0;
      commit('addByIndex', {
        name: 'country.origin',
        index,
        value: payload.value,
      });
      if (!payload.value.not) {
        getters.getValue({ name: 'series' }).forEach((s, index) => {
          if (!s.not) {
            delete s.brand;
            delete s.model;
            commit('addByIndex', {
              name: 'series',
              index,
              value: s,
            });
          }
        });
      }
    },
    setFuel({ commit }, payload) {
      const value = Number(payload.value);
      const { set } = payload;
      if (set) {
        commit('addValue', {
          value,
          name: 'fuel.id[i]',
        });
        if (value === 3) {
          for (const value of [4, 8]) {
            commit('addValue', {
              value,
              name: 'fuel.id[i]',
            });
          }
        }
      } else {
        commit('delValue', {
          value,
          name: 'fuel.id[i]',
        });
      }
    },
    setGearbox({ commit }, payload) {
      const value = Number(payload.value);
      const { set } = payload;
      if (set) {
        commit('addValue', {
          value,
          name: 'gearbox.id[i]',
        });
        if (value === 2) {
          for (const value of [3, 4, 5]) {
            commit('addValue', {
              value,
              name: 'gearbox.id[i]',
            });
          }
        }
      } else {
        commit('delValue', {
          value,
          name: 'gearbox.id[i]',
        });
      }
    },
    setDrive({ commit }, payload) {
      const value = Number(payload.value);
      const { set } = payload;
      if (set) {
        commit('addValue', {
          value,
          name: 'drive.id[i]',
        });
      } else {
        commit('delValue', {
          value,
          name: 'drive.id[i]',
        });
      }
    },
    setEcoStandart({ commit }, payload) {
      const value = Number(payload.value);
      const { set } = payload;
      if (set) {
        commit('addValue', {
          value,
          name: 'eco_standart[i]',
        });
      } else {
        commit('delValue', {
          value,
          name: 'eco_standart[i]',
        });
      }
    },
    setColorType({ commit }, payload) {
      const value = Number(payload.value);
      const { set } = payload;
      if (set) {
        commit('addValue', {
          value,
          name: 'color.type[i]',
        });
      } else {
        commit('delValue', {
          value,
          name: 'color.type[i]',
        });
      }
    },
    setColor({ commit }, payload) {
      if (payload.hasOwnProperty('color')) {
        const value = Number(payload.color);
        const { set } = payload;
        if (set) {
          commit('addValue', {
            value,
            name: 'color.id[i]',
          });
        } else {
          commit('delValue', {
            value,
            name: 'color.id[i]',
          });
        }
      }
      if (payload.hasOwnProperty('metallic')) {
        commit('setColorMetallic', payload.metallic);
      }
    },
    setOptions({ commit }, payload) {
      const value = Number(payload.value);
      const { set } = payload;
      if (set) {
        commit('addValue', {
          value,
          name: 'options.id[i]',
        });
      } else {
        commit('delValue', {
          value,
          name: 'options.id[i]',
        });
      }
    },
    setExchange({ commit, dispatch }, value) {
      if (Number(value) === 1) {
        commit('setExchangesPayment', 0);
        dispatch('setExchangesCategory', 0);
      }
      commit('setExchange', value);
    },
    setExchangesCategory({ commit, dispatch }, value) {
      commit('setExchangesCategory', value);
      dispatch('setExchangesBrand', 0);
      commit('setExchangesBody', 0);
    },
    setExchangesBrand({ commit }, value) {
      commit('setExchangesBrand', value);
      commit('setExchangesModel', 0);
    },
    createHash(_, searchString) {
      return _laxios
        .createSubscribeHash
        .request({ data: `value=${searchString}` })
        .then((response) => response)
        .catch((error) => {
          console.error('search/state2 createHash error', error.message);
          return {
            error,
          };
        });
    },
    checkSubscribeBot() {
      return _laxios
        .subscribeBotCheck
        .request();
    },
    createEmailSub(_, payload) {
      return _laxios
        .createSubscribeEmail
        .request({
          data: {
            query: payload.searchQuery,
            email: payload.mailing_email,
            platform: 1,
          },
        })
        .catch((error) => {
          console.error('search/state2 createEmailSub error', error.message);
          return {
            error,
          };
        });
    },
    editSub(_, payload) {
      return _laxios
        .editSubscribe
        .request({ data: payload })
        .then((response) => response)
        .catch((error) => {
          console.error('search/state2 editSub error', error.message);
          return {
            error,
          };
        });
    },
  },
  mutations: {
    ABSearchForm: (state, value) => Vue.set(state, 'ABSearchForm', value),
    setMany: (state, payload) => {
      for (const param in state) {
        if (state.hasOwnProperty(param) && !payload.hasOwnProperty(param)) {
          Vue.delete(state, param);
        }
      }
      for (const param in payload) {
        if (payload.hasOwnProperty(param) && searchParam.hasOwnProperty(param)) {
          if (searchParam[param].type === 'number') {
            Vue.set(state, param, Number(payload[param]));
          } else if (searchParam[param].type === 'string') {
            Vue.set(state, param, String(payload[param]));
          } else {
            Vue.set(state, param, payload[param]);
          }
        }
      }
    },
    setValue: (state, payload) => {
      if (!payload.hasOwnProperty('value')) {
        Vue.delete(state, payload.name);
      } else {
        Vue.set(state, payload.name, payload.value);
      }
    },
    addValue: (state, payload) => {
      Vue.set(state, payload.name, state[payload.name] || []);
      if (state[payload.name].indexOf(Number(payload.value)) === -1) {
        state[payload.name].push(Number(payload.value));
      }
    },
    delValue: (state, payload) => {
      if (state[payload.name] && Array.isArray(state[payload.name])) {
        const index = state[payload.name].indexOf(payload.value);
        if (index >= 0) {
          for (let i = index; i < state[payload.name].length - 1; i++) {
            state[payload.name][i] = state[payload.name][i + 1];
          }
          state[payload.name].pop();
          if (!Object.keys(state[payload.name]).length) {
            Vue.delete(state, payload.name);
          }
        }
      }
    },
    addByIndex(state, payload) {
      if (!state[payload.name]) {
        Vue.set(state, payload.name, []);
      }
      if (state[payload.name].length - 1 < payload.index) {
        state[payload.name].length = payload.index + 1;
      }
      state[payload.name].splice(payload.index, 1, payload.value);
    },
    delByIndex(state, payload) {
      if (state[payload.name] && Array.isArray(state[payload.name])) {
        const indices = payload.indices || [];
        if (payload.hasOwnProperty('index')) {
          indices.push(payload.index);
        }
        for (const index of indices) {
          state[payload.name][index] = undefined;
        }
        console.log('DEBUG: index 277', state[payload.name]);
        const st = state[payload.name].filter(Boolean);
        if (st.length) {
          state[payload.name] = st;
        } else {
          Vue.delete(state, payload.name);
        }
      }
    },
    resetByIndex(state, payload) {
      if (state[payload.name] && Array.isArray(state[payload.name])) {
        state[payload.name].splice(payload.index, 1, undefined);
      }
    },
    setCategory(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'categories.main.id');
      } else {
        Vue.set(state, 'categories.main.id', value);
      }
    },
    setVat(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'vat');
      } else {
        Vue.set(state, 'vat', value);
      }
    },
    setInstallment(state, value) {
      value = Number(value);
      if (value) {
        Vue.set(state, 'installment', value);
      } else {
        Vue.delete(state, 'installment');
      }
    },
    setEquipmentType(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'equipment.type.id');
      } else {
        Vue.set(state, 'equipment.type.id', value);
      }
    },
    setPrice(state, payload) {
      let gte = Number(payload.gte);
      let lte = Number(payload.lte);
      const { currency } = payload;
      if (gte > lte && gte && lte) {
        [gte, lte] = [lte, gte];
      }
      for (const a of ['gte', 'lte']) {
        for (const b of ['USD', 'EUR', 'UAH']) {
          if (state.hasOwnProperty(`price.${b}.${a}`) && currency !== a) {
            Vue.delete(state, `price.${b}.${a}`);
          }
        }
      }

      if (!gte) {
        Vue.delete(state, `price.${currency}.gte`);
      } else {
        Vue.set(state, `price.${currency}.gte`, gte);
      }

      if (!lte) {
        Vue.delete(state, `price.${currency}.lte`);
      } else {
        Vue.set(state, `price.${currency}.lte`, lte);
      }
    },
    setAuction(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'auction');
      } else {
        Vue.set(state, 'auction', value);
      }
    },
    setExchange(state, value) {
      /* eslint-disable */
			value = value ^ state['post.exchanges.type.id'];
			/* eslint-enable */
      if (!value) {
        Vue.delete(state, 'post.exchanges.type.id');
      } else {
        Vue.set(state, 'post.exchanges.type.id', value);
      }
    },
    setExchangesPayment(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'post.exchanges.payment.id');
      } else {
        Vue.set(state, 'post.exchanges.payment.id', value);
      }
    },
    setExchangesCategory(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'post.exchanges.filters.category');
      } else {
        Vue.set(state, 'post.exchanges.filters.category', value);
      }
    },
    setExchangesBody(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'post.exchanges.filters.subCategory');
      } else {
        Vue.set(state, 'post.exchanges.filters.subCategory', value);
      }
    },
    setExchangesBrand(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'post.exchanges.filters.brand');
      } else {
        Vue.set(state, 'post.exchanges.filters.brand', value);
      }
    },
    setExchangesModel(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'post.exchanges.filters.model');
      } else {
        Vue.set(state, 'post.exchanges.filters.model', value);
      }
    },
    setAbroad(state, value) {
      value = Number(value);
      if (Number.isFinite(value)) {
        Vue.set(state, 'abroad.not', value);
      } else {
        Vue.delete(state, 'abroad.not');
      }
    },
    setCountryImportUSA(state, value) {
      value = Number(value);
      if (value === -1) {
        Vue.delete(state, 'country.import.usa.not');
      } else {
        Vue.set(state, 'country.import.usa.not', value);
      }
    },
    setCustom(state, value) {
      value = Number(value);
      if (Number.isFinite(value)) {
        Vue.set(state, 'custom.not', value);
      } else {
        Vue.delete(state, 'custom.not');
      }
    },
    setCredit(state, value) {
      value = Number(value);
      if (value === -1) {
        Vue.delete(state, 'credit');
      } else {
        Vue.set(state, 'credit', value);
      }
    },
    setConfiscated(state, value) {
      value = Number(value);
      if (value === -1) {
        Vue.delete(state, 'confiscated');
      } else {
        Vue.set(state, 'confiscated', value);
      }
    },
    setDamage(state, value) {
      value = Number(value);
      if (value === -1) {
        Vue.delete(state, 'damage.not');
      } else {
        Vue.set(state, 'damage.not', value);
      }
    },
    setTechnical(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'technicalCondition');
      } else {
        Vue.set(state, 'technicalCondition', value);
      }
    },
    setPaint(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'paintCondition');
      } else {
        Vue.set(state, 'paintCondition', value);
      }
    },
    setSpareParts(state, value) {
      value = Number(value);
      if (value === -1) {
        Vue.delete(state, 'spareParts');
      } else {
        Vue.set(state, 'spareParts', value);
      }
    },
    setSellerType(state, value) {
      value = Number(value);
      if (value === 0) {
        Vue.delete(state, 'sellerType');
      } else {
        Vue.set(state, 'sellerType', value);
      }
    },
    setCountryImport(state, value) {
      value = Number(value);
      if (value === 0) {
        Vue.delete(state, 'country.import.id');
      } else {
        Vue.set(state, 'country.import.id', value);
      }
    },
    setFuelConsumption(state, payload) {
      let gte = Number(payload.gte);
      let lte = Number(payload.lte);
      const { type } = payload;
      if (gte > lte && gte && lte) {
        [gte, lte] = [lte, gte];
      }
      for (const a of ['gte', 'lte']) {
        for (const b of ['city', 'route', 'combine']) {
          if (state.hasOwnProperty(`fuel.consumption.${b}.${a}`) && type !== a) {
            Vue.delete(state, `fuel.consumption.${b}.${a}`);
          }
        }
      }

      if (!gte) {
        Vue.delete(state, `fuel.consumption.${type}.gte`);
      } else {
        Vue.set(state, `fuel.consumption.${type}.gte`, gte);
      }

      if (!lte) {
        Vue.delete(state, `fuel.consumption.${type}.lte`);
      } else {
        Vue.set(state, `fuel.consumption.${type}.lte`, lte);
      }
    },
    setEngine(state, payload) {
      let gte = Number(payload.gte);
      let lte = Number(payload.lte);
      if (gte > lte && gte && lte) {
        [gte, lte] = [lte, gte];
      }
      if (!gte) {
        Vue.delete(state, 'engine.gte');
      } else {
        Vue.set(state, 'engine.gte', gte);
      }

      if (!lte) {
        Vue.delete(state, 'engine.lte');
      } else {
        Vue.set(state, 'engine.lte', lte);
      }
    },
    setPower(state, payload) {
      let gte = Number(payload.gte);
      let lte = Number(payload.lte);
      const { type } = payload;
      if (gte > lte && gte && lte) {
        [gte, lte] = [lte, gte];
      }
      for (const a of ['gte', 'lte']) {
        for (const b of ['hp', 'kW']) {
          if (state.hasOwnProperty(`power.${b}.${a}`) && type !== a) {
            Vue.delete(state, `power.${b}.${a}`);
          }
        }
      }

      if (!gte) {
        Vue.delete(state, `power.${type}.gte`);
      } else {
        Vue.set(state, `power.${type}.gte`, gte);
      }

      if (!lte) {
        Vue.delete(state, `power.${type}.lte`);
      } else {
        Vue.set(state, `power.${type}.lte`, lte);
      }
    },
    setMileage(state, payload) {
      let gte = Number(payload.gte);
      let lte = Number(payload.lte);
      if (gte > lte && gte && lte) {
        [gte, lte] = [lte, gte];
      }

      if (!gte) {
        Vue.delete(state, 'mileage.gte');
      } else {
        Vue.set(state, 'mileage.gte', gte);
      }

      const canSaveZero = payload.canSaveZero || false;
      const deleteLteCondition = !lte && !canSaveZero;

      if (deleteLteCondition) {
        Vue.delete(state, 'mileage.lte');
      } else {
        Vue.set(state, 'mileage.lte', lte);
      }
    },
    setCarrying(state, payload) {
      let gte = Number(payload.gte);
      let lte = Number(payload.lte);
      if (gte > lte && gte && lte) {
        [gte, lte] = [lte, gte];
      }

      if (!gte) {
        Vue.delete(state, 'carrying.gte');
      } else {
        Vue.set(state, 'carrying.gte', gte);
      }

      if (!lte) {
        Vue.delete(state, 'carrying.lte');
      } else {
        Vue.set(state, 'carrying.lte', lte);
      }
    },
    setAxle(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'axle');
      } else {
        Vue.set(state, 'axle', value);
      }
    },
    setWheel(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'wheel.id');
      } else {
        Vue.set(state, 'wheel.id', value);
      }
    },
    setDoors(state, payload) {
      let gte = Number(payload.gte);
      let lte = Number(payload.lte);
      if (gte > lte && gte && lte) {
        [gte, lte] = [lte, gte];
      }
      if (!gte) {
        Vue.delete(state, 'doors.gte');
      } else {
        Vue.set(state, 'doors.gte', gte);
      }

      if (!lte) {
        Vue.delete(state, 'doors.lte');
      } else {
        Vue.set(state, 'doors.lte', lte);
      }
    },
    setSeats(state, payload) {
      let gte = Number(payload.gte);
      let lte = Number(payload.lte);
      if (gte > lte && gte && lte) {
        [gte, lte] = [lte, gte];
      }
      if (!gte) {
        Vue.delete(state, 'seats.gte');
      } else {
        Vue.set(state, 'seats.gte', gte);
      }

      if (!lte) {
        Vue.delete(state, 'seats.lte');
      } else {
        Vue.set(state, 'seats.lte', lte);
      }
    },
    setColorMetallic(state, value) {
      value = Number(value);
      if (!value) {
        Vue.delete(state, 'color.metallic');
      } else {
        Vue.set(state, 'color.metallic', value);
      }
    },
    setSort(state, value) {
      value = String(value);
      if (value === '') {
        Vue.delete(state, 'sort[0].order');
      } else {
        Vue.set(state, 'sort[0].order', value);
      }
    },
    setSold(state, value) {
      switch (value) {
        case 'not':
          Vue.set(state, 'dates.sold.not', '0000-00-00 00:00:00');
          Vue.delete(state, 'dates.sold');
          break;
        case 'only':
          Vue.set(state, 'dates.sold', '0000-00-00 00:00:00');
          Vue.delete(state, 'dates.sold.not');
          break;
        default:
          Vue.delete(state, 'dates.sold.not');
          Vue.delete(state, 'dates.sold');
      }
    },
    setTop(state, value) {
      value = Number(value);
      if (value === 0) {
        Vue.delete(state, 'top');
      } else {
        Vue.set(state, 'top', value);
      }
    },
    setSize(state, payload) {
      const value = Number(payload.value);
      const defaultSize = Number(payload.defaultSize);
      if (value === (defaultSize || 20)) {
        Vue.delete(state, 'size');
      } else {
        Vue.set(state, 'size', value);
      }
    },
    setPhoto(state, value) {
      value = Number(value);
      if (value === 0) {
        Vue.delete(state, 'photos.all.id.gte');
      } else {
        Vue.set(state, 'photos.all.id.gte', value);
      }
    },
    setPlateNumber(state, value) {
      value = Number(value);
      if (value === 0) {
        Vue.delete(state, 'plateNumber.length.gte');
      } else {
        Vue.set(state, 'plateNumber.length.gte', value);
      }
    },
    setFirstTime(state, value) {
      value = Number(value);
      if (value === 0) {
        Vue.delete(state, 'firstTime');
      } else {
        Vue.set(state, 'firstTime', value);
      }
    },
    setVerifiedVIN(state, value) {
      value = Number(value);
      if (value === 0) {
        Vue.delete(state, 'verified.VIN');
      } else {
        Vue.set(state, 'verified.VIN', value);
      }
    },
    setIndices(state, value) {
      Vue.set(state, 'indexName', value);
    },
    setVideo(state, value) {
      value = Number(value);
      if (value === 0) {
        Vue.delete(state, 'video.key.gte');
      } else {
        Vue.set(state, 'video.key.gte', value);
      }
    },
    setFilterByChecking(state, payload) {
      if (payload === 0) {
        FILTER_BY_CHECKING_OPTIONS_VALUES.map((el) => {
          if (state.hasOwnProperty(el)) Vue.delete(state, el);
        });
      } else {
        Vue.set(state, payload, 1);
        FILTER_BY_CHECKING_OPTIONS_VALUES.filter((el) => el !== payload).map((el) => {
          if (state.hasOwnProperty(el)) Vue.delete(state, el);
        });
      }
    },
  },
  getters: {
    ABSearchForm: ({ ABSearchForm }) => ABSearchForm,
    getVat(state) {
      return (state.vat === undefined) ? 0 : state.vat;
    },
    getInstallment(state) {
      return (state.installment === undefined) ? 0 : state.installment;
    },
    getValue(state) {
      return function (payload) {
        // @todo випиляти
        if (typeof payload === 'string') {
          return state[payload];
        }
        return (state[payload.name] === undefined) ? searchParam[payload.name].delete : state[payload.name];
      };
    },
    getByIndex(state) {
      return function (payload) {
        if (state[payload.name]) {
          return state[payload.name][payload.index];
        }
      };
    },
    getSearchQuery(state) {
      const result = {};
      for (const param in state) {
        if (state.hasOwnProperty(param) && searchParam.hasOwnProperty(param)) {
          if (searchParam[param].type === 'array') {
            // @todo костиль!!!
            let index_ = 0;
            if (param === 'series') {
              for (let i = 0; i < state[param].length; i++) {
                if (state[param][i]) {
                  const not = state[param][i].not ? '.not' : '';
                  if (state[param][i].brand) {
                    result[`brand.id[${index_}]${not}`] = state[param][i].brand;
                    if (state[param][i].model) {
                      result[`model.id[${index_}]${not}`] = state[param][i].model;
                      // Generation
                      if (state[param][i].hasOwnProperty('generation') && state[param][i].generation.length) {
                        state[param][i].generation.forEach((el, index) => {
                          const generationId = state[param][i].generation[index];

                          result[`generation.id[${index_}][${index}]${not}`] = generationId;
                          // Modification
                          if (state[param][i].hasOwnProperty('modification') && state[param][i].modification.length) {
                            const modificationForGeneration = state[param][i].modification.filter((el) => el.generationId === generationId);
                            if (modificationForGeneration.length) {
                              modificationForGeneration.forEach((el, modificationIndex) => {
                                result[`modification.id[${index_}][${index}][${modificationIndex}]${not}`] = el.value;
                              });
                            }
                          }
                        });
                      }
                    }
                  }
                  if (state[param][i].year) {
                    if (state[param][i].year.gte) {
                      result[`year[${index_}].gte${not}`] = state[param][i].year.gte;
                    }
                    if (state[param][i].year.lte) {
                      result[`year[${index_}].lte${not}`] = state[param][i].year.lte;
                    }
                  }
                  if (state[param][i].year || state[param][i].brand) {
                    index_++;
                  }
                }
              }
            } else if (param === 'locations') {
              for (let i = 0; i < state[param].length; i++) {
                if (state[param][i]) {
                  if (state[param][i].region) {
                    result[`region.id[${i}]`] = state[param][i].region;
                    if (state[param][i].city) {
                      result[`city.id[${i}]`] = state[param][i].city;
                      if (state[param][i].distance_from_city_km) {
                        result[`distance_from_city_km[${i}]`] = state[param][i].distance_from_city_km;
                      }
                    }
                  }
                }
              }
            } else if (param === 'country.origin') {
              for (let i = 0; i < state[param].length; i++) {
                if (state[param][i]) {
                  if (state[param][i].id) {
                    const not = state[param][i].not ? '.not' : '';
                    result[`country.origin.id[${i}]${not}`] = state[param][i].id;
                  }
                }
              }
            } else {
              for (let i = 0; i < state[param].length; i++) {
                if (state[param][i]) {
                  result[param.replace('[i]', `[${i}]`)] = state[param][i];
                }
              }
            }
          } else if (state[param] !== searchParam[param].delete) {
            result[param] = state[param];
          }
        }
      }
      return result;
    },
    getState(state) {
      const result = {};
      for (const param in state) {
        if (state.hasOwnProperty(param)) {
          result[param] = state[param];
        }
      }
      return result;
    },
    getCategory(state) {
      return (state['categories.main.id'] === undefined) ? 0 : state['categories.main.id'];
    },
    isCreateNewSubscribe(state) {
      return Boolean(state?.newSubscribe);
    },
    isEditSubscribe(state) {
      return Boolean(state?.editSubscribe);
    },
    editSubscribe(state) {
      return state?.editSubscribe;
    },
    channelId(state) {
      return state?.channelId;
    },
    getEquipmentType(state) {
      return state['equipment.type.id'] || -1;
    },
    getBody(state) {
      return (state['body.id[i]'] === undefined) ? [] : state['body.id[i]'];
    },
    getCountryOrigin(state) {
      return (state['country.origin.id[i]'] === undefined) ? [] : state['country.origin.id[i]'];
    },
    getCountryOrigin0(state) {
      return (state['country.origin'] === undefined) ? [] : state['country.origin'];
    },
    getPrice(state) {
      let currency = 'USD';
      if (state.hasOwnProperty('price.EUR.gte') || state.hasOwnProperty('price.EUR.lte')) {
        currency = 'EUR';
      } else if (state.hasOwnProperty('price.UAH.gte') || state.hasOwnProperty('price.UAH.lte')) {
        currency = 'UAH';
      }
      return {
        currency,
        gte: state[`price.${currency}.gte`],
        lte: state[`price.${currency}.lte`],
      };
    },
    getAuction(state) {
      return (state.auction === undefined) ? 0 : state.auction;
    },
    getExchangeAuto(state) {
      return [1, 3].indexOf(state['post.exchanges.type.id']) >= 0;
    },
    getExchangeRealEstate(state) {
      return [2, 3].indexOf(state['post.exchanges.type.id']) >= 0;
    },
    getExchangesPayment(state) {
      return (state['post.exchanges.payment.id'] === undefined) ? 0 : state['post.exchanges.payment.id'];
    },
    getExchangesCategory(state) {
      return (state['post.exchanges.filters.category'] === undefined) ? 0 : state['post.exchanges.filters.category'];
    },
    getExchangesBody(state) {
      return (state['post.exchanges.filters.subCategory'] === undefined) ? 0 : state['post.exchanges.filters.subCategory'];
    },
    getExchangesBrand(state) {
      return (state['post.exchanges.filters.brand'] === undefined) ? 0 : state['post.exchanges.filters.brand'];
    },
    getExchangesModel(state) {
      return (state['post.exchanges.filters.model'] === undefined) ? 0 : state['post.exchanges.filters.model'];
    },
    getAbroad(state) {
      return (state['abroad.not'] === undefined) ? 0 : state['abroad.not'];
    },
    getCountryImportUSA(state) {
      return (state['country.import.usa.not'] === undefined) ? -1 : state['country.import.usa.not'];
    },
    getCustom(state) {
      return (state['custom.not'] === undefined) ? 1 : state['custom.not'];
    },
    getCredit(state) {
      return (state.credit === undefined) ? -1 : state.credit;
    },
    getConfiscated(state) {
      return (state.confiscated === undefined) ? -1 : state.confiscated;
    },
    getDamage(state) {
      return (state['damage.not'] === undefined) ? -1 : state['damage.not'];
    },
    getTechnical(state) {
      return (state.technicalCondition === undefined) ? 0 : state.technicalCondition;
    },
    getPaint(state) {
      return (state.paintCondition === undefined) ? 0 : state.paintCondition;
    },
    getSpareParts(state) {
      return (state.spareParts === undefined) ? -1 : state.spareParts;
    },
    getSellerType(state) {
      return (state.sellerType === undefined) ? 0 : state.sellerType;
    },
    getCountryImport(state) {
      return (state['country.import.id'] === undefined) ? 0 : state['country.import.id'];
    },
    getFuel(state) {
      return (state['fuel.id[i]'] === undefined) ? [] : state['fuel.id[i]'];
    },
    getGearbox(state) {
      return (state['gearbox.id[i]'] === undefined) ? [] : state['gearbox.id[i]'];
    },
    getDrive(state) {
      return (state['drive.id[i]'] === undefined) ? [] : state['drive.id[i]'];
    },
    getEcoStandart(state) {
      return (state['eco_standart[i]'] === undefined) ? [] : state['eco_standart[i]'];
    },
    getColorType(state) {
      return (state['color.type[i]'] === undefined) ? [] : state['color.type[i]'];
    },
    getFuelConsumptionType(state) {
      return (type) => {
        if (state.hasOwnProperty(`fuel.consumption.${type}.gte`) || state.hasOwnProperty(`fuel.consumption.${type}.lte`)) {
          return {
            gte: state[`fuel.consumption.${type}.gte`],
            lte: state[`fuel.consumption.${type}.lte`],
          };
        }
        return {};
      };
    },
    getFuelConsumption(state) {
      let type = 'city';
      if (state.hasOwnProperty('fuel.consumption.route.gte') || state.hasOwnProperty('fuel.consumption.route.lte')) {
        type = 'route';
      } else if (state.hasOwnProperty('fuel.consumption.combine.gte') || state.hasOwnProperty('fuel.consumption.combine.lte')) {
        type = 'combine';
      }
      return {
        type,
        gte: state[`fuel.consumption.${type}.gte`],
        lte: state[`fuel.consumption.${type}.lte`],
      };
    },
    getEngine(state) {
      return {
        gte: state['engine.gte'],
        lte: state['engine.lte'],
      };
    },
    getPower(state) {
      let type = 'hp';
      if (state.hasOwnProperty('power.kW.gte') || state.hasOwnProperty('power.kW.lte')) {
        type = 'kW';
      }
      return {
        type,
        gte: state[`power.${type}.gte`],
        lte: state[`power.${type}.lte`],
      };
    },
    getMileage(state) {
      return {
        gte: state['mileage.gte'],
        lte: state['mileage.lte'],
        noMileage: state.noMileage,
      };
    },
    getCarrying(state) {
      return {
        gte: state['carrying.gte'],
        lte: state['carrying.lte'],
      };
    },
    getAxle(state) {
      return (state.axle === undefined) ? 0 : state.axle;
    },
    getWheel(state) {
      return (state['wheel.id'] === undefined) ? 0 : state['wheel.id'];
    },
    getDoors(state) {
      return {
        gte: state['doors.gte'],
        lte: state['doors.lte'],
      };
    },
    getSeats(state) {
      return {
        gte: state['seats.gte'],
        lte: state['seats.lte'],
      };
    },
    getColor(state) {
      return {
        color: (state['color.id[i]'] === undefined) ? [] : state['color.id[i]'],
        metallic: (state['color.metallic'] === undefined) ? 0 : state['color.metallic'],
      };
    },
    getOptions(state) {
      return (state['options.id[i]'] === undefined) ? [] : state['options.id[i]'];
    },
    getSort(state) {
      return (state['sort[0].order'] === undefined) ? '' : state['sort[0].order'];
    },
    getSold(state) {
      const soldNot = state['dates.sold.not'];
      const sold = state['dates.sold'];
      if (!sold && !soldNot) {
        return 'all';
      } if (sold) {
        return 'only';
      }
      return 'not';
    },
    getTop(state) {
      return (state.top === undefined) ? 0 : state.top;
    },
    getSize(state) {
      return function (payload) {
        const defaultSize = Number(payload.defaultSize);
        return (state.size === undefined) ? (defaultSize || 20) : state.size;
      };
    },
    getPhoto(state) {
      return (state['photos.all.id.gte'] === undefined) ? 0 : state['photos.all.id.gte'];
    },
    getVideo(state) {
      return (state['video.key.gte'] === undefined) ? 0 : state['video.key.gte'];
    },
    getVerifiedVIN(state) {
      return (state['verified.VIN'] === undefined) ? 0 : state['verified.VIN'];
    },
    getIndices(state) {
      if (!state.indexName) {
        Vue.set(state, 'indexName', 'auto,order_auto,newauto_search');
      }
      return state.indexName;
    },
    getPlateNumber(state) {
      return (state['plateNumber.length.gte'] === undefined) ? 0 : state['plateNumber.length.gte'];
    },
    getFirstTime(state) {
      return (state.firstTime === undefined) ? 0 : state.firstTime;
    },
    getFilterByChecking(state) {
      const result = FILTER_BY_CHECKING_OPTIONS_VALUES.find((el) => state.hasOwnProperty(el));
      return result || 0;
    },
  },
};
