import {
    ADD_GENERATION,
    DELETE_GENERATION,
    RESET_GENERATIONS
} from './mutation-types';
const { merge }  = require('lodash');


export default {
    [ADD_GENERATION]: (state, payload) => {
        state.generations = {...merge(state.generations, payload)};
    },
    [DELETE_GENERATION]: (state, {blockKey}) => {
        const newGenerations = Object.assign({}, state.generations);

        Object.keys(newGenerations).forEach(el => {
            if(el.includes(blockKey)) delete newGenerations[el];
        })
        state.generations = Object.assign({}, newGenerations)
    },
    [RESET_GENERATIONS]: (state) => {
        state.generations = {};
    }
}
