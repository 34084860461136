import _laxios from '../_laxios';
import Vue from "vue";

export default {
	namespaced: true,
	state: {
		donationsData: {},
		donationsLoggerData: {},
	},
	actions: {
		fetchData({commit, rootGetters}) {
			return _laxios.getDonationZSU
				.request()
				.then(payload => {
					commit('donationsData', {donationsData: payload});
					return payload;
				})
				.catch(() => rootGetters['bff/donations/public/allData']);
		},
	},
	mutations: {
		donationsData(state, {donationsData}) {
			Vue.set(state, 'donationsData', donationsData);
		},
		donationsLoggerData(state, donationsLoggerData) {
			Vue.set(state, 'donationsLoggerData', donationsLoggerData);
		},
	},
	getters: {
		donationsData({donationsData}) {
			return donationsData;
		},
		donationsLoggerData({donationsLoggerData}) {
			return donationsLoggerData;
		},
	},
};
