import {mapGetters} from 'vuex';

module.exports = {
	computed: {
		...mapGetters({
			langId: 'lang/id',
			langCode: 'lang/code',
			langPrefix: 'lang/prefix',
			langPrefixNewAuto: 'lang/prefixNewAuto',
		})
	}
};
