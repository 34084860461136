const packageJson = require('../package.json');

module.exports = {
  port: process.env.NODE_APP_INSTANCE || 1953,
  mode: process.env.NODE_ENV,
  webpack: {
    public: '/vue/scripts/', // Обязательно / в конце (для файлов типа chunk)
    template: 'vue/template.html',
    __webpack_hmr: '__webpack_hmr',
    heartbeat: 3000,
    bundles: {
      cache: 'build/.cache',
      web: 'build/web',
      node: 'build/node',
    },
  },
  project: {
    path: `${__dirname}/..`,
  },
  app: {
    name: packageJson.name,
    version: packageJson.version,
    description: packageJson.description,
    env: process.env.NODE_ENV,
  },
  data: {
    path: `${__dirname}/../data/normalized.json`,
  },
  rest: {
    autoTest: {
      host: `${process.env.AUTOTEST_SCHEME}://${process.env.AUTOTEST_HOSTNAME}`,
    },
    webClients: {
      url: `http://${process.env.WEBCLIENTS_REST_HOSTNAME}`,
    },
  },
  cookieOptions: {
    signed: false,
    httpOnly: false,
    domain: '.ria.com',
    maxAge: 60 * 60 * 24 * 365,
    secure: true,
    sameSite: 'none',
    partitioned: true,
  },
  GOOGLE_CLIENT_ID: '324252216515-df0l464t59j69cnkvspi1fmh6t30nd6s.apps.googleusercontent.com',
};
