import {
    CHANGE_CURRENT_SEARCH_TAB,
    CHANGE_PERIOD_FIELD_VALUE,
    SET_PERIOD_FIELD_OPTIONS,
    SET_SEARCH_FIELD_OPTIONS,
    UPDATE_AVERAGE_PRICE_FIELD_VALIDATOR,
    UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_MESSAGE,
    UPDATE_AVERAGE_PRICE_FIELD_VALUE,
    UPDATE_AVERAGE_PRICE_LOGGER_DATA,
    UPDATE_FIELD_HIDING,
    UPDATE_FIELD_SEARCH_OPTIONS,
    UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_STATUS,
} from "./mutations-types";
import field from "../../../../../../../components/AveragePrice/Form/components/FormFields/mixins/field";

const fillByDefaultValueEmptyValues = (value, defaultValue) => Object.entries(value).reduce(
    (acc, [key, data]) => {
        if(value[key]) {
            acc[key] = value[key]
        } else {
            acc[key] = defaultValue[key]
        }

        return acc
    },
    {}
);

const getParentTab = ({ tabs, name }) => {
    return Object.keys(tabs).find((key) => {
        return tabs[key].fields[name]
    })
}


export default {
    [CHANGE_CURRENT_SEARCH_TAB]: (state, payload) => state.currentSearchTab = payload,
    [CHANGE_PERIOD_FIELD_VALUE]: (state, payload) => state.period.value = payload,
    [SET_PERIOD_FIELD_OPTIONS]: (state, payload) => state.period.options = payload,
    [SET_SEARCH_FIELD_OPTIONS]: (state, { field, options }) => {
        const parentTab = getParentTab({tabs: state.searchTabs, name: field})

        const currentSearchTabFields = state.searchTabs[parentTab].fields
        if(Array.isArray(currentSearchTabFields[field].options)) currentSearchTabFields[field].options = options;
        if( typeof currentSearchTabFields[field].options === 'object') {
            if(currentSearchTabFields[field].options?.gte) currentSearchTabFields[field].options.gte = options; //виключення для років
            if(currentSearchTabFields[field].options?.lte) currentSearchTabFields[field].options.lte = options; //виключення для років
        }
    },
    [UPDATE_AVERAGE_PRICE_FIELD_VALUE]: (state,{ name, value }) => {
        const parentTab = getParentTab({tabs: state.searchTabs, name})
        const fields = state.searchTabs[parentTab].fields;
        const fieldData = state.searchTabs[parentTab].fields[name];
        if(value === null) {
            fields[name] = Object.assign({}, fields[name], { value: fieldData.defaultValue })
        }
        if(value && typeof value === 'object') {
            value = fillByDefaultValueEmptyValues(value, fieldData.defaultValue);
            fields[name] = Object.assign({}, fields[name], { value: value })
        }
        else {
            fields[name] = Object.assign({}, fields[name], { value: value || fieldData.defaultValue })
        }
    },
    [UPDATE_AVERAGE_PRICE_FIELD_VALIDATOR]: (state, payload) => {
        const {fieldName, validationData } = payload;
        const parentTab = getParentTab({tabs: state.searchTabs, name: fieldName})
        const fields = state.searchTabs[parentTab].fields;

        fields[fieldName] = Object.assign(
            {},
            fields[fieldName],
            {
                validation: {
                    ...fields[fieldName].validation,
                    validators : {
                        ...fields[fieldName].validation.validators,
                        ...validationData
                    }
                }
            }
        )
    },
    [UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_MESSAGE]: (state, payload) => {
        const {fieldName, messageStatus } = payload;
        const parentTab = getParentTab({tabs: state.searchTabs, name: fieldName})
        const fields = state.searchTabs[parentTab].fields;

        fields[fieldName] = Object.assign(
            {},
            fields[fieldName],
            {
                validation: {
                    ...fields[fieldName].validation,
                    showMessage: messageStatus
                }
            }
        )
    },
    [UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_STATUS]: (state, payload) => {
        const {fieldName, status } = payload;
        const parentTab = getParentTab({tabs: state.searchTabs, name: fieldName})
        const fields = state.searchTabs[parentTab].fields;

        fields[fieldName] = Object.assign(
            {},
            fields[fieldName],
            {
                validation: {
                    ...fields[fieldName].validation,
                    isValid: status
                }
            }
        )
    },
    [UPDATE_AVERAGE_PRICE_LOGGER_DATA]: (state, payload) => state.loggerData = payload,
    [UPDATE_FIELD_SEARCH_OPTIONS]: (state, {name, value}) => {
        const parentTab = getParentTab({tabs: state.searchTabs, name})
        const fields = state.searchTabs[parentTab].fields;

        fields[name] = Object.assign(
            {},
            fields[name],
            {
                search: {
                    options: value
                }
            }
        )
    },
    [UPDATE_FIELD_HIDING]: (state, { name, hideValue }) => {
        const parentTab = getParentTab({tabs: state.searchTabs, name})
        const fields = state.searchTabs[parentTab].fields;

        if(fields[name] && fields[name].hasOwnProperty('fieldIsHide')) {
            fields[name] = Object.assign(
                {},
                fields[name],
                { fieldIsHide: hideValue }
            )
        }
    }
}



