import {mapMutations} from 'vuex';

export default {
	methods: {
		...mapMutations({
			width: 'shared/device/width',
			height: 'shared/device/height'
		})
	},
	mounted() {
		window.addEventListener('resize', () => {
			this.width(window.innerWidth);
			this.height(window.innerHeight);
		});
		setTimeout(() => {
			this.width(window.innerWidth);
			this.height(window.innerHeight);
		}, 0);
	}
};
