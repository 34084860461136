import Vue from 'vue';
import _laxios from '../_laxios';

export default {
	namespaced: true,
	state: {
		couples: {},
		moreCompares: [],
	},
	actions: {
		fetch({ commit, rootGetters, getters}, link) {
			let {'lang/id': langId} = rootGetters;
			if (!getters.couple(link)) {
				return _laxios.compareCars
					.expandUrl({link})
					.request({ params: { langId } })
					.then((res = {}) => {
						if (res.status >= 300) {
							return {
								status: res.status,
								url: res.url,
							};
						} else {
							const { searchParamNormalize: { modelId = []} = {}, data = [] } = res;
							const [ mainCarId, comparedCarId ] = modelId;
							let mainCar = data.find(({modelId}) => modelId === mainCarId);
							let comparedCar = data.find(({modelId}) => modelId === comparedCarId);
							const cars = { mainCar, comparedCar };
							commit('couple', {payload: cars, link});
							return cars;
						}
					})
					.catch(e => console.error(e));
			}
		},
		getCompareCouples({ commit, rootGetters }, params) {
			let {'lang/id': langId} = rootGetters;
			return _laxios.getCompareCouples
				.request({ params: { langId, ...params } })
				.then((couples = {}) => {
					const { data = [] } = couples;
					commit('moreCompares', data);
					return couples;
				})
				.catch(e => console.error('ERROR:::getCompareCouples', e.message));
		}
	},
	mutations: {
		couple(state, {payload, link}) {
			Vue.set(state.couples, link, payload);
		},
		moreCompares(state, links) {
			Vue.set(state, 'moreCompares', links);
		}
	},
	getters: {
		couple(state) {
			return function (link) {
				return state.couples[link];
			};
		},
		moreCompares(state) {
			return state.moreCompares;
		},
	}
};
