import { mapGetters } from 'vuex';
import i18n from './i18n';
import _laxios from '../../../store/_laxios';

export default {
  name: 'InspectionPackages',
  data: () => ({
    userPackages: [],
    selectedPackages: [],
    viewType: 'all',
  }),
  watch: {
    viewType(newValue) {
      if (newValue === 'all') this.selectedPackages = [...this.userPackages];
      if (newValue === 'active') this.selectedPackages = this.userPackages.filter((o) => o.status === 'active');
      if (newValue === 'archive') this.selectedPackages = this.userPackages.filter((o) => !o.status);
    },
  },
  mounted() {
    setTimeout(() => this.getUserPackages(), 1000);
  },
  ...mapGetters({
    langId: 'lang/id',
  }),
  methods: {
    async getUserPackages() {
      try {
        const res = await _laxios.getUserPackagesData.request({ langId: this.langId });
        if (res?.packages?.length) {
          this.userPackages = res.packages;
          this.selectedPackages = res.packages;
        }
      } catch (e) {
        console.error('getUserPackages', e);
      }
    },
  },
  i18n,
};
