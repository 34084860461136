import _laxios from '../../../../_laxios';

import { CHANGE_LOADING_STATUS, SET_QRCODE_SVG } from '../mutations/mutation-types';

export default {
  async setQRCodeSvg({ commit }, { url = '', withPlateNumber = false, format = 'svg' }) {
    commit(CHANGE_LOADING_STATUS, true);

    const data = {
      headers: {},
      params: {
        url,
        withPlateNumber,
        format,
      },
    };

    try {
      const res = await _laxios.getAdvertisementQRCode.request(data);
      commit(SET_QRCODE_SVG, res);
      commit(CHANGE_LOADING_STATUS, false);
    } catch (e) {
      console.error('/qr_code/get_qr/', e);
    }
  },
};
