import state from './state/index';
import mutations from './mutations/index'
import getters from './getters/index';
import actions from './actions/index';

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
