export default function () {
    return {
        // кожен тип поля амє свої поля для адекватної роботи поля бажано заповнювати всі потрібні поля для адекватної роботи поля
        searchTabs: {
            'byParams': {
                id: 2,
                title: 'Пошук за параметрами авто',
                tabType: 'byParams',
                fields: {
                    'categoryId': {
                        id: 21,
                        component: 'form-select',
                        fieldName: 'categoryId',
                        queryName: 'categoryId', // планується сетап параметрів через url
                        fieldAttrs: {
                            autoComplete: 'off',
                            required: true
                        },
                        value: null,
                        defaultValue: 0,
                        options: [],
                        optionAttrs: {},
                        defaultOptions: [
                            {
                                id: 211,
                                name: 'Тип транспорту',
                                attrs: {
                                    value: '',
                                }
                            }
                        ],
                        dependencies: {
                            on: [],
                            to: ['bodyId', 'brandId', 'modelId', 'gearBoxId', 'driveId']
                        },
                        api: {
                            entity: 'categories',
                            get: {
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: []  //послідовніть повинна бути так як в урлі 'api/categories/:id/marks' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id'
                            },
                        },
                        validation: {
                            showMessage: false,
                            isValid: false,
                            validators: {
                                required: {
                                    valid: false
                                }
                            }
                        }
                    },
                    'stateId': {
                        id: 23,
                        component: 'form-select',
                        fieldName: 'stateId',
                        queryName: 'stateId', // планується сетап параметрів через url
                        fieldAttrs: {
                            autoComplete: 'off'
                        },
                        value: null,
                        defaultValue: 0,
                        options: [],
                        optionAttrs: {},
                        defaultOptions: [
                            {
                                id: 231,
                                name: 'Усі регіони',
                                attrs: {
                                    value: '',
                                }
                            }
                        ],
                        dependencies: {
                            on: [],
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: {}
                        },
                        api: {
                            entity: 'states',
                            get: {
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: []
                                //послідовніть повинна бути так як в урлі 'api/categories/:id/marks' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id/bodystyles''
                            },
                        },
                        ga: []
                    },
                    'brandId': {
                        id: 24,
                        component: 'form-autocomplete',
                        fieldName: 'brandId',
                        queryName: 'brandId', // планується сетап параметрів через url
                        label: 'Марка',
                        fieldAttrs: {
                            autoComplete: 'off',
                            required: true
                        },
                        value: null,
                        defaultValue: 0,
                        options: [],
                        common: {
                            title: 'Усі марки',
                            options: [],
                        },
                        popular: {
                            title: 'ТОП марки',
                            options: [],
                        },
                        search: {
                            emptyText: 'Марку не знайдено',
                            options: [],
                        },
                        dependencies: {
                            on: ['categoryId'],
                            to: ['modelId']
                        },
                        validation: {
                            showMessage: false,
                            isValid: false,
                            validators: {
                                required: {
                                    valid: false
                                }
                            }
                        },
                        api: {
                            entity: 'brands',
                            get: {
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: ['categories']
                                //послідовніть повинна бути так як в урлі 'api/categories/:id/marks' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id/bodystyles''
                            },
                            search: {
                                entity: 'brands',
                                dispatch: 'landing/averagePrice/search/fetchOptionsBySearch',
                                params: {
                                    fields: 'name,value,count', //поля які повинен віддати бек(структура елемента)
                                    q: '', // буде записано значення пошуку
                                },
                                dependencies: {
                                    // додається як query parameter
                                    context: {
                                        // context може бути вказаний по наявності значення у поля і тільки один раз
                                        // тк як у нас є послідовність виботу полів цей параметр буде наявним завжди
                                        // відображається в запиті як context[brand]
                                        categoryId: {
                                            contextName: 'category'
                                        }
                                    }
                                }
                            },
                        },
                    },
                    'modelId': {
                        id: 25,
                        component: 'form-autocomplete',
                        fieldName: 'modelId',
                        queryName: 'modelId', // планується сетап параметрів через url
                        label: 'Модель',
                        fieldAttrs: {
                            autoComplete: 'off',
                            required: true
                        },
                        value: null,
                        defaultValue: 0,
                        options: [],
                        common: {
                            title: 'Усі моделі',
                            options: [],
                        },
                        popular: {
                            title: 'ТОП моделі',
                            options: [],
                        },
                        search: {
                            emptyText: 'Марку не знайдено',
                            options: [],
                        },
                        dependencies: {
                            on: ['categoryId', 'brandId'],
                            to: ['generationId', 'year', 'bodyId']
                        },
                        // відображення полів по умові
                        viewDependencies: {
                            // масив який відображає від яких полей залежеть відображення/приховування поля
                            on: [],
                            // масив який відображає які поля залежні від данного поля
                            to: ['generationId']
                        },
                        validation: {
                            showMessage: false,
                            isValid: false,
                            validators: {
                                required: {
                                    valid: false
                                }
                            }
                        },
                        api: {
                            entity: 'models',
                            get: {
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: ['categories', 'brands'],
                                //послідовніть повинна бути так як в урлі 'api/categories/:id/marks' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id/bodystyles''
                                params: {
                                    sort: 1,
                                }
                            },
                            search: {
                                entity: 'models',
                                dispatch: 'landing/averagePrice/search/fetchOptionsBySearch',
                                params: {
                                    fields: 'name,value,count', //поля які повинен віддати бек(структура елемента)
                                    q: '', // буде записано значення пошуку
                                },
                                dependencies: {
                                    // додається як query parameter
                                    context: {
                                        // context може бути вказаний по наявності значення у поля і тільки один раз
                                        // тк як у нас є послідовність виботу полів цей параметр буде наявним завжди
                                        // відображається в запиті як context[brand]
                                        brandId: {
                                            contextName: 'brand'
                                        }
                                    }
                                }
                            },
                        },
                    },
                    'generationId': {
                        id: 34,
                        component: 'form-average-select',
                        fieldName: 'generationId',
                        queryName: 'generationId', // планується сетап параметрів через url
                        fieldAttrs: {
                            type: 'radio',
                            placeholder: 'Усі покоління'
                        },
                        value: null,
                        defaultValue: 0,
                        defaultOptions: [{
                            img: 'https://cdn.riastatic.com/docs/pictures/common/1/151/15124/15124.svg',
                            name: 'Усі покоління',
                            value: 0,
                            isDefaultOptions: true
                        }],
                        options: [],
                        optionAttrs: {
                            class: 'bold'
                        },
                        hasPhoto: true,
                        dependencies: {
                            on: ['categoryId', 'brandId', 'modelId'],
                            to: ['modificationId']
                        },
                        fieldIsHide: true, // відповідає повинно відобразитись поле чи ні
                        viewDependencies: {
                            // масив який відображає від яких полей залежеть відображення/приховування поля
                            on: ['modelId'],
                            // масив який відображає які поля залежні від данного поля
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: {}
                        },
                        api: {
                            entity: 'generations',
                            get: {
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: ['models'],
                                //послідовніть повинна бути так як в урлі 'api/models/:id/generations' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id/bodystyles''
                                needNormalize: true,
                                params: {
                                    sort: 1,
                                    withImage: 1
                                }
                            },
                        },
                    },
                    'modificationId': {
                        id: 35,
                        component: 'form-average-select',
                        fieldName: 'modificationId',
                        queryName: 'modificationId', // планується сетап параметрів через url
                        fieldAttrs: {
                            type: 'radio',
                            placeholder: 'Усі модифікації'
                        },
                        value: null,
                        defaultValue: {},
                        options: [],
                        defaultOptions: [{
                            name: 'Усі модифікації',
                            value: 0,
                            isDefaultOptions: true
                        }],
                        optionAttrs: {},
                        dependencies: {
                            on: ['categoryId', 'brandId', 'modelId', 'generationId'],
                            to: []
                        },
                        fieldIsHide: true, // відповідає повинно відобразитись поле чи ні
                        viewDependencies: {
                            // масив який відображає від яких полей залежеть відображення/приховування поля
                            on: ['modelId'],
                            // масив який відображає які поля залежні від данного поля
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: {}
                        },
                        api: {
                            entity: 'modifications',
                            get: {
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: ['generations'],
                                //послідовніть повинна бути так як в урлі 'api/categories/:id/marks' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id/bodystyles''
                                params: {
                                    sort: 1
                                }
                            },
                        },
                    },
                    'year': {
                        id: 26,
                        component: 'form-double-select',
                        fieldName: 'year',
                        queryName: 'year', // планується сетап параметрів через url
                        fieldAttrs: {
                            gte: {
                                autoComplete: 'off',
                                name: 'gte',
                            },
                            lte: {
                                autoComplete: 'off',
                                name: 'lte',
                            },
                        },
                        value: {
                            gte: null,
                            lte: null
                        },
                        defaultValue: {
                            gte: 0,
                            lte: 0
                        },
                        options: {
                            gte: [],
                            lte: []
                        },
                        optionAttrs: {},
                        defaultOptions: {
                            gte: [
                                {
                                    id: 261,
                                    name: 'Рік від',
                                    attrs: {
                                        value: '',
                                    }
                                }
                            ],
                            lte: [
                                {
                                    id: 262,
                                    name: 'Piк до',
                                    attrs: {
                                        value: '',
                                    }
                                }
                            ]
                        },
                        dependencies: {
                            on: ['modelId'],
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: {}
                        },
                        api: {
                            entity: 'years',
                            get: {
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: ['models']
                                //послідовніть повинна бути так як в урлі 'api/categories/:id/marks' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id/bodystyles''
                            },
                        },
                    },
                    'bodyId': {
                        id: 22,
                        component: 'form-average-select',
                        fieldName: 'bodyId',
                        queryName: 'bodyId', // планується сетап параметрів через url
                        fieldAttrs: {
                            type: 'radio',
                            autoComplete: 'off',
                            placeholder: 'Усі типи кузова'
                        },
                        value: null,
                        defaultValue: 0,
                        options: [],
                        defaultOptions: [{
                            name: 'Усі типи кузова',
                            value: 0,
                            isDefaultOptions: true
                        }],
                        optionAttrs: {},
                        dependencies: {
                            on: ['modelId'],
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: []
                        },
                        api: {
                            entity: 'bodies',
                            get: {
                                needNormalize: true,
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: ['models']
                                //послідовніть повинна бути так як в урлі 'api/categories/:id/marks' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id/bodies'
                            },
                        },
                    },
                    'mileage': {
                        id: 27,
                        component: 'form-double-input',
                        fieldName: 'mileage',
                        queryName: 'mileage', // планується сетап параметрів через url
                        fieldAttrs: {
                            gte: {
                                autoComplete: 'off',
                                name: 'gte',
                                type: 'number',
                                step: 1,
                                'data-flexion': 'тис. км',
                                placeholder: 'Пробіг від, тис. км'
                            },
                            lte: {
                                autoComplete: 'off',
                                name: 'lte',
                                type: 'number',
                                step: 1,
                                'data-flexion': 'тис. км',
                                placeholder: 'Пробіг до, тис. км'
                            },
                        },
                        value: {
                            gte: null,
                            lte: null
                        },
                        defaultValue: {
                            gte: null,
                            lte: null
                        },
                        dependencies: {
                            on: [],
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: {}
                        },
                    },
                    'fuelId': {
                        id: 28,
                        component: 'form-select',
                        fieldName: 'fuelId',
                        queryName: 'fuelId', // планується сетап параметрів через url
                        fieldAttrs: {
                            autoComplete: 'off'
                        },
                        value: null,
                        defaultValue: 0,
                        options: [],
                        optionAttrs: {},
                        defaultOptions: [
                            {
                                id: 281,
                                name: 'Усі типи палива',
                                attrs: {
                                    value: 0,
                                }
                            }
                        ],
                        dependencies: {
                            on: [],
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: {}
                        },
                        api: {
                            entity: 'fuels',
                            get: {
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: []
                                //послідовніть повинна бути так як в урлі 'api/categories/:id/marks' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id/bodystyles''
                            },
                        },
                    },
                    'engineVolume': {
                        id: 29,
                        component: 'form-double-input',
                        fieldName: 'engineVolume',
                        queryName: 'engineVolume', // планується сетап параметрів через url
                        fieldAttrs: {
                            gte: {
                                autoComplete: 'off',
                                type: 'number',
                                name: 'gte',
                                placeholder: 'Обʼєм двигуна від, л',
                                'data-flexion': 'л',
                                step: 0.1,
                            },
                            lte: {
                                autoComplete: 'off',
                                type: 'number',
                                name: 'lte',
                                placeholder: 'Обʼєм двигуна до, л',
                                'data-flexion': 'л',
                                step: 0.1,
                            },
                        },
                        value: {
                            gte: null,
                            lte: null
                        },
                        defaultValue: {
                            gte: null,
                            lte: null
                        },
                        dependencies: {
                            on: [],
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: {}
                        },
                    },
                    'gearBoxId': {
                        id: 31,
                        component: 'form-select',
                        fieldName: 'gearBoxId',
                        queryName: 'gearBoxId', // планується сетап параметрів через url
                        fieldAttrs: {
                            autoComplete: 'off'
                        },
                        value: null,
                        defaultValue: 0,
                        options: [],
                        optionAttrs: {},
                        defaultOptions: [
                            {
                                id: 221,
                                name: 'Усі типи КПП',
                                attrs: {
                                    value: 0,
                                }
                            }
                        ],
                        dependencies: {
                            on: ['categoryId'],
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: {}
                        },
                        api: {
                            entity: 'gearboxes',
                            get: {
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: ['categories']
                                //послідовніть повинна бути так як в урлі 'api/categories/:id/marks' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id/bodystyles''
                            },
                        },
                    },
                    'driveId': {
                        id: 32,
                        component: 'form-select',
                        fieldName: 'driveId',
                        queryName: 'driveId', // планується сетап параметрів через url
                        fieldAttrs: {
                            autoComplete: 'off'
                        },
                        value: null,
                        defaultValue: 0,
                        options: [],
                        optionAttrs: {},
                        defaultOptions: [
                            {
                                id: 221,
                                name: 'Усі типи привода',
                                attrs: {
                                    value: 0,
                                }
                            }
                        ],
                        dependencies: {
                            on: ['categoryId'],
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: {}
                        },
                        api: {
                            entity: 'driverTypes',
                            get: {
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: ['categories']
                                //послідовніть повинна бути так як в урлі 'api/categories/:id/marks' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id/bodystyles''
                            },
                        },
                    },
                    'colorId': {
                        id: 33,
                        component: 'form-select',
                        fieldName: 'colorId',
                        queryName: 'colorId', // планується сетап параметрів через url
                        fieldAttrs: {
                            autoComplete: 'off'
                        },
                        value: null,
                        defaultValue: 0,
                        options: [],
                        optionAttrs: {},
                        defaultOptions: [
                            {
                                id: 221,
                                name: 'Усі кольори',
                                attrs: {
                                    value: 0,
                                }
                            }
                        ],
                        dependencies: {
                            on: [],
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: {}
                        },
                        api: {
                            entity: 'colors',
                            get: {
                                dispatch: 'landing/averagePrice/search/fetchFieldData',
                                dependencies: []
                                //послідовніть повинна бути так як в урлі 'api/categories/:id/marks' тобто ['categories'] в кінець автоматично додаеться данна сущніть
                                // результат 'categories/:id/bodystyles''
                            },
                        },
                    },
                }
            },
            'byOmniId': {
                id: 1,
                title: 'Пошук по ID, VIN-коду чи держ. номеру',
                tabType: 'byOmniId',
                fields: {
                    'omniId': {
                        id: 1,
                        component: 'form-input',
                        fieldName: 'omniId',
                        queryName: 'omniId', // планується сетап параметрів через url
                        fieldAttrs: {
                            placeholder: 'Введіть данні',
                            autoComplete: 'off',
                            required: true
                        },
                        value: null,
                        defaultValue: null,
                        dependencies: {
                            on: [],
                            to: []
                        },
                        validation: {
                            showMessage: false,
                            isValid: true, // При додаванні валідаторів поставити флаг в false
                            validators: {
                                required: {
                                    valid: false
                                }
                            }
                        }
                    }
                }
            },
        },
        period: {
            id: 21,
            component: 'form-select',
            queryName: 'period',
            fieldAttrs: {
                name: 'period',
            },
            value: null,
            defaultValue: 365,
            options: [],
            defaultOptions: [],
            dependencies: {
                on: [],
                to: []
            },
            validation: {
                showMessage: false,
                isValid: false,
                validators: {}
            }
        },
        loader: false,
        currentSearchTab: 'byOmniId',
        loggerData: {},
        loggerDataDefault: {
            eventData: {
                event_id: 1019,
            },
            mapTabs: {
                byParams: 1,
            },
            mapSearchType: {
                'advertisementId': 2,
                'VIN': 3,
                'plateNumber': 4
            },
            mapSubmitByTabs: {
                byParams: 11,
                byOmniId: 21,
            },
        }
    }
}
