export default {
    mainTypes: {
        'bu':{
            id: 2,
            eventId: 2,
            title: 'Вживані',
            type: 'bu',
            gaEvents: ['MainPageNew', 'bu', 'SearchForm'],
            searchSettings: {
                target: '/search/',
                indexName:'auto',
            }
        },
        'all':{
            id: 1,
            eventId: 1,
            title: 'Всі',
            type: 'all',
            gaEvents: ['MainPageNew', 'all', 'SearchForm'],
            searchSettings: {
                target: '/search/',
            }
        },
        'order': {
            id: 4,
            eventId: 4,
            title: 'Під пригон',
            type: 'order',
            gaEvents: ['MainPageNew', 'order', 'SearchForm'],
            searchSettings: {
                target:'/search/',
                withVin: false,
                searchQuery:'abroad.not=1&custom.not=0'
            }
        },
        'na': {
            id: 3,
            eventId: 3,
            title: 'Нові',
            type: 'na',
            gaEvents: ['MainPageNew', 'na', 'SearchForm'],
            searchSettings: {
                target:'/newauto/search/',
            }
        }
    },
    autoInterTypes: {
        'order': {
            id: 4,
            eventId: 4,
            title: 'Під пригон',
            type: 'order',
            gaEvents: ['MainPageNew', 'order', 'SearchForm'],
            searchSettings: {
                target: '/search-order-auto/',
                withVin: false,
                searchQuery: 'abroad.not=-1&custom.not=-1'
            }
        },
        'order-abroad': {
            id: 6,
            eventId: 6,
            title: 'За кордоном',
            type: 'order-abroad',
            gaEvents: ['MainPageNew', 'order-abroad', 'SearchForm'],
            searchSettings: {
                target: '/autointer/search/',
                withVin: false,
                searchQuery: 'abroad.not=-1&custom.not=-1'
            }
        },
    }
}
