import _laxios from '../../_laxios';
import {UPDATE_FAVORITES_IDS} from "../mutations/mutations-types";

export default {
    news: ({commit, rootGetters, getters}, id) => {
        let {'lang/id': langId} = rootGetters;
        const params = {langId, size: 1, news_id: id };
        return _laxios.newsSearchItem.request({params, responseType: 'json'})
            .then(({data: {0: payload} = {}} = {}) => {
                payload ? commit('news', {id, payload}) : undefined})
            .catch(()=> console.error('Ошибка при получении отдельной новости'))
    },
    fetch: ({commit, rootGetters, getters},
            {_target, size = 5, from = 0, rubric_id, top_prior, catalog_ranges, exclude_rubric_id, project_id} = {}) => {
        let {'lang/id': langId} = rootGetters;
        const params = {langId, from, size, top_prior, rubric_id, catalog_ranges, exclude_rubric_id, project_id};
        const id = JSON.stringify(params);
        _laxios.news_search.stay = () => getters.response(id);

        return _laxios.news_search.request({params, responseType: 'json'})
            .then((body) => {
                if (body) {
                    commit('save', {id, payload: body});
                }
                commit('toggle', {toggle: _target, id});
            })
            .catch(()=> console.error('Ошибка при получении новостей'))
    },
    fetchReviews: ({commit, rootGetters, getters}, {_target, size = 5, from = 0, rubric_id, top_prior, catalog_ranges} = {}) => {
        let {'lang/id': langId} = rootGetters;
        const params = {langId, from, size, top_prior, rubric_id, catalog_ranges};
        const id = JSON.stringify(params);
        _laxios.news_reviews.stay = () => getters.responseReviews(id);
        return _laxios.news_reviews.request({
            params,
            responseType: 'json'
        })
            .then((body) => {
                if (body) {
                    commit('saveReviews', {id, payload: body});
                }
                commit('toggleReviews', {toggle: _target, id});
            })
            .catch(()=> console.error('Ошибка при получении обзоров'))
    },
    fetchLandingMainLink: ({commit, rootGetters, getters}) => {
        let {'lang/id': langId} = rootGetters;
        const params = {langId};
        const id = JSON.stringify(params);
        commit('toggleLandingMainLink', id);
        _laxios.news_getLandingMainLink.stay = () => getters.LandingMainLink;
        return _laxios.news_getLandingMainLink.request({
            params,
            responseType: 'json'
        })
            .then((body) => {
                if (body) {
                    commit('saveLandingMainLink', {id, payload: body});
                }
            })
            .catch((error)=> console.error('Ошибка при получении ссылок каталогов. темы', error.message))
    },
    fetchNewsSlonikInfo: ({commit, rootGetters, getters}, {_target, slonik_ranges = 2, ui} = {}) => {
        let {'lang/id': langId} = rootGetters;
        const params = {langId, slonik_ranges, ui};
        return _laxios.news_slonikInfo.request({params, responseType: 'json'})
            .then((response) => commit('slonikInfo', response))
            .catch(()=> console.error('Ошибка при получении данных для новостей из слоника'))
    },
    autoleaderInfo: ({commit, rootGetters}, {min_count = 5, year, is_static = 1} = {}) => {
        let {'lang/id': langId} = rootGetters;
        year = year || (new Date()).getUTCFullYear() + 1;

        const params = {langId: langId, min_count, year, is_static};
        return _laxios.newsAutoleader.request({params, responseType: 'json'})
            .then((response) => commit('autoleaderInfo', response))
            .catch(()=> console.error('Ошибка при получении данных для автолидера'));
    },
    commonReviewsInfo: ({ commit, rootGetters }, params) => {
        let {'lang/id': langId} = rootGetters;
        return _laxios.commonReviewsInfo
            .request({ params: { langId, ...params } })
            .then(rating => rating)
            .catch(e => console.error(e));
    },
    fetchReviewsList: ({ commit, rootGetters }, params) => {
        let {'lang/id': langId} = rootGetters;
        return _laxios.news_reviews
            .request({ params: { langId, ...params } })
            .then(reviews => reviews)
            .catch(e => console.error(e));
    },
    async fetchFavoriteNewsIds({commit}){
        let data
        try {
            const response = await _laxios
                .getFavoritesNewsIds
                .request({
                    responseType: 'json',
                })
            data = response.favoriteNewsId
        } catch (e) {
            data.errors = e;
        } finally {
            commit(UPDATE_FAVORITES_IDS, { data });
        }
    },
    async addToFavoritesNews({commit, dispatch}, id) {
        try{
            const response = await _laxios
                .addToFavoriteNews
                .request({data: {
                        status: 1,
                        newsId: id
                    }
            })
            if(response.result === 2) {
                dispatch('fetchFavoriteNewsIds')
            }
        } catch (e) {
            throw new Error(e.message)
        }
    },
    async deleteFromFavoritesNews({commit, dispatch}, id) {
        try{
            const response = await _laxios
                .deleteFromFavoritesNews
                .request({ data: {
                        status: 0,
                        newsId: id
                    }
                })

            if(response.result === 2) {
                dispatch('fetchFavoriteNewsIds')
            }

        } catch (e) {
            throw new Error(e.message)
        }
    }
};
