export const NAME = 'test_new_features'; // Имя cookie для тестирования
export const EXPIRES = 2592000; // 60 * 60 * 24 * 30  - год

import {mapMutations, mapGetters} from 'vuex';

const generate = () => Math.floor(Math.random() * 1000);

export default {
	computed: {
		...mapGetters({
			cookieStored: 'others/testNewFeaturesCookie/get',
		}),
	},
	methods: {
		...mapMutations({
			setCookie: 'others/testNewFeaturesCookie/set',
		}),
	},
	render() {
	},
	serverPrefetch() {
		let value = this.cookieStored;
		if (!value) {
			value = generate();
		}
		this.setCookie(value);
	},
	beforeMount() {
		const {get, set} = require('../../helpers/cookie');

		const cookieStored = this.cookieStored;
		let cookie = get(NAME);
		if (!cookie) {
			const value = cookieStored || generate();
			this.setCookie(value);
			set(NAME, value, { expires: EXPIRES });
		}
	}
};
