import Vue from 'vue';
import _laxios from '../../_laxios';

function fetchByCategory_({category, langId, getters, commit}) {
	_laxios.bransForCategory.stay = () => Object.values(getters.getByCategory(category)).length;
	return _laxios.bransForCategory.expandUrl({category}).request({params: {langId}})
		.then((body) => {
			if (body) {
				let brands = body.reduce((result, item = {}) => {
					let {value} = item;
					item.category = category;
					result[value] = item;
					return result;
				}, {});
				commit('save', brands);
			}
		})
		.catch((error) => console.error('Ошибка. марки', _laxios.bransForCategory.query.url, error.message));
}

export default {
	fetchByCategory: ({commit, rootGetters, getters}, category = 0) => {
		let {'lang/id': langId} = rootGetters;
		return fetchByCategory_({category, langId, getters, commit});
	},
	fetchPopularByCategory: ({commit, rootGetters, getters}, id = 0) => {
		let {'lang/id': langId} = rootGetters;
		_laxios.popularBrands.stay = () => Array.isArray(getters.popular(id));
		return _laxios.popularBrands.request({params: {id}})
			.then((response = {}) => {
				if (response) {
					commit('popular', {payload: response.marka, id});
				}
			})
			.catch((error) => console.error('Ошибка. популярные марки:', _laxios.popularBrands.query.url, error.message));
	},
	suggest({commit}, {text = '', category}) {
		const params = {
			q: text,
			fields: 'name,value,count,category'
		};
		if (category) {
			params['context[category]'] = category;
		}
		Vue.http.get(`/api/suggest/brands`, {params})
			.then(({body} = {}) => {
				if (Array.isArray(body)) {
					commit('suggest', body);
					let brands = body.reduce((result, item = {}) => {
						let {value, category: {[0]: categoryResponse} = []} = item;
						item.category = category ? category : categoryResponse;
						result[value] = item;
						return result;
					}, {});
					commit('save', brands);
				}
			});
	}
};
