import langs from '../../constants/langs';
// eslint-disable-next-line import/extensions
import { set } from '../helpers/cookie.js';

const EXPIRES = 315360000; // 10 років

export const getCodeByLangId = (id) => {
	const { code = 'ru' } = langs.find(({ id: itemId }) => itemId === id) || {};
	return code;
};

export const getPrefixByLangId = (id) => {
	const { prefix = '' } = langs.find(({ id: itemId }) => itemId === id) || {};
	return prefix;
};

export const getPrefixByLangIdNewAuto = (id) => {
	const { prefixNewAuto = '' } = langs.find(({ id: itemId }) => itemId === id) || {};
	return prefixNewAuto;
};

export const setLangCookie = (langCode = 'uk') => {
	const { id = '' } = langs.find(({ code: itemCode }) => itemCode === langCode) || {};
	set('lang_id', id, {
      expires: EXPIRES,
      domain: '.ria.com',
      partitioned: true,
    });
};

export default {
	namespaced: true,
	state: {
		id: 2,
		idPrevious: 2,
	},
	mutations: {
		setByCode(state, payload) {
			if (payload) {
				const { id } = langs.find(({ code }) => code === payload) || {};
				if (id) {
					// eslint-disable-next-line no-param-reassign
					state.idPrevious = state.id;
					// eslint-disable-next-line no-param-reassign
					state.id = id;
				}
			}
		},
		set(state, payload) {
			if (payload) {
				// eslint-disable-next-line no-param-reassign
				state.idPrevious = state.id;
				// eslint-disable-next-line no-param-reassign
				state.id = payload;
			}
		},
	},
	getters: {
		id: ({ id }) => id,
		code: ({ id }) => getCodeByLangId(id),
		codePrevious: ({ idPrevious }) => getCodeByLangId(idPrevious),
		prefix: ({ id }) => getPrefixByLangId(id),
		prefixNewAuto: ({ id }) => getPrefixByLangIdNewAuto(id),
	},
};
