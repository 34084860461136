import Vue from 'vue';
import _laxios from '../_laxios';

const advertisement_check_selection = import('./_queries/advertisement_check_selection.graphql');

export default {
	namespaced: true,
	state: {
		advertisements: {}
	},
	actions: {
		advertisements({commit, getters, rootGetters}, ids = []) {
			let {'shared/url/current': url} = rootGetters;
			ids = ids.filter((item) => !getters.advertisements(item));
			return ids.length ?
				advertisement_check_selection
					.then(({default: query}) => query)
					.then((query) => {
						const data = {query, variables: {ids}};
						return _laxios.graphql
							.request({data})
							.then(({data: {advertisements} = {}} = {}) => commit('advertisements', advertisements))
							.catch((e) => {
								console.log('[GRAPH_QL API] method (advertisements)', e.message, {url});
							});
					}) :
				Promise.resolve();
		}
	},
	mutations: {
		advertisements(state, payload) {
			Array.isArray(payload) ?
				payload.map((item) => {
					Vue.set(state.advertisements, item.id, item);
				}) : undefined;
		}
	},
	getters: {
		advertisements(state) {
			return function (id) {
				return state.advertisements[id];
			};
		}
	}
};
