import { mapGetters, mapMutations } from 'vuex';

module.exports = {
  name: 'LoginForm',
  props: ['notLoggedIn', 'whenNeeded', 'notScrollTo'],
  mixins: [require('webpack:projectPath/vue/mixins/withData')],
  mounted() {
    window.addEventListener('message', this.loginFrameHandler, false);
  },
  methods: {
    ...mapMutations({
      set: 'Common/set',
    }),
    loginFrameHandler(event) {
      let data = {};
      if (typeof event.data === 'object') {
        data = event.data;
      } else if (typeof event.data === 'string') {
        try {
          data = JSON.parse(event.data);
        } catch (e) {
        }
      }

      if (data.login == 'ok') {
        window.removeEventListener('message', this.loginFrameHandler);
        this.getUserData()
          .then((userData) => {
            this.set({ userData });
            if (!this.notScrollTo) {
              window.scrollTo(0, 0);
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      userData: 'Common/userData',
    }),
  },
  data() {
    return {};
  },
  i18n: {
    messages: {
      ru: {
        'Авторизуйтесь, чтобы знать на каком этапе проверка авто и получить отчет о ее результатах': 'Авторизуйтесь, чтобы знать на каком этапе проверка авто и получить отчет о ее результатах',
      },
      uk: {
        'Авторизуйтесь, чтобы знать на каком этапе проверка авто и получить отчет о ее результатах': 'Авторизуйтесь, щоб знати на якому етапі перевірка авто й отримати звіт про її підсумки',
      },
    },
  },
};
