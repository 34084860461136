import axios from 'axios';
import URI from 'urijs';

import vinCheck from '../../enums/vinCheck';

let host;
let scheme;
let ssl;

switch (process.env.NODE_ENV) {
  case 'development':
    host = 'autolocal.ria.com';
    scheme = process.env.RIA_SCHEME || 'https';
    ssl = host;
    break;
  case 'dev-cluster':
    host = 'dev-auto.ria.com';
    scheme = 'https';
    break;
  case 'production':
  default:
    host = 'auto.ria.com';
    if (_TARGET_ === 'server') {
      scheme = 'http';
      ssl = '10.1.53.92';
    } else {
      scheme = 'https';
      ssl = host;
    }
    break;
}
export const base = `${scheme}://${host}`;

const AUTOTEST_URL = `${base}/autotest/api`;
const CABINET_URL = `${base}/cabinet/api`;
const ANALYTIC_ENGINE_URL = `${base}/api/analytic-engine/public`;

export function advertisement({
  id,
  langId,
}) {
  return axios(`${base}/demo/bu/searchPage/v3/view/auto/${id}?langId=${langId}`)
    .catch((e) => {
      console.error('advertisement query error =>', e.message);
    });
}

export function personalization({
  id,
  langId,
}) {
  return axios(`${base}/demo/bu/finalPage/v2/personalizationBlock/${id}?langId=${langId}`)
    .catch((e) => {
      console.error('personalization query error =>', e.message);
    });
}

export function autotestVin(autoId, fontSize) {
  const uri = new URI(`${AUTOTEST_URL}/public/adv/vin/${autoId}/?vinSize=${fontSize || 13}`);
  return axios.get(uri.toString())
    .then(({ data }) => data)
    .catch((e) => {
      console.error('autotestVin query error =>', e.message);
    });
}

export function position({
  id,
  brandId,
  modelId,
  custom,
  abroad,
  categoryId,
}) {
  // eslint-disable-next-line max-len
  return axios(`${base}/levels/position/${id}?indexName=auto,order_auto,newauto_search&searchType=1,3&category_id=${categoryId}&marka=${brandId}&model=${modelId}&custom=${Number(custom) ? 2 : 1}&searchType=${abroad ? 3 : 6}&abroad=${abroad ? 1 : 2}`)
    .catch((e) => {
      console.error('position query error =>', e.message);
    });
}

export function aar({ id }) {
  return axios(`${base}/users/aar/preference/${id}`)
    .catch((e) => {
      console.error('aar query error =>', e.message);
    });
}

export function statistic({ id }) {
  return axios(`${base}/api/autos/${id}/statistic/`)
    .catch((e) => {
      console.error('statistic query error =>', e.message);
    });
}

export function deleteReason({ id }) {
  return axios(`${CABINET_URL}/car/${id}/delete_reason?langId=4`)
    .catch((e) => {
      console.error('deleteReason query error =>', e.message);
    });
}

export function search({ query }) {
  return axios(`${base}/demo/bu/searchPage/v2/search?${Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join('&')}`)
    .catch((e) => {
      console.error('search query error =>', e.message);
    });
}

export function getOrder({
  advertisementId,
  langId,
}) {
  return axios(`${base}/api/publications/public/getUnpaidOrder/${advertisementId}`, {
    params: { langId },
  })
    .catch((e) => {
      console.error('getOrder query error =>', e.message);
    });
}

export function advertisementsActive({ query }) {
  return axios(`${CABINET_URL}/cars/active`, {
    params: query,
  })
    .catch((e) => {
      console.error('advertisementsActive query error =>', e.message);
    });
}

export function advertisementsArchive({ query }) {
  return axios(`${CABINET_URL}/cars/archive`, {
    params: query,
  })
    .catch((e) => {
      console.error('advertisementsArchive query error =>', e.message);
    });
}

export function advertisementsDraft({ query }) {
  return axios(`${CABINET_URL}/cars/draft`, {
    params: query,
  })
    .catch((e) => {
      console.error('advertisementsDraft query error =>', e.message);
    });
}

export function advertisementsAwaitForPayment({ query }) {
  return axios(`${CABINET_URL}/cars/not_paid`, {
    params: query,
  })
    .catch((e) => {
      console.error('advertisementsAwaitForPayment query error =>', e.message);
    });
}

export function deleteAdvertisement({ id }) {
  return axios({
    url: `${CABINET_URL}/car/${id}`,
    method: 'delete',
  })
    .catch((e) => {
      console.error('deleteAdvertisement query error =>', e.message);
    });
}

export function moveToArchiveAdvertisement({
  id,
  reason,
  price,
  source,
}) {
  return axios({
    url: `${CABINET_URL}/car/${id}/${reason}/`,
    method: 'delete',
    data: {
      price,
      source,
    },
  })
    .catch((e) => {
      console.error('moveToArchiveAdvertisement query error =>', e.message);
    });
}

export function updatePriceForMovedArchiveAdvertisement({
  id,
  price,
  currency,
}) {
  return axios({
    url: `${CABINET_URL}/car/${id}/sellPrice`,
    method: 'put',
    data: {
      price,
      currency,
    },
  })
    .catch((e) => {
      console.error('updatePriceForMovedArchiveAdvertisement query error =>', e.message);
    });
}

export function isSetByAutoId(autoId) {
  const uri = new URI(`${AUTOTEST_URL}/sto/is_set_by_auto_id`);
  uri.setQuery({ autoId });
  return axios.get(uri.toString())
    .then(({ data }) => data)
    .catch((e) => {
      console.error('isSetByAutoId query error =>', e.message);
    });
}

export function autotestVinMaxBrands() {
  const uri = new URI(`${AUTOTEST_URL}/vinMax/brands`);
  return axios.get(uri.toString())
    .then(({ data }) => data)
    .catch((e) => {
      console.error('vinMaxBrands query error =>', e.message);
    });
}

export function autotestVinCheckPrices({ cookies, autoId = 0 }) {
  const opts = {
    headers: cookies,
    params: { autoId },
  };
  const uri = new URI(`${AUTOTEST_URL}/vin-check/prices`);
  return axios.get(uri.toString(), opts)
    .then(({ data }) => data)
    .catch((err) => {
      console.error('autotestVinCheckPrices query error =>', err.message);
      return vinCheck.prices;
    });
}

export function cebiaReportLink(id) {
  const uri = new URI(`${base}/api/cebia/public/report/auto/${id}`);
  return axios.get(uri.toString())
      .then(({data}) => data)
      .catch((err) => {
        console.error('cebiaReportLink query error =>', err.message);
      });
}

export function usaReportsExist(autoId, cookies) {
  const data = {
    headers: {},
  };
  if (cookies) {
    data.headers = cookies;
  }

  return axios(`${base}/api/reports-usa/public/auto/${autoId}/exist`, data)
    .then((res) => res?.data)
    .catch((e) => {
      console.error('usaReportsExist query error =>', e.message);
    });
}

export function autotestPriceLaw() {
  const uri = new URI(`${AUTOTEST_URL}/verification/law/price`);
  return axios.get(uri.toString())
    .then(({ data }) => data)
    .catch((e) => {
      console.error('autotestPriceLaw query error =>', e.message);
    });
}

export function getCities({
  cityId,
  subcategoryId,
  categoryId,
  brandId,
  modelId,
  year,
  fuelId,
  langId,
} = {}) {
  const uri = new URI(`${AUTOTEST_URL}/cities`);
  uri.setQuery({
    regionId: cityId, // Не спрашивайте меня почему так, все дело в backend'е ¯\(^_^)/¯
    subcategoryId,
    categoryId,
    brandId,
    modelId,
    year,
    langId,
    fuelId,
    cityId,
  });
  return axios.get(uri.toString())
    .then(({ data }) => data)
    .catch((e) => {
      console.error('getCities query error =>', e.message);
    });
}

/**
 * @method getStationsInCityRegion
 * @description Повертає CТО з регіону міста (regionCityId),
 * з можливісцю фільтрувати СТО по конкретному місту регіона (cityId)
 * @param {Number} regionCityId - Id міста в регіоні якого шукатимуться станції
 * @param {Number} cityId - Id міста в середині регіону, використовується як фільтр,
 * щоб повернути СТО в конкретньому місті регіона
 * @param {Number} brandId
 * @param {Number} subcategoryId
 * @param {Number} langId
 * @param {Number} fuelId
 * @return Promise{Object} - {
 *  value, - id СТО
 *  name, - назва СТО
 *  cityId, - місто в якому находиться СТО
 *  cityName - назва міста в якому находиться СТО
 * }
 */
export function getStationsInCityRegion({
  regionCityId,
  cityId,
  brandId,
  subcategoryId,
  fuelId,
  langId,
}) {
  const uri = new URI(`${AUTOTEST_URL}/region/${regionCityId}/stos`);
  uri.setQuery({
    cityId,
    brandId,
    subcategoryId,
    fuelId,
    langId,
  });
  return axios.get(uri.toString())
    .then(({ data }) => data)
    .catch((e) => {
      console.error(`error: ${e.message}; getStationsInCityRegion(${JSON.stringify({
        cityId,
        brandId,
        subcategoryId,
        fuelId,
        langId,
      })})`);
    });
}

export function getCitySTOs({
  cityId = 0,
  categoryId,
  bodyId,
  brandId,
  modelId,
  year,
  priceUSD,
  fuelId,
} = {}) {
  if (!Number(cityId)) return [];
  const uri = new URI(`${AUTOTEST_URL}/city/${cityId}/sto`);
  uri.setQuery({
    categoryId,
    bodyId,
    brandId,
    modelId,
    year,
    priceUSD,
    fuelId,
  });
  return axios.get(uri.toString())
    .then(({ data }) => data)
    .catch((e) => {
      console.error('getCitySTOs query error =>', e.message);
    });
}

export function getCitySTOsV2({
  cityId = 0,
  categoryId = 0,
  bodyId = 0,
  brandId,
  modelId,
  year,
  langId,
} = {}) {
  if (!Number(cityId)) return {};
  const uri = new URI(`${AUTOTEST_URL}/v2/city/${cityId}/sto`);
  uri.setQuery({
    categoryId,
    bodyId,
    brandId,
    modelId,
    year,
    langId,
  });
  return axios.get(uri.toString())
    .then(({ data }) => data);
}

/**
 * Returns station packages object. packageId -> brandId -> 1 to get package price for brand
 * @param {{ stationId: number; }} param0
 * @returns {Promise<Record<string, Record<string, { 1: number; }>>>}
 */
export function getSTOPricesPackagesV2({ stationId } = {}) {
  const uri = new URI(`${AUTOTEST_URL}/sto/${stationId}/package`);
  return axios.get(uri.toString(), {
    timeout: 1000,
  })
    .then(({ data }) => data);
}

export function getReviewsAutotest(type = 1, size = 1, from = 0, langId = 2) {
  const uri = new URI(`${AUTOTEST_URL}/public/reviews`);
  uri.setQuery({
    type,
    limit: size,
    page: from + 1,
    status_id: 1,
    langId,
  });
  return axios.get(uri.toString(), {
    timeout: 1000,
  })
    .then((response) => ({
      comments: response.data,
      size,
      from,
      total: Number(response.headers['x-total-count']),
    }));
}

export function autotestPackageVerifications(packageId, langId = 2) {
  const uri = new URI(`${AUTOTEST_URL}/package/${packageId}/verifications`);
  uri.setQuery({
    packageId,
    langId,
  });
  return axios.get(uri.toString())
    .then(({ data }) => data)
    .catch((e) => {
      console.error('autotestPackageVerifications query error =>', e.message);
    });
}

/**
 * @param payload
 * @param payload.stoId
 * @param payload.brandId
 * @param payload.year
 * @param payload.packages
 * @return {Promise}
 */
export function autotestGetVerificationsPrice(payload) {
  return axios({
    url: `${AUTOTEST_URL}/verification/technical/price`,
    method: 'POST',
    data: payload,
    config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  })
    .then(({ data }) => data)
    .catch((e) => {
      console.error('autotestGetVerificationsPrice query error =>', e.message);
    });
}

export function cheapestSTOServices(advId, langId) {
  return Number(advId)
      ? axios({
        method: 'GET',
        url: `${AUTOTEST_URL}/sto/cheapest-service/advert/${advId}`,
        params: { langId },
      })
          .then(({ data }) => data)
          .catch((err) => console.error(`cheapestSTOServices(${advId}) error: `, err?.message))
      : Promise.reject(new Error(`Invalid advId: ${advId}`));
}

export function autotestGetSTOVerificationsPriceForBrandYear({
  stoId, brandId, year, langId, autoId,
}) {
  return (stoId && brandId && year)
    ? axios({
      method: 'GET',
      url: `${AUTOTEST_URL}/sto/${stoId}/services/${brandId}/${year}`,
      params: { langId, autoId },
    })
      .then(({ data }) => data)
      .catch((e) => {
        console.error('autotestGetVerificationsPrice query error =>', e.message);
      })
    : Promise.reject(new Error(`Invalid arguments: ${stoId} ${brandId}, ${year}`));
}

/** @typedef {{ name: string; email: string; phone: string; }} OrderUserInfo */
/** @typedef {{ packageId?: number; sto: { stoId: number; }; verifications: number[], }} OrderTechnical */
/** @typedef {{ diagnostic_type: number; }} OrderVin */

/** @typedef {{ vin: OrderVin; technical: OrderTechnical; }} Orders */

/**
 *
 * @param {number} advertisementId
 * @param {OrderUserInfo} userInfo
 * @param {OrderSellerInfo} sellerInfo
 * @param {Orders} orders
 * @param {string} comment
 * @param {number} userId
 * @param {(0 | 1)=} foreignAuto
 * @returns {Promise<any>}
 */
export function autotestCreateOrder(
  advertisementId,
  userInfo,
  sellerInfo,
  orders,
  comment,
  userId,
  foreignAuto,
) {
  return axios({
    url: `${AUTOTEST_URL}/public/createOrder`,
    method: 'POST',
    data: {
      advertisementId,
      userInfo,
      sellerInfo,
      orders,
      comment,
      userId,
      foreignAuto,
      // зі старої версії WEB все вважаємо як сторінка '/check-selection', поки не буде нових вказівок
      requestSource: 'checkSelection',
    },
  })
    .then(({ data }) => data);
}

/** @typedef {Object} ForeignAuto
 * @property {Number} markaId
 * @property {Number} modelId
 * @property {Number} years
 * @property {Number} userId
 * @property {String} vin
 */
/**
 * @param {ForeignAuto} auto
 * @returns {Promise<{ autoId: number; }>}
 */
export function autotestCreateForeignAuto(auto) {
  return axios({
    url: `${AUTOTEST_URL}/public/foreignAuto`,
    method: 'POST',
    data: auto,
  })
    .then(({ data }) => data);
}

/**
 * @param {ForeignAuto} foreignAuto
 * @param {OrderUserInfo} userInfo
 * @param {OrderSellerInfo} sellerInfo
 * @param {Orders} orders
 * @param {string} comment
 * @param {number} userId
 * @returns {Promise<void>}
 */
export async function autotestCreateOrderForeignAuto(
  foreignAuto,
  userInfo,
  sellerInfo,
  orders,
  comment,
  userId,
) {
  const { autoId } = await autotestCreateForeignAuto(foreignAuto);
  return autotestCreateOrder(autoId, userInfo, sellerInfo, orders, comment, userId, 1);
}

/**
 * @param payload
 * @param payload.phone
 * @param payload.phone_time
 */
export function autotestAddQestion(payload) {
  return axios({
    url: `${AUTOTEST_URL}/addQuestion/`,
    method: 'POST',
    data: payload,
    timeout: 1000,
    config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  })
    .then(({ data }) => data)
    .catch((e) => {
      console.error('autotestAddQestion query error =>', e.message);
    });
}

/**
 * АвтоТест Менеджер
 * @param langId
 * @return {Promise}
 */
export function autotestManager(langId) {
  return axios.get(
    `${base}/content/news/templetify/autocheck/manager/?langId=${langId}`,
    { timeout: 1000 },
  )
    .then(({ data }) => data)
    .catch((err) => {
      const emptyMngr = 0;
      console.error(`ERROR: ${err?.message || err}, autotestManager(${langId})`);
      return emptyMngr;
    });
}

export function autotestDatetime() {
  return axios.get(`${AUTOTEST_URL}/datetime`)
    .then(({ data }) => data)
    .catch((err) => {
      const currDate = new Date();
      console.error(`ERROR: ${err?.message || err}, autotestDatetime()`);
      return currDate.getTime();
    });
}

export function getBidfaxData(advId) {
  return axios.get(`${base}/bidfax/api/public/links/${advId}`)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(`ERROR: ${err?.message || err}, getBidfaxData(${advId})`);
      return [];
    });
}

export function getInsuranceCheckedEvents(advId) {
  return axios.get(`${base}/api/insurance/public/tinex/${advId}/checkedEvents`)
    .then(({ data = {} }) => data)
    .catch((err) => {
      console.error(`ERROR: ${err?.message || err}, getInsuranceCheckedEvents(${advId})`);
      return {};
    });
}

export function getABtestVariants(testName, params = '', headers = {}) {
  return axios.get(`${ANALYTIC_ENGINE_URL}/ab-test/${testName}${params}`, { headers })
    .then(({ data = {} } = {}) => data)
    .catch((err) => {
      console.error(`ERROR: ${err?.message || err}, getABtestVariants(${testName})`);
      return { variant: 0 };
    });
}

export function setNewPhoto(query) {
  return axios.post(`${AUTOTEST_URL}/set-new-photo?${query}`)
    .catch((err) => {
      console.error(`ERROR: ${err?.message || err}, setNewPhoto(${query})`);
      return {};
    });
}

export function sendPhotoToAiRecognition(photoId) {
  return axios
    .post(`${AUTOTEST_URL}/photo/recognize/${photoId}`)
    .catch((err) => console.error(`sendPhotoToAiRecognition(${photoId}) error: `, err?.message));
}

export function updatePhotosOrder(photos) {
  return axios.post(`${AUTOTEST_URL}/update-photos-order`, photos)
    .catch((err) => console.error(`ERROR: ${err?.message || err}, updatePhotosOrder`));
}

export async function getOrderById(id) {
  try {
    const response = await axios.get(`${AUTOTEST_URL}/public/orders/orderId/${id}`);
    return response?.data || {};
  } catch (err) {
    console.error(`getOrderById(${id}) error:`, err?.message);
    return {};
  }
}
