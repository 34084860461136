export default {
	namespaced: true,
	state: {
		value: 0,
	},
	mutations: {
		set(state, payload) {
			state.value = payload;
		},
	},
	getters: {
		get: (state) => state.value,
	}
};
