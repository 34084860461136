import {
  SET_PRO_ACCOUNT_LEFT_BAR_DATA,
  SET_PRO_ACCOUNT_STATISTIC,
  SET_PRO_PHONES_ASSIGNMENT,
  SET_PRO_SELLER_PAGE_DATA,
} from './mutation-types';

export default {
  [SET_PRO_ACCOUNT_LEFT_BAR_DATA](state, payload) {
    state.proAccountLeftBarData = payload;
  },
  [SET_PRO_ACCOUNT_STATISTIC](state, payload) {
    state.proAccountStatisticData = payload;
  },
  [SET_PRO_PHONES_ASSIGNMENT](state, payload) {
    state.proPhonesAssignmentData = payload;
  },
  [SET_PRO_SELLER_PAGE_DATA](state, payload) {
    state.proSellerPageData = payload;
  },
};
