import Vue from 'vue';
import {cloneDeep} from '../../../../helpers/_lodash';

import actions from './actions';

export default {
	namespaced: true,
	state: {
		centers: {},
		cities: {},
		suggestsCity: [],
		zones: {}
	},
	actions,
	mutations: {
		setCenters(state, {payload = [], langId} = {}) {
			payload.forEach((item = {}) => {
				let {value, name} = item;
				let {[value]: {name: nameOld = {}} = {}} = state.centers;
				item.name = Object.assign({}, nameOld, {[langId]: name});
				Vue.set(state.centers, value, item);
			});

		},
		citiesInState(state, {payload = {}, id} = {}) {
			if (Array.isArray(payload)) {
				payload.forEach((item) => {
					const {value} = item;
					Vue.set(state.cities, value, Object.assign(item, state.cities[value], {state: id}));
				});
			} else {
				throw new Error('Invalid cities list');
			}
		},
		city(state, {payload = {}, langId} = {}) {
			const {value} = payload;
			const item = cloneDeep(payload);
			const {name} = state.cities[value] || {};
			item.name = Object.assign({}, name, {[langId]: item.name});
			Vue.set(state.cities, value, item);
		},
		suggestCity(state, payload) {
			for (let city of payload) {
				Vue.set(state.cities, city.value, city);
			}
			state.suggestsCity.splice(0, state.suggestsCity.length, ...payload);
		},
		setZones(state, {payload = [], langId} = {}) {
			payload.forEach((item = {}) => {
				let {value, name} = item;
				let {[value]: {name: nameOld = {}} = {}} = state.zones;
				item.name = Object.assign({}, nameOld, {[langId]: name});
				Vue.set(state.zones, value, item);
			});

		}
	},
	getters: {
		l10n: (state, getters, rootState, rootGetters) => {
			const {'lang/id': langId} = rootGetters;
			let {centers = {}} = state;
			return Object.values(centers)
				.reduce((result, item = {}) => {
					const {value, name: {[langId]: name} = {}} = item;
					if (name) {
						result[value] = Object.assign({}, item, {name});
					}
					return result;
				}, {});
		},
		region(state, getters, rootState, rootGetters) {
			return function (id) {
				return getters.l10n[id];
			};
		},
		citiesInState(state, getters, rootState, rootGetters) {
			return function (id) {
				return Object.values(state.cities).filter(({state} = {}) => id === state);
			};
		},
		city(state, getters, rootState, rootGetters) {
			return function (id) {
				return state.cities[id];
			};
		},
		cityl10n(state, getters, rootState, rootGetters) {
			return function (id) {
				let item = getters.city(id);
				if (item) {
					const {'lang/id': langId} = rootGetters;
					item = cloneDeep(item);
					let {name = {}} = item;
					item.name = name[langId];
					return item;
				}
			};
		},
		zonel10n(state, getters, rootState, rootGetters) {
			return function (id) {
				let {zones = {}} = state;
				let item = zones[id];
				if (item) {
					const {'lang/id': langId} = rootGetters;
					item = cloneDeep(item);
					let {name = {}} = item;
					item.name = name[langId];
					return item;
				}
			};
		},
		zonesPlain(state) {
			return Object
				.values(state.zones)
				.reduce((a, {states} = {}) => a.concat(states), []);
		},
		suggestsCity: (state) => state.suggestsCity
	}
};
