import Vue from 'vue';

import {
  autotestVin,
} from '../../../queries';

export default {
  namespaced: true,
  state: {
    vinSvg: {},
  },
  actions: {
    vinSvg({ commit, rootGetters, getters: { vinSvg } }, { autoId, fontSize = 13 }) {
      const vin = vinSvg(autoId);
      return vin
        ? Promise.resolve(vin)
        : autotestVin(autoId, fontSize)
          .then(({ vinSvg = '' }) => commit('vinSvg', { vinSvg, autoId }));
    },
  },
  mutations: {
    vinSvg(state, { vinSvg, autoId } = {}) {
      Vue.set(state.vinSvg, autoId, vinSvg);
    },
  },
  getters: {
    vinSvg({ vinSvg } = {}) {
      return (autoId) => vinSvg[autoId];
    },
  },
};
