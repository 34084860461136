export default {
	messages: {
		ru: {
			'Обрати по ID': 'Выбрать по ID',
			'html.buAuto.autocheck45': 'Выберите авто для проверки',
			'html.buAuto.lawyerCheck37': 'Просмотренные',
			'html.buAuto.lawyerCheck38': 'Избранное',
			'html.buAuto.lawyerCheck39': 'Мои авто',
			'Авто не з': 'Авто не с',
			'Введіть': 'Введите',
			'оголошення': 'объявления',
			'існує': 'существует',
			'html.buAuto.pokazatesche': 'Показать еще',
		},
		uk: {
			'Обрати по ID': 'Обрати по ID',
			'html.buAuto.autocheck45': 'Виберіть авто для перевірки',
			'html.buAuto.lawyerCheck37': 'Переглянуті',
			'html.buAuto.lawyerCheck38': 'Обране',
			'html.buAuto.lawyerCheck39': 'Мої авто',
			'Авто не з': 'Авто не з',
			'Введіть': 'Введіть',
			'оголошення': 'оголошення',
			'існує': 'існує',
			'html.buAuto.pokazatesche': 'Показати ще',
		},
	}
};
