const axios = require('axios');

const fetch = (url, params) => axios.get(url, params).then(({data} = {}) => data).catch((e) => {
	console.error(url, e.message);
});

module.exports = {
	name: 'importHTML',
	props: {
		url: {type: String, required: true},
		tag: {type: String, 'default': 'div'},
		verbose: Boolean
	},
	data() {
		return {html: ''};
	},
	methods: {
		getHTML() {
			return fetch(this.url, {timeout: 3333})
				.then((payload) => this.html = payload)
				.catch(this.verbose ? console.error : new Function());
		}
	},
	mounted() {
		return this.getHTML();
	},
	beforeUpdate() {
		return this.getHTML();
	},
	render(createElement) {
		return this.html ?
			createElement(
				this.tag,
				{
					attrs: {'data-url': this.url, 'data-tag': this.tag, 'data-verbose': this.verbose},
					domProps: {innerHTML: this.html}
				}
			) :
			undefined;
	}
};
