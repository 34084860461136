export const UPDATE_AUTH_DIIA_STATUS = 'SET_AUTH_DIIA_STATUS';
export const UPDATE_AUTH_DIIA_POPUP_VISIBLE = 'SET_AUTH_DIIA_POPUP_VISIBLE';
export const SET_AUTH_DIIA_TOKEN = 'SET_AUTH_DIIA_TOKEN';
export const SET_AUTH_DIIA_AUTH_LINK = 'SET_AUTH_DIIA_AUTH_LINK';
export const SET_DIIA_STAGE_INDEX = 'SET_DIIA_STAGE_INDEX';
export const SET_STOP_PING = 'SET_STOP_PING';
export const SET_DIIA_SHARE_REGUEST_ID = 'SET_DIIA_SHARE_REGUEST_ID';
export const SET_DIIA_SHARE_LINK = 'SET_DIIA_SHARE_LINK';
export const SET_DIIA_TYPE_CHECK = 'SET_DIIA_TYPE_CHECK';
export const SET_DIIA_SHARE_ADV_ID = 'SET_DIIA_SHARE_ADV_ID';
export const UPDATE_DIIA_STATUS_CONFIRMED_CAR = 'UPDATE_DIIA_STATUS_CONFIRMED_CAR';
