export default {
  data() {
    return {
      message: null,
      socket: null,
      reconnectionTimeout: null,
      analytics: {},
    };
  },
  mounted() {
    this.initSocket();
  },
  methods: {
    initSocket() {
      if (!WebSocket) {
        console.error('WebSocket not supported!');
        return;
      }

      this.socket = new WebSocket('wss://auto.ria.com/inbox/socket/');

      this.socket.addEventListener('message', (event) => {
        try {
          const data = JSON.parse(event.data);
          const { message, analytics = {} } = data;

          if (message) {
            this.message = message;
            this.analytics = analytics;
            if (document.visibilityState === "visible") {
              this._slonik({ ...this.analytics, event_id: 333 });
            }
          } else if (data && data.event === 'close') {
            this.message = null;
          }
        } catch (error) {
          console.error('Error parsing incoming message:', error);
        }
      });

      this.socket.addEventListener('close', (event) => {
        if (!this.reconnectionTimeout) {
          this.reconnectionTimeout = setTimeout(() => {
            this.initSocket();
            clearTimeout(this.reconnectionTimeout);
            this.reconnectionTimeout = null;
          }, 5000);
        }
      });

      this.socket.addEventListener('error', (event) => {
        console.error('WebSocket error:', event);
      });
    },
    socketClose() {
      const messageData = { event: "close" };
      this.socket.send(JSON.stringify(messageData));
    },
    close() {
      this._slonik({ ...this.analytics, event_id: 630 });
      this.analytics = {};
      this.socketClose();
    },
    handleClick(event) {
      if (event && event.target?.className === 'btn-blue') {
        this.socketClose();
      }
    },
  },
  i18n: {
    messages: {
      uk: {
        Закрити: 'Закрити',
      },
      ru: {
        Закрити: 'Закрыть',
      },
    },
  },
};
