import types from './static/search-types'


export default function () {
    return {
        searchType: {
            name: 'searchType',
            value: null,
            fieldList: types.autoInterTypes,
            dependencies: {
                to: [], // запитати данні чи очистити поля для полей вказаних в масиві при зміні даного поля
                on: [] // гетер не повертає данні для поточного поля доки не будуть заповнені поля з даного масиву (для прикладу model=>brand)
            },
            defaultValue: types.autoInterTypes['order'],
        },

        category: {
            name: 'category',
            value: null,
            fieldList: [],
            dependencies: {
                to: ['brand', 'year'], // запитати данні чи очистити поля для полей вказаних в масиві
                on: [] // гетер не повертає данні для поточного поля доки не будуть заповнені поля з даного масиву (для прикладу model=>brand)
            },
            defaultValue: {
                eng: "car",
                id: 2,
                name:"Легковой автомобиль",
                vehicleId: 2,
            },
        },
        brand: {
            name: 'brand',
            value: null,
            fieldList: [],
            dependencies: {
                to: ['model'],
                on: []
            },
            defaultValue: null,
        },
        model: {
            name: 'model',
            value: null,
            fieldList: [],
            dependencies: {
                to: [],
                on: ['brand']
            },
            defaultValue: null,
        },
        year: {
            name: 'year',
            value: {},
            fieldList: [],
            dependencies: {
                to: [],
                on: []
            },
            defaultValue: null
        },
        price: {
            name: 'price',
            value: {},
            fieldList: [],
            dependencies: {
                to: [],
                on: []
            },
            defaultValue: null,
        },
        country: {
            name: 'country',
            value: null,
            fieldList: [],
            dependencies: {
                to: [],
                on: []
            },
            defaultValue: null,
        },
    }
}
