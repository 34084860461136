export const CHANGE_CURRENT_SEARCH_TAB = 'CHANGE_CURRENT_SEARCH_TAB';
export const CHANGE_PERIOD_FIELD_VALUE = 'CHANGE_PERIOD_FIELD_VALUE';
export const SET_PERIOD_FIELD_OPTIONS = 'SET_PERIOD_FIELD_OPTIONS';

export const SET_SEARCH_FIELD_OPTIONS = 'CHANGE_SEARCH_FIELD_OPTION';
export const UPDATE_AVERAGE_PRICE_FIELD_VALUE= 'UPDATE_AVERAGE_PRICE_FIELD_VALUE';
//validation start
export const UPDATE_AVERAGE_PRICE_FIELD_VALIDATOR= 'UPDATE_AVERAGE_PRICE_FIELD_VALIDATOR';
export const UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_MESSAGE= 'UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_MESSAGE';
export const UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_STATUS= 'UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_STATUS';
//validation end
export const UPDATE_AVERAGE_PRICE_LOGGER_DATA= 'UPDATE_AVERAGE_PRICE_LOGGER_DATA';
export const UPDATE_FIELD_SEARCH_OPTIONS = 'UPDATE_AVERAGE_PRICE_FIELD_SEARCH_OPTIONS';

export const UPDATE_FIELD_HIDING = 'UPDATE_FIELD_HIDING';
