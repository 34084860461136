module.exports = {
  methods: {
    sendOrderPriceGTM(price) {
      if (!Number.isNaN(price)) {
        this.$gtm.trackEvent({
          event: 'conversion_paid_check',
          Value_check: price,
        });
      }
    },
  },
};
