export default {
	props: {
		tag: {type: String, default: 'div'},
	},
	data() {
		return ({
			beforeMount: true,
			innerHTML: '',
		});
	},
	beforeMount() {
		this.beforeMount = false;
		this.innerHTML = this?.$el?.innerHTML;
	},
	render(h) {
		return h(
			this.tag,
			this.beforeMount ?
				this.$slots.default :
				{domProps: {innerHTML: this.innerHTML}}
		);
	},
};
