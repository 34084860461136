module.exports = class {
	constructor(options) {
		const targets = new Map();

		const callback = (entries, observer) => {
			entries.forEach((entry = {}) => {
				const {target} = entry;
				const cb = targets.get(target);
				cb(entry, observer);
			});
		};

		this.__observer = new IntersectionObserver(callback, options);
		this.__targets = targets;
	}

	add(target, cb) {
		if (typeof cb === 'function') {
			this.__targets.set(target, cb);
			this.__observer.observe(target);
		} else {
			throw new Error('First argument must be a function');
		}
	}

	del(target) {
		this.__observer.unobserve(target);
		this.__targets.delete(target);
	}
};
