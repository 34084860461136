import Vue from 'vue';
import {UPDATE_FAVORITES_IDS} from "./mutations-types";

export default {
    news: (state, {id, payload}) => Vue.set(state.news, id, payload),
    toggle(state, {toggle, id}) {
        Vue.set(state.toggles, toggle, id);
    },
    save(state, {id, payload} = {}) {
        Vue.set(state.responses, id, payload);
    },
    toggleReviews(state, {toggle, id}) {
        Vue.set(state.togglesReviews, toggle, id);
    },
    saveReviews(state, {id, payload} = {}) {
        Vue.set(state.responsesReviews, id, payload);
    },
    toggleLandingMainLink(state, id) {
        state.toggleLandingMainLink = id;
    },
    saveLandingMainLink(state, {id, payload} = {}) {
        Vue.set(state.LandingMainLink, id, payload);
    },
    slonikInfo(state, payload = {}) {
        Vue.set(state, 'slonikInfo', payload);
    },
    autoleaderInfo(state, payload = {}) {
        Vue.set(state, 'autoleaderInfo', payload);
    },
    [UPDATE_FAVORITES_IDS]: (state, {data}) => state.favoritesIds = data
}
