import Vue from 'vue';

import actions from './actions';

/**
 * @deprecated
 */
export default {
	namespaced: true,
	state: {
		responses: {}
	},
	mutations: {
		save(state, {id, payload} = {}) {
			Vue.set(state.responses, id, payload);
		},
	},
	actions,
	getters: {
		getById(state) {
			return function (id) {
				return state.responses[id]
			}
		},
		subcategory(state, getters, rootState, rootGetters) {
			return function ({size = 30, categoryId, type}) {
				let {'lang/id': langId} = rootGetters;
				const params = {langId, size, categoryId, type};
				return getters.getById(JSON.stringify(params))
			}
		}
	}
}


