import { SET_SLIDERS_DATA, SET_POPUP_SLIDE_DATA } from './mutation-types';

export default {
  [SET_SLIDERS_DATA](state, payload) {
    state.sliders = payload;
  },
  [SET_POPUP_SLIDE_DATA](state, payload) {
    state.popupSlide = payload;
  },
};
