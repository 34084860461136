import {mapActions} from "vuex";
import { GOOGLE_CLIENT_ID } from '../../../../config/default.js'

class OneTapGoogle {
    constructor(src, loginFunc) {
        this.src = src
        this.googleLogin = loginFunc
    }

    load(container, clientId){
        try {
            const googleScript = document.createElement('script');
            googleScript.src = this.src;
            googleScript.async = 'async' ;
            googleScript.defer = 'defer';
            container.appendChild(googleScript);
            googleScript.onload = () => {
                if (clientId) {
                    window.google.accounts.id.initialize({
                        client_id: clientId,
                        callback: (googleUser) => {
                            // this.slonikLogs(); @todo треба буде визивати, коли зробиться логування
                            this.googleLogin(googleUser).then(() => location.reload());
                        },
                        cancel_on_tap_outside: "true",
                        context: "signin",
                        itp_support: "true"
                    });
                    window.google.accounts.id.prompt();
                } else {
                    console.error('!!!CLIENT_ID NOT DEFINED!!! OneTapGoogle/index.js--->30');
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    slonikLogs() {
        //    @todo тут буде логування для КІС
    }
}

export default {
    name: 'OneTapGoogle',

    data() {
      return {
        userId: null,
      }
    },

    methods: {
        ...mapActions({
            getUserData: 'Common/userData',
            googleLogin: 'Common/googleLoginEndpoint'
        }),
        async init() {
            this.userId = await this.getUserData()
            .then((data) => data.userId)

            if (!this.userId) {
                const oneTapContainer = this.$refs.oneTapLogin;
                const googleScript = new OneTapGoogle(
                    'https://accounts.google.com/gsi/client',
                    this.googleLogin)
                googleScript.load(oneTapContainer, GOOGLE_CLIENT_ID)
            }
        }
    },
    async mounted() {
        await this.init();
    }
}
