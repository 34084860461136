'use strict';
const imageLoaded = require('../../helpers/imageLoaded');

function renderChildren(createElement, img, sources) {
	const elements = Array.isArray(sources) ?
		sources.map((item) => createElement('source', item)) :
		[];

	if (img && img.attrs && img.attrs.src) {
		elements.push(createElement('img', Object.assign({}, img, {ref: 'img'})));
	}

	return elements;
}

module.exports = {
	name: 'lazy-picture',
	props: ['img', 'sources'],
	data() {
		return {
			wasInView: false
		};
	},
	render(createElement) {
		let {sources, img} = this;
		const children = this.wasInView ? renderChildren(createElement, img, sources) : [];

		try {
			return this.wasInView ?
				createElement('picture', children) :
				createElement('div', {style: {minWidth: '1px', minHeight: '1px'}}, [createElement('picture', children)])
				;
		} catch (error) {
			console.error('Ошибка отрисовки элемента picture:', error);
		}
	},
	mounted() {
		try {
			const _this = this;
			this.IOW = require('../../helpers/IntersectionObserver_screen_333_01');
			this.IOW.add(this.$el, (entry = {}) => {
				if (entry.isIntersecting) {
					_this.wasInView = true;
					_this.IOW.del(_this.$el);
				}
			});
		} catch (error) {
			this.wasInView = true;
			console.error(error);
		}
	},
	beforeDestroy() {
		this.IOW.del(this.$el);
	},
	updated() {
		const img = this.$refs.img;
		return img ?
			imageLoaded(img)
				.then(() => this.$emit('loaded'))
				.catch((error) => this.$emit('failed', error)) :
			undefined;
	}
};
