import { mapGetters } from 'vuex';

const moment = require('../mixins/moment').default;
const VueHeader = require('../components/Common/VueHeader/index.vue').default;
const VueFooterSSI = require('../components/Common/VueFooterSSI/index.vue').default;
const testcookie = require('../components/_aux/testcookie').default;

module.exports = {
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.langCode,
      },
      link: [
        { rel: 'manifest', href: '/manifest.json' },
        { rel: 'shortcut icon', href: 'https://css.riastatic.com/images/favicon.ico' },
        { rel: 'apple-touch-icon-precomposed', sizes: '114x114', href: 'https://css.riastatic.com/images/apple-touch-icon-114x114-precomposed.png' },
        { rel: 'apple-touch-icon-precomposed', sizes: '72x72', href: 'https://css.riastatic.com/images/apple-touch-icon-72x72-precomposed.png' },
        { rel: 'apple-touch-icon', href: 'https://css.riastatic.com/images/apple-touch-icon-57x57.png' },
        { rel: 'apple-touch-icon', sizes: '76x76', href: 'https://css.riastatic.com/images/apple-touch-icon-76x76-precomposed.png' },
        { rel: 'apple-touch-icon', sizes: '120x120', href: 'https://css.riastatic.com/images/apple-touch-icon-120x120-precomposed.png' },
        { rel: 'apple-touch-icon', sizes: '152x152', href: 'https://css.riastatic.com/images/apple-touch-icon-152x152-precomposed.png' },
        { rel: 'apple-touch-icon', sizes: '180x180', href: 'https://css.riastatic.com/images/apple-touch-icon-180x180-precomposed.png' },
        { rel: 'apple-touch-icon', href: 'https://css.riastatic.com/images/icon-hires.png' },
      ],
      script: [
        {
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Organization',
            url: 'https://auto.ria.com',
            name: 'AUTO.RIA',
            logo: 'https://auto.ria.com/images/autoria-seo.png',
            sameAs: [
              'http://www.facebook.com/AUTORIAcom',
              'https://twitter.com/AUTO_RIA_com',
              'https://plus.google.com/+авториа',
              'https://www.youtube.com/user/AutoRiaUA',
              'https://www.instagram.com/auto_ria_com/',
            ],
            contactPoint: [{
              '@type': 'ContactPoint',
              telephone: '+38-080-021-0012',
              contactType: 'customer service',
              contactOption: 'TollFree',
              areaServed: 'UA',
              availableLanguage: [
                'Ukrainian',
                'Russian',
              ],
            }, {
              '@type': 'ContactPoint',
              telephone: '+38-067-431-8229',
              contactType: 'customer service',
              areaServed: 'UA',
              availableLanguage: [
                'Ukrainian',
                'Russian',
              ],
            }, {
              '@type': 'ContactPoint',
              telephone: '+38-093-190-2723',
              contactType: 'customer service',
              areaServed: 'UA',
              availableLanguage: [
                'Ukrainian',
                'Russian',
              ],
            }, {
              '@type': 'ContactPoint',
              telephone: '+38-050-416-8688',
              contactType: 'customer service',
              areaServed: 'UA',
              availableLanguage: [
                'Ukrainian',
                'Russian',
              ],
            }],
          }),
          type: 'application/ld+json',
        },
        {
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url: 'https://auto.ria.com',
            name: 'AUTO.RIA',
          }),
          type: 'application/ld+json',
        },
        { innerHTML: '!function(e){"use strict";var n=function(n,t,o){var l,r=e.document,i=r.createElement("link");if(t)l=t;else{var a=(r.body||r.getElementsByTagName("head")[0]).childNodes;l=a[a.length-1]}var d=r.styleSheets;i.rel="stylesheet",i.href=n,i.media="only x",l.parentNode.insertBefore(i,t?l:l.nextSibling);var f=function(e){for(var n=i.href,t=d.length;t--;)if(d[t].href===n)return e();setTimeout(function(){f(e)})};return i.onloadcssdefined=f,f(function(){i.media=o||"all"}),i};"undefined"!=typeof module?module.exports=n:e.loadCSS=n}("undefined"!=typeof global?global:this);' },
        { innerHTML: 'var googletag=googletag||{};googletag.cmd=googletag.cmd||[];' },
        {
          innerHTML:
            `var p = document.getElementsByTagName('head')[0];
            function admErr() {
                var self = this;window.ADMITAD=window.ADMITAD||{},ADMITAD.Helpers=ADMITAD.Helpers||{},ADMITAD.Helpers.generateDomains=function(){for(var e=new Date,n=Math.floor(new Date(2020,e.getMonth(),e.getDate()).setUTCHours(0,0,0,0)/1e3),t=parseInt(1e12*(Math.sin(n)+1)).toString(30),i=["de"],o=[],a=0;a<i.length;++a)o.push({domain:t+"."+i[a],name:t});return o},ADMITAD.Helpers.findTodaysDomain=function(e){function n(){var o=new XMLHttpRequest,a=i[t].domain,D="https://"+a+"/";o.open("HEAD",D,!0),o.onload=function(){setTimeout(e,0,i[t])},o.onerror=function(){++t<i.length?setTimeout(n,0):setTimeout(e,0,void 0)},o.send()}var t=0,i=ADMITAD.Helpers.generateDomains();n()},window.ADMITAD=window.ADMITAD||{},ADMITAD.Helpers.findTodaysDomain(function(e){if(window.ADMITAD.dynamic=e,window.ADMITAD.dynamic){var n=function(){return function(){return self.src?self:""}}(),t=n(),i=(/campaign_code=([^&]+)/.exec(t.src)||[])[1]||"";t.parentNode.removeChild(t);var o=document.getElementsByTagName("head")[0],a=document.createElement("script");a.src="https://www."+window.ADMITAD.dynamic.domain+"/static/"+window.ADMITAD.dynamic.name.slice(1)+window.ADMITAD.dynamic.name.slice(0,1)+".min.js?campaign_code="+i,o.appendChild(a)}});
            }
            if (document.cookie.split(';').filter(function (item) {
                return item.indexOf('admitad_user=') >= 0
            }).length) {
                var admitadScript     = document.createElement('script');
                admitadScript.type    = "text/javascript";
                admitadScript.src     = "https://www.artfut.com/static/tagtag.min.js?campaign_code=2479de078d";
                admitadScript.onerror = admErr;
                admitadScript.async   = true;
                p.appendChild(admitadScript);
            }`,
        },
      ],
      meta: [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'referrer', content: 'unsafe-url' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'revisit-after', content: '1 days' },
        { name: 'verify-v1', content: 'E9HAEevUr8VPRLqroNPZClPEVXN5W034JsP9HUzB00k' },
        { property: 'fb:pages', content: '354235117123' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        { name: 'application-name', content: 'AUTO.RIA' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
        { name: 'apple-mobile-web-app-title', content: 'AUTO.RIA' },
        { name: 'msapplication-TileImage', content: 'https://css.riastatic.com/images/pinned.png' },
        { name: 'msapplication-TileColor', content: '#db5c4c' },
        { name: 'theme-color', content: '#db5c4c' },
        { property: 'catalog_name', content: 'first_auto_main' },
      ],
      __dangerouslyDisableSanitizers: ['noscript', 'script', 'meta'],
    };
  },
  mixins: [moment],
  components: {
    'vue-header': VueHeader,
    'vue-footer-ssi': VueFooterSSI,
    // GDPR: require('../components/Common/GDPR/index.vue').default,
    'vue-testcookie': testcookie,
  },
  computed: {
    ...mapGetters({
      langCode: 'lang/code',
    }),
    footerSize() {
      const { $route: { meta: { footer } = {} } = {} } = this;
      return footer;
    },
  },
  data() {
    return {
      appClass: '',
      mounted: false,
    };
  },
  mounted() {
    this.mounted = true;
    this.$root.$on('activeClass', () => {
      this.appClass ? this.appClass = '' : this.appClass = 'overflow-0';
    });
  },
};
