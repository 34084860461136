module.exports = function (full = 0, {langId = 2, mounted} = {}) {
	full = full ? 1 : 0;
	const url = `/templetify/static?t=/common/footer&langId=${langId}&full=${full}&disable_gzip=true`;
	const gdprUrl = `/demo/partials/common/gdpr.html?langId=${langId}`;
	return (`
<!--# block name="stub_footer" --><p style="display:none">ssi include error in <a href="${url}">${url}</a></p><!--# endblock -->
<!--# include virtual="${url}" stub="stub_footer" -->
<!--# include virtual="${gdprUrl}" -->
`)
};
