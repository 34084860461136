import { mapActions, mapGetters, mapMutations } from 'vuex';

const INIT_LENGTH = 5;
const PICKED_ONE_ID_CHANGE_INTERVAL = 3000;
const MAP_TABS = {
	viewedAdvertisements: 1,
	bookmarkedAdvertisements: 2,
	myAdvertisements: 3,
	pickedOne: 4,
	another: 5,
};

export default {
	i18n: require('./i18n').default,
	components: {
		'vue-advertisement': require('./Advertisement/index.vue').default,
		'vue-another-advertisement': require('./AnotherAdvertisement/index.vue').default,
		'flickity': require('../../Common/Flickity/Flickity.vue').default,
	},
	props: ['showItems', 'formDataInfotech', 'validationVIN'],
	data() {
		return ({
			mounted: false,
			currentListName: 'viewedAdvertisements',
			pickedOneId: '',
			listLength: INIT_LENGTH,
			UserAdvertisementsAreTaken: false,
			pickedOneIdChangeTimer: 0,
			flickityOptions: {
				cellAlign: 'left',
				freeScroll: true,
				lazyLoad: true,
				pageDots: false,
				contain: true,
			},
			allowFlickityRerender: true,
			lastIndexFlickity: 0,
			lastPickedOneId: 0,
			logDataDefault: { action_block: 5 },
		});
	},
	computed: {
		...mapGetters({
			search: 'shared/advertisement/search',
			userData: 'Common/userData',
			advertisements_: 'graphql/advertisements'
		}),
		viewedAdvertisements() {
			if (this.mounted) {
				try {
					const ids = window.riaHistoryStorage.getIdsByType(1);
					return Array.isArray(ids) ? ids.map((id) => ({id})) : [];
				} catch (error) {
					return [];
				}
			} else {
				return [];
			}
		},
		bookmarkedAdvertisements() {
			if (this.mounted) {
				try {
					const {items} = JSON.parse(window.localStorage.userNotepadData);
					return Array.isArray(items) ?
						items
							.filter(({content_type_id}) => content_type_id === 1)
							.map(({item_id} = {}) => ({id: item_id})) :
						[];
				} catch (error) {
					return [];
				}
			} else {
				return [];
			}
		},
		myAdvertisements() {
			if (this.formDataInfotech || this.validationVIN) {
				const tabName = 'another';
				this.currentListName = tabName;
                this.logDataDefault.form_id = MAP_TABS[tabName] || 0;
			}

			if (this.mounted) {
				if (!this.UserAdvertisementsAreTaken) {
					const {userData: {userId} = {}} = this;

					if (userId) {
						this.fetchAdvertisements({query: {userId}});
						this.UserAdvertisementsAreTaken = true;
					}
				}
				return this.search.filter(({_type} = {}) => _type === 'UsedAuto');
			} else {
				return [];
			}
		},
		pickedOne() {
			return this.pickedOneId ? [{id: this.pickedOneId}] : [];
		},
		list() {
			return this[this.currentListName];
		},
		showError() {
			return !this.pickedOneId || this._result(this.advertisements_(this.pickedOneId), 'brand.name') || false;
		},
	},
	methods: {
		...mapActions({
			fetchAdvertisements: 'shared/advertisement/search',
			isSetByAutoId: 'others/autotest/isSetByAutoId',
		}),
		...mapMutations({
			autotestLoggerData: 'others/autotest/autotestLoggerData',
		}),
		changeToList(listName, logging = true) {
			if (this.resetIpickedOneIdChangeTimer) {
				clearTimeout(this.resetIpickedOneIdChangeTimer);
			}
			if (logging) this.autotestLogger( { phone_position:MAP_TABS[listName] || 0 } );
			this.listLength = INIT_LENGTH;
			this.currentListName = listName;
			this.logDataDefault.form_id = MAP_TABS[this.currentListName] || 0;
			this.allowFlickityRerender = true;
		},
		changeToPickedOne() {
			// Очень крутая логика защиты от невалидных ID, ага. Порефакторить никогда
			if ((this.pickedOneId / 100000 > 1)) {
                if (this.lastPickedOneId !== this.pickedOneId) {
                    this.autotestLogger({ proposal_id: this.pickedOneId, click_action: 5 });
                    this.lastPickedOneId = this.pickedOneId;
                }
				this.gaEvent('Сheck_Auto', 'Click_on_Button_Another_car', 'autocheck');
				this.changeToList('pickedOne', false);
			}
		},
		rerenderFlickity() {
			this.$refs.flickity.rerender();
		},
		appendFlickityElement(append) {
			this.$refs.flickity.append(append);
			if (this.allowFlickityRerender) {
				this.rerenderFlickity();
			}
		},
		flickityEvents(event, listener) {
			this.$refs.flickity.on(event, listener);
		},
		addSlides() {
			this.flickityEvents('change', (index) => {
				const currentIndex = index + 1;
				const listLength = this.list.length;
				const listForShow = this.list.slice(0, this.listLength).length;
				const showStep = Math.round(INIT_LENGTH - 2);
				if (listLength > listForShow && currentIndex >= listForShow - showStep) {
					this.allowFlickityRerender = false;
					this.listLength += INIT_LENGTH;
				}
				this.autotestLogger({ click_action: index > this.lastIndexFlickity ? 2 : 1 });
				this.lastIndexFlickity = index;
			});
		},
		isEmptyArr() {
			if (this.viewedAdvertisements.length) {
				this.currentListName = 'viewedAdvertisements';
			} else if (this.bookmarkedAdvertisements.length) {
				this.currentListName = 'bookmarkedAdvertisements';
			} else if (this.myAdvertisements.length) {
				this.currentListName = 'myAdvertisements';
			} else {
				this.currentListName = 'pickedOne';
			}
		},
		autotestLogger(logData) {
			this.autotestLoggerData({ ...this.logDataDefault, ...logData })
		},
	},
	watch: {
		pickedOneId() {
			this.resetIpickedOneIdChangeTimer = setTimeout(function () {
				this.changeToPickedOne();
			}.bind(this), PICKED_ONE_ID_CHANGE_INTERVAL);
		}
	},
	mounted() {
		this.mounted = true;
		require('lelekaClient')();
		this.isEmptyArr();
		this.logDataDefault.form_id = MAP_TABS[this.currentListName] || 0;
	}
};
