import _laxios from '../../../../_laxios';
import {
    SET_AUTOMARKET_DATA,
    SET_AUTOMARKET_ACTIVE_CATEGORY,
    SET_AUTOMARKET_CATEGORIES, SET_AUTOMARKET_PRODUCTS
} from "../mutations/mutations-types";
import {normalizeAutomarkerData} from "./normilize";


export default {
    async getAutoMarketData({commit, rootGetters}){
        const  {'lang/id': langId } = rootGetters;

        commit(SET_AUTOMARKET_CATEGORIES, {loading: true});
        commit(SET_AUTOMARKET_PRODUCTS, {loading: true});
        commit(SET_AUTOMARKET_DATA, {loading: true});
        try{
            const response = await _laxios.getAutoMarketData
                .request({params: {langId} });

            const normalizedData = normalizeAutomarkerData(response);

            const categories = normalizedData.reduce((acc, el) => {
                acc.push(el.group);
                return acc;
            }, [])

            const products = normalizedData.reduce((acc, el) => {
                return [...acc, ...el.ads];
            }, [])

            commit(SET_AUTOMARKET_CATEGORIES, categories);
            commit(SET_AUTOMARKET_PRODUCTS, products);
            commit(SET_AUTOMARKET_DATA, normalizedData);
        }catch (e) {
            console.error('Помилка отримання данних AutoMarket');
            commit(SET_AUTOMARKET_CATEGORIES, {error: true});
            commit(SET_AUTOMARKET_PRODUCTS, {error: true});
            commit(SET_AUTOMARKET_DATA, {error: true});
        }
    },

    updateAutomarketActiveCategory({commit}, id) {
        commit(SET_AUTOMARKET_ACTIVE_CATEGORY, id);
    }
}

