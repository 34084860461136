export const objectToQuery = (obj) => {
    if(typeof obj !== 'object' || !Object.keys(obj).length) return '';
    const initArrayEntity = Object.entries(obj);

    return initArrayEntity.reduce((acc, [key, value]) => {
        const query = makeQuery({
            queryKey: key,
            queryValue: value
        })

        acc = { ...acc, ...query,  }

        return acc
    }, { })
};


const makeQuery = ({ parentQueryKey = '', queryKey, queryValue }) => {
    const commonQueryKey = parentQueryKey ?  [parentQueryKey, queryKey].join('.') : queryKey

    if(typeof queryValue !== 'object') return { [commonQueryKey] : queryValue }

    return Object.entries(queryValue).reduce((acc, [ key, value ]) => {

        const query = makeQuery({
            parentQueryKey: commonQueryKey,
            queryKey: key,
            queryValue: value
        })

        acc = { ...acc, ...query }

        return acc;
    }, {})
};

export const getDataFromQuery = (obj) => {
    return Object.entries(obj).reduce((acc, [ key, value]) => {
        const parsedValue = getQueryValue({
            key,
            value
        });

        Object.entries(parsedValue).forEach(([queryKey, queryValue]) => {
            if(!acc[queryKey] || typeof queryValue !== 'object') {
                acc[queryKey] = queryValue
            } else {
                acc[queryKey] = { ...acc[queryKey], ...queryValue}
            }
        })

        return acc
    }, {})
}


const getQueryValue = ({ key, value }) => {
    if(!key.includes('.')) {
        return {
            [key]: value
        }
    } else {
        const allKeys = key.split('.');
        const parentKey = allKeys[0]
        const childKey = key.split(parentKey + '.');
        const data = getQueryValue({ key: childKey[1], value: value})

        return {
            [parentKey]: data
        }
    }
}
