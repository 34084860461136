import Vue from 'vue';
import _laxios from "../_laxios";

module.exports = {
    namespaced: true,
    state: {
        sellersRating: {},
        reasons: {}
    },
    actions: {
        getSellerRating({ commit, getters }, ownerId) {
            return _laxios.sellerRating
                .expandUrl({ownerId})
                .request()
                .then((data = {}) => {
                    const { average = 0 }  = data;
                    const sellerRating = { average: Number(average) ? average.toFixed(1) : average };
                    commit('setSellerRating', { sellerRating, ownerId });
                }).catch(e => console.log('getSellerRating: ', e));
        },
        getMyRate({ commit }, ownerId) {
            return _laxios.getUserRating
                .expandUrl({id: ownerId})
                .request()
                .then((ratingsList = []) => {
                    const [lastRate = {}] = ratingsList;
                    commit('setMyRate', { ownerId, lastRate });
                }).catch(e => console.log('getMyRate: ', e));
        },
        getReasons({ commit, rootGetters, getters }, type = 'all'){
            let {'lang/id': langId } = rootGetters;
            if (!getters.getReasonsByType(type === 'all' ? 'seller' : type)) {
                return _laxios.getInspectorReasons
                    .expandUrl({type})
                    .request({ params: { langId } })
                    .then(reasons => {
                        commit('setReasons', { reasons, type });
                    }).catch(e => console.log('getReasons: ', e));
            }
        },
        downloadInspectorPics({}, data) {
            return _laxios
                .downloadInspectorPics
                .request({data})
                .catch(e => console.log('downloadInspectorPics: ', e));
        },
        rateUser({commit}, {ownerId, value}) {
            return _laxios.saveUserRating
                .expandUrl({id: ownerId})
                .request({ data: {value }})
                .then(lastRate => {
                    commit('setMyRate', { ownerId, lastRate });
                    return lastRate;
                }).catch(e => console.log('rateUser: ', e));
        },
    },
    mutations: {
        setSellerRating(state, { sellerRating, ownerId }) {
           Vue.set(state.sellersRating, ownerId, sellerRating);
        },
        setReasons(state, { reasons, type }) {
            Vue.set(state.reasons, type === 'all' ? 'seller' : 'auto', reasons)
        },
        setMyRate(state, { ownerId, lastRate }) {
            const data = state.sellersRating[ownerId] ? { ...state.sellersRating[ownerId], myRate: lastRate } : { myRate: lastRate }
            Vue.set(state.sellersRating, ownerId, data);
        },
    },
    getters: {
        sellerRating(state) {
            return ownerId => state.sellersRating[ownerId];
        },
        getReasonsByType(state) {
            return type => state.reasons[type];
        }
    }
};
