export default {
    props: {
        fieldData: {
            type: Object,
            required: true
        },
        fields: {
            type: Object,
            default: () => ({})
        },
        validation: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        fieldValue() {
            return this.fieldData?.value
        },
    },
    methods: {
        fieldChange(payload) {
            this.$emit('change', payload);
        },
        fieldSearch(value) {
            if(this.fieldData.component === 'form-autocomplete') {
                this.$store.dispatch(this.fieldData.api.search.dispatch, { fieldData: this.fieldData, searchValue: value })
            }
        }
    },
    mounted() {
        if(this.fieldData.api) {
            this.$store.dispatch(this.fieldData.api.get.dispatch, this.fieldData);
        }

    }
};
