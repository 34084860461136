const _laxios = require('../../_laxios');

module.exports = {
	fetchPopular: function ({commit, rootGetters, getters, state}, {category: categoryId, brand: brandId} = {}) {
		let {'shared/url/current': url, 'lang/id': langId} = rootGetters;
		let limit = 20;
		let key = `graphql_pop_models_${brandId}_${categoryId}_${langId}_${limit}`;
		let dataKey = `${categoryId}_${brandId}`;
		const data = {
    	query: require('../../_graphql/queries/models/popular').default, variables: {brandId, categoryId, langId, limit}
		};

		return state.lock[key] ?
			Promise.reject(new Error(`Lock on ${key} exists`)) :
			Promise.resolve(commit('lock', {key, value: true}))
				.then(() => state.popular[dataKey] ?
					{data: {models: {popular: state.popular[dataKey].map(id => ({id}))}}} :
					_laxios.graphql.request({data}).catch((e) => {
						console.log('[GRAPH_QL API] method [fetchPopular] =>', e.message, {url});
					}))
				.then(({data: {models: {popular}} = {}} = {}) => {
					commit('popular', {payload: popular.map(({id}) => id), category: categoryId, brand: brandId});
					return commit('lock', {key, value: false});
				})
				.catch((e) => {
					commit('lock', {key, value: false});
				});
	},
	fetchByBrand: ({commit, rootGetters, getters}, {category, brand, search_type} = {}) => {
		let {'lang/id': langId} = rootGetters;
		const withNew = search_type === 'all' ? 1 : undefined;

		if (!category || Object.values(getters.getByBrand(brand, category)).length === 0) {
			if (brand) {
				return (
					category ?
						_laxios.modelsForBrandWithCategory.expandUrl({category, brand}).request({params: {langId, withNew}}) :
						_laxios.modelsForBrand.expandUrl({brand}).request({params: {langId, withNew}})
							.then((data) =>
								Array.isArray(data) ?
									data.reduce((result, item) => {
										if (Array.isArray(item)) {
											result = result.concat(item);
										} else {
											result.push(item);
										}
										return result;
									}, []) :
									undefined
							)
				)
					.then((data) =>
						Array.isArray(data) ?
							data.map((item = {}) => {
								item.brand = brand;
								item.category = category;
								return item;
							}) :
							undefined
					)
					.then((data) => commit('save', data))
					.catch(() => console.error('Ошибка при получении моделей'));
			} else {
				return Promise.reject('Ошибка при получении моделей: Не указана марка');
			}
		}
	},
	suggest({commit}, {text = '', category, brand}) {
		const params = {
			q: text,
			fields: 'name,value,count'
		};
		if (category && brand) {
			params['context[category_brand]'] = `${category}_${brand}`;
		} else {
			params['context[brand]'] = brand;
		}

		return _laxios.suggestModel.request({params})
			.then((data) => commit('suggest', data))
			.catch(() => console.error('Ошибка при получении моделей. suggest'));
	}
};
