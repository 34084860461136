import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import Meta from 'vue-meta';
import VueI18n from 'vue-i18n';
import App from './App/index.vue';
import createRouter from './router';
import createStore from './store';
import mixinsGlobal from './mixins/global';
import routeToLang from './mixins/routeToLang';
import deviceChecker from './mixins/deviceChecker';
import googletag from './mixins/googletag';
import baseSvg from './components/svg/base';

require('./directives')(Vue);

Vue.use(Meta, {
  keyName: 'metaInfo', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-vue-meta', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'vmid', // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

Vue.mixin(mixinsGlobal);

Vue.component('no-ssr', require('./components/_aux/no-ssr'));
Vue.component('vue-server-only', require('./components/_aux/server-only').default);
Vue.component('vue-fw', require('./components/_aux/functional-wrapper'));
Vue.component('vue-router-link', require('./components/_aux/router-link-wrapper'));
Vue.component('vue-picture', require('./components/_aux/picture'));
Vue.component('vue-lp', require('./components/_aux/lazy-picture'));
Vue.component('vue-lp2', require('./components/_aux/lazy-picture2'));
Vue.component('vue-import-html', require('./components/_aux/importHTML'));
Vue.component('vue-store', require('./components/_aux/store'));
Vue.component('IconBase', require('./components/svg/IconBase.vue').default);

Vue.config.performance = true;
if (process.env.NODE_ENV !== 'production') {
  Vue.config.devtools = true;
}

Vue.filter('numberFormat', (value) => Number(value).toLocaleString('en').replace(/,/g, ' '));

const { _render } = Vue.prototype;

Vue.prototype._render = function (...rest) {
  try {
    return _render.apply(this, rest);
  } catch (error) {
    console.error('Error when render called: ', error);
    return new Vue().$createElement();
  }
};

VueI18n.prototype.getChoiceIndex = (choice, choicesLength) => {
  if (choice === 0) {
    return 0;
  }
  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;
  if (!teen && endsWithOne) {
    return 1;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }
  return (choicesLength < 4) ? 2 : 3;
};

Vue.prototype.$baseSvg = baseSvg;

Vue.use(VueI18n);
const i18n = new VueI18n({ locale: 'ru' });

export default function createApp(target, context) {
  const router = createRouter(target, context);
  const store = createStore(target, context);

  sync(store, router);
  const app = new Vue({
    i18n,
    mixins: [routeToLang, deviceChecker, googletag],
    router,
    store,
    render: (h) => h(App),
  });
  return { app, router, store };
}

/* eslint-disable */
if (module.hot) {
  module.hot.accept();
}
/* eslint-enable */
