<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="`0 0 ${width} ${height}`"
       :aria-labelledby="iconName"
       :fill="iconColor"
       role="presentation"
  >
    <title :id="iconName">{{iconName}}</title>
    <g v-html="iconHtml"></g>
  </svg>
</template>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'box'
      },
      width: {
        type: [Number, String],
        default: 16
      },
      height: {
        type: [Number, String],
        default: 16
      },
      iconColor: {
        type: String,
        default: 'none'
      },
      iconHtml: {
        type: String,
        default: ''
      }
    }
  }
</script>
