import Vue from 'vue';
import {cloneDeep} from '../../../../helpers/_lodash';
import laxios from '../../_laxios';

export default {
	namespaced: true,
	state: {
		lib: {}
	},
	actions: {
		fetch: ({commit, rootGetters, getters}) => {
			let {'lang/id': langId} = rootGetters;
			laxios.countries.stay = () => Object.values(getters.l10n).length;
			return laxios.countries.request({params: {langId}})
				.then((payload) => commit('set', {payload, langId}))
				.catch(() => console.error('Ошибка. категории авто'));
		}
	},
	mutations: {
		set(state, {payload = [], langId} = {}) {
			payload
				.forEach(item => {
					const {value, name} = item;
					let {[value]: {name: nameOld = {}} = {}} = state.lib;
					item.name = Object.assign(nameOld, {[langId]: name});
					Vue.set(state.lib, value, item);
				});
		}
	},
	getters: {
		l10n: (state, getters, rootState, rootGetters) => {
			const {'lang/id': langId} = rootGetters;
			const stateCloned = cloneDeep(state.lib);
			Object
				.values(stateCloned)
				.forEach((item = {}) => {
					const {name: {[langId]: name} = {}, value} = item;
					if (name) {
						item.name = name;
					} else {
						delete stateCloned[value];
					}

				});
			return stateCloned;
		},
		country(state, getters, rootState, rootGetters) {
			return function (id) {
				const {'lang/id': langId} = rootGetters;
				const country = cloneDeep(state.lib[id]) || {};
				let {name: {[langId]: name} = {}} = country;
				country.name = name;
				return country;
			};
		}
	}
};
