export default {
	namespaced: true,
	state: {
		current: '',
	},
	mutations: {
		current: (state, payload) => {
			state.current = payload},
	},
	getters: {
		current: ({current}) => current,
	}
}
