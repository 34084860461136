'use strict';
const imageLoaded = require('../../helpers/imageLoaded');

module.exports = {
	methods: {
		watchImg() {
			try {
				const [img] = this.$el.getElementsByTagName('img') || [];
				return img && img.src ?
					imageLoaded(img)
						.then((payload) => this.$emit('loaded', payload))
						.catch((error) => this.$emit('failed', error)) :
					undefined;
			} catch (error) {
				this.$emit('failed', error);
			}
		}
	},
	render(createElement) {
		return createElement('picture', this.$slots.default);
	},
	mounted() {
		this.watchImg();
	},
	updated() {
		this.watchImg();
	}
};
