import {
    ADD_MODIFICATION,
    DELETE_MODIFICATION,
    RESET_MODIFICATIONS
} from "./mutation-types";
import {merge} from "lodash";

export default {
    [ADD_MODIFICATION](state, payload) {
        state.modifications = {...merge(state.modifications, payload)};
    },
    [DELETE_MODIFICATION](state, { blockKey }) {
        const newModifications = Object.assign({}, state.modifications);
        Object.keys(newModifications).forEach(el => {
            if(el.includes(blockKey)) delete newModifications[el];
        })
        state.modifications = Object.assign({}, newModifications)
    },
    [RESET_MODIFICATIONS] (state) {
        state.modifications = {}
    }
}
