import Vue from 'vue';

import {
  UPDATE_AUTH_DIIA_STATUS,
  UPDATE_AUTH_DIIA_POPUP_VISIBLE,
  SET_AUTH_DIIA_TOKEN,
  SET_AUTH_DIIA_AUTH_LINK,
  SET_DIIA_STAGE_INDEX,
  SET_STOP_PING,
  SET_DIIA_SHARE_REGUEST_ID,
  SET_DIIA_SHARE_LINK,
  SET_DIIA_TYPE_CHECK,
  SET_DIIA_SHARE_ADV_ID,
  UPDATE_DIIA_STATUS_CONFIRMED_CAR,
} from './mutations-types';

export default {
    [UPDATE_AUTH_DIIA_STATUS](state, payload) {
        Vue.set(state, 'status', payload);
    },
    [UPDATE_AUTH_DIIA_POPUP_VISIBLE](state, payload) {
        Vue.set(state, 'stopPing', !payload);
        Vue.set(state, 'popVisible', payload);
    },
    [SET_STOP_PING](state, payload) {
        Vue.set(state, 'stopPing', payload);
    },
    [SET_AUTH_DIIA_TOKEN](state, payload) {
        Vue.set(state, 'stopPing', false);
        Vue.set(state, 'token', payload);
    },
    [SET_AUTH_DIIA_AUTH_LINK](state, payload) {
        Vue.set(state, 'authLink', payload);
    },
    [SET_DIIA_STAGE_INDEX](state, payload) {
        Vue.set(state, 'index', payload);
    },
    [SET_DIIA_SHARE_REGUEST_ID](state, payload) {
      Vue.set(state, 'shareRequestId', payload);
    },
    [SET_DIIA_SHARE_LINK](state, payload) {
      Vue.set(state, 'shareLink', payload);
    },
    [SET_DIIA_TYPE_CHECK](state, payload) {
      Vue.set(state, 'typeCheck', payload);
    },
    [SET_DIIA_SHARE_ADV_ID](state, payload) {
      Vue.set(state, 'advId', payload);
    },
    [UPDATE_DIIA_STATUS_CONFIRMED_CAR](state, payload) {
      state.statusConfirmedCar.push(payload);
    },
}
