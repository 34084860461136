import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		OA_zones: {},
	},
	mutations: {
		OA_zones: (state, payload = {}) => {
			Object.keys(payload).forEach(key => {
				Vue.set(state.OA_zones, key, payload[key]);
			});
		},
		delOA_zones: (state, payload = []) => {
			payload.forEach(key => {
				Vue.delete(state.OA_zones, key);
			});
		},
	},
	getters: {
		OA_zones: ({OA_zones}) => OA_zones,
	}
}
