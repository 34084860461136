import state from "./state/index";
import actions from "./actions/index";
import mutations from "./mutations/index";
import getters from "./getters/index";
import modules from './modules'

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules
}
