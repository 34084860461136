import Vue from 'vue';

import actions from './actions';
import newAuto from './newAuto';

export default {
	namespaced: true,
	modules: {
		newAuto
	},
	state: {
		base: {},
		popular: {},
		lock: {},
		suggests: []
	},
	mutations: {
		save(state, payload = []) {
			payload.forEach((item = {}) => {
					const {value} = item;
					if (!item.category) {
						const {category: categoryOld} = state.base[value] || {};
						item.category = categoryOld;
					}
					Vue.set(state.base, value, item)
				}
			);
		},
		popular(state, {category, brand, payload} = {}) {
      Vue.set(state.popular, `${category}_${brand}`, payload);
		},
		lock(state, {key, value}){
      Vue.set(state.lock, key, value);
		},
		suggest(state, payload) {
			state.suggests.splice(0, state.suggests.length, ...payload)
		}
	},
	actions,
	getters: {
		getAll: (state) => state.base,
		getPopular: (state) => {
      return function (brand, category) {
        if (state.popular[`${category}_${brand}`]) {
          return state.popular[`${category}_${brand}`].map((modelId) => state.base[modelId])
            .filter(Boolean)
            .sort(({name: a}, {name: b}) => a.localeCompare(b, 'en'));
        } else {
					return [];
        }
      }
    },
		getByBrand(state) {
			return function (brand, category) {
				return brand ?
					Object.keys(state.base).reduce((result, key) => {
						const {[key]: {brand: itemBrand, category: itemCategory} = {}} = state.base;
						if (itemBrand === brand) {
							if (!category || (category === itemCategory)) {
								result[key] = state.base[key]
							}
						}
						return result;
					}, {})
					: {}
			}
		},
		model(state){return function(id){return state.base[id]}},
		suggests: (state) => state.suggests
	}
}


