export default function () {
    return {
        news: {},
        toggles: {},
        responses: {},
        toggleLandingMainLink: '',
        LandingMainLink: {},
        togglesReviews: {},
        responsesReviews: {},
        slonikInfo: {},
        autoleaderInfo: {},
        favoritesIds: null
    }
}
