module.exports = ({isProduction} = {}) => {
	return {
		query: {
			method: 'POST',
			url: isProduction ? 'http://bu-graphql' : 'http://graphql.prod.decluster.ria.com/',
			headers: {
				// Host: isProduction ? 'bu-graphql' : 'bu-graphql.ria.dev',
				'Content-type': 'application/json'
			},
			timeout: 1000
		}
	};
};
