const promisify = require('../../helpers/promisify');

export default function (Component = {}, fnName, args) {
	function recursive(Component = {}, parentPayload) {

		const {[fnName]: fn, components = {}} = Component;

		const promise = typeof fn === 'function' ?
			promisify(fn, Component)(args, parentPayload) :
			Promise.resolve();
		return promise.then((payload) => {
				return Promise.all(
					Object
						.values(components)
						.map((item) => recursive(item, payload)));
			}
		);
	}

	return recursive(Component);
}
