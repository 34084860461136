import {idFromWidth, nameFromWidth} from '../../helpers/device'

export default {
	namespaced: true,
	state: {
		width: 1200,
		height: 1,
		isWebView: false,
	},
	mutations: {
		width: (state, payload) => state.width = payload,
		height: (state, payload) => state.height = payload,
		setWebView: (state, payload) => state.isWebView = payload,
	},
	getters: {
		id: ({width}) => idFromWidth(width),
		width: ({width}) => width,
		isWebView: ({isWebView}) => isWebView,
		height: ({height}) => height,
		isDesktop: (state, {id}) => id > 1,
		isMobile: (state, {isDesktop}) => !isDesktop,
		name: ({width}) => {
			return nameFromWidth(width)
		},
	}
}
