import _laxios from './../../../../../../_laxios/index';
import normalizeData from "./normilize";
import {
    CHANGE_CURRENT_SEARCH_TAB,
    CHANGE_PERIOD_FIELD_VALUE,
    SET_PERIOD_FIELD_OPTIONS,
    SET_SEARCH_FIELD_OPTIONS, UPDATE_AVERAGE_PRICE_FIELD_VALIDATION_MESSAGE,
    UPDATE_AVERAGE_PRICE_FIELD_VALUE,
    UPDATE_AVERAGE_PRICE_LOGGER_DATA, UPDATE_FIELD_HIDING, UPDATE_FIELD_SEARCH_OPTIONS
} from "../mutations/mutations-types";
import {
    RESET_AVERAGE_PRICE_RESPONSE,
    SET_AVERAGE_PRICE_CHART_DATA,
    SET_AVERAGE_PRICE_CHIPS_DATA,
    SET_AVERAGE_PRICE_NOTICES,
    SET_AVERAGE_PRICE_RESPONSE_TYPE,
    SET_AVERAGE_PRICE_SIMILAR_CARS_DATA,
    SET_AVERAGE_PRICE_STATISTIC_INFO,
    SET_AVERAGE_PRICE_META_DATA,
    SET_LOADER_STATE,
} from "../../../mutations/mutations-types";
import {checkValidators, checkRequiredField} from "./validation";
import {getDataFromQuery} from "../../../../../../../components/AveragePrice/helpers/objectToQuery";

const checkFieldDependencies = (currenTabFields, fieldData) => {
    const {on} = fieldData.dependencies;
    if (on.length <= 0) return true;
    return on.every(field => {
        return currenTabFields[field]?.value || currenTabFields[field]?.value?.gte || currenTabFields[field]?.value?.lte
    });
}

const checkFieldApiDependency = (currenTabFields, fieldData) => {
    const {get: {dependencies}} = fieldData.api;
    if (!dependencies.length) return true;
    const mapDependencies = dependencies.reduce(
        (acc, entity) => {
            Object.values(currenTabFields).forEach(field => {
                if (field.api && field.api.entity === entity) {
                    acc.push(field.value);
                }
            });
            return acc
        }, [])
    return mapDependencies.every(entity => entity)
};


const getValidParams = ({ fields, params }) => {
    const existParams = Object.values(fields).map(el => el.queryName);
    const queryData = getDataFromQuery(params);
    return Object.entries(queryData).reduce((acc, [key, value]) => {
        if(existParams.includes(key)) {
            acc[key] = value
        }
        return acc
    }, {})
}

const getContextDependency = ({dependencies, currenTabFields}) => {
    const dependencyContextData = Object.keys(dependencies.context);
    if (!dependencyContextData.length) return null;

    const keyWithValue = dependencyContextData.find((key) => currenTabFields[key].value)
    if (keyWithValue) {
        return {[`context[${dependencies.context[keyWithValue].contextName}]`]: currenTabFields[keyWithValue].value}
    }
}

const makeFetchSearchParams = ({currenTabFields, fieldData, searchValue}) => {
    const {search: {params, dependencies}} = fieldData.api;
    if (searchValue) params.q = searchValue;

    const searchContext = getContextDependency({dependencies, currenTabFields});
    if (searchContext) {
        return {
            ...params,
            ...searchContext
        }
    }
    return params
};


const makeEntityUrl = (currenTabFields, fieldData) => {
    const {get: {dependencies}, entity} = fieldData.api;
    if (!dependencies.length) return entity;
    const urlDepend = dependencies.reduce(
        (acc, entity) => {
            Object.values(currenTabFields).forEach(field => {
                if (field.api && field.api.entity === entity) {
                    acc = [...acc, entity, field.value];
                }
            });
            return acc;
        },
        ''
    );
    return [...urlDepend, entity].join('/');
}

const resetDependedFields = ({commit, dispatch}, {currenTabFields, fieldData}) => {
    const {dependencies: {to}} = fieldData;
    if (to.length) {
        to.forEach(field => {
            if (currenTabFields[field].value) {
                dispatch(
                    'updateFieldData',
                    {
                        name: currenTabFields[field].fieldName,
                        value: null
                    },
                );
            }
            if (currenTabFields[field].options.length) commit(SET_SEARCH_FIELD_OPTIONS, {field, options: []});
            changeDependentFieldsVisible({commit}, {fieldData, valueForHidden: true})
        })
    }
}

const changeDependentFieldsVisible = ({commit}, {fieldData, valueForHidden}) => {
    const viewDependencies = fieldData?.viewDependencies?.to
    if (viewDependencies && viewDependencies.length) {
        viewDependencies.forEach((el) => {
            commit(UPDATE_FIELD_HIDING, {name: el, hideValue: valueForHidden});
        })
    }
}

const fetchDependentFields =  ({dispatch}, {currenTabFields, fieldData}) => {
    const {dependencies: {to}} = fieldData;
    if (to.length) {
        to.forEach(async field => {
            if (currenTabFields[field].api) await dispatch(currenTabFields[field].api.get.dispatch, currenTabFields[field], {root: true});
        })
    }
}

const getDefaultValue = (value) => {
    if (typeof value !== 'object') return null;
    if (typeof value === 'object') {
        return Object.keys(value).reduce((acc, el) => {
            acc[el] = null
            return acc
        }, {})
    }
    ;
}

const getSubmitFormRequestData = (fields) => {
    return fields.reduce((acc, field) => {
        if (field.value && typeof field.value !== 'object') acc[field.fieldName] = field.value;
        if (typeof field.value === 'object' && field.value !== null) {
            Object.keys(field.value).forEach(el => {
                if (field.value[el]) {
                    if (acc[field.fieldName]) {
                        acc[field.fieldName] = Object.assign(
                            {},
                            acc[field.fieldName],
                            {[el]: field.value[el]}
                        )
                    } else {
                        acc[field.fieldName] = {
                            [el]: field.value[el]
                        }
                    }
                }
            })
        }
        return acc
    }, {})
}

const checkExistValue = ({ value, options }) => {
    if(typeof value === 'object') {
        const result = Object.entries(value).map(([key, value]) => {
            return options.some(el => String(el.value) === String(value))
        })
        return result.some(el => el)
    }
    return options.some(el => String(el.value) === String(value))
}

export default {
    changeSearchTab({commit, dispatch}, payload) {
        commit(CHANGE_CURRENT_SEARCH_TAB, payload)
    },
    changePeriod({commit, dispatch}, payload) {
        commit(CHANGE_PERIOD_FIELD_VALUE, payload.value)
        // запит за новими данними
        dispatch('submitForm')
    },
    async fetchFieldData({commit, dispatch, getters, rootGetters}, fieldData) {
        const {'lang/id': langId} = rootGetters;
        const {
            'getCurrentTabFields': currenTabFields,
            'getFormData': formData
        } = getters;
        const fieldDependenciesIsValid = checkFieldDependencies(currenTabFields, fieldData);
        const apiDependenciesIsValid = checkFieldApiDependency(currenTabFields, fieldData);
        if (fieldDependenciesIsValid && apiDependenciesIsValid) {
            const entityUrl = makeEntityUrl(currenTabFields, fieldData);
            const params = fieldData?.api?.get?.params || {}
            try {
                let data = await _laxios.getAverageFormFieldData.expandUrl({entityUrl}).request({params: {langId, ...params}});
                data = normalizeData({data, fieldData});

                commit(SET_SEARCH_FIELD_OPTIONS, {field: fieldData.fieldName, options: data});

                // reset field if fieldValue is not exist in fieldOption ONLY for Primitive types and Objects
                if(formData[fieldData.queryName]) {
                    const valueIsExist = checkExistValue({ value: formData[fieldData.queryName], options: data });
                    if(!valueIsExist) {
                        dispatch('resetField', fieldData.fieldName)
                        if (fieldData.fieldName.options?.length) commit(SET_SEARCH_FIELD_OPTIONS, {field: fieldData.fieldName, options: []})
                    }
                }

                // Сховати залежні або відобразити залежні поле коли немає опцій для даного поля
                commit(UPDATE_FIELD_HIDING, {name: fieldData.fieldName, hideValue: !Boolean(data.length)});
                if (fieldData.value) changeDependentFieldsVisible({commit}, {
                    fieldData,
                    valueForHidden: !Boolean(data.length)
                })
            } catch (e) {
                console.error('AveragePrice error:', e)
            }
        }
    },
    //only for field with autocomplete type
    async fetchOptionsBySearch({commit, getters, rootGetters}, payload) {
        const {searchValue, fieldData} = payload;
        const {'lang/id': langId} = rootGetters;
        const {
            'getCurrentTabFields': currenTabFields
        } = getters;
        const params = makeFetchSearchParams({currenTabFields, fieldData, searchValue})
        const entity = fieldData.api.search.entity
        try {
            let data = await _laxios.getAverageFormFieldOptionsBySearch.expandUrl({entity}).request({params: {langId, ...params}});

            if (data && data.length && searchValue) {
                commit(UPDATE_FIELD_SEARCH_OPTIONS, {name: fieldData.fieldName, value: data});
            } else {
                commit(UPDATE_FIELD_SEARCH_OPTIONS, {name: fieldData.fieldName, value: []});
            }
        } catch (e) {
            console.error('Error fetchOptionsBySearch');
        }
    },

    updateFieldData({commit, dispatch, getters}, payload) {
        const {name, value} = payload;
        const {
            'getAllFields': allFields
        } = getters;

        const fieldData = allFields[name];
        // resetData for depended fields
        resetDependedFields({commit, dispatch}, {currenTabFields: allFields, fieldData});
        commit(UPDATE_AVERAGE_PRICE_FIELD_VALUE, payload);
        // fetch data for depended fields
        fetchDependentFields({dispatch}, {currenTabFields: allFields, fieldData})
        // reset Response if we have response and current fieldIs Required and setup value == null
        checkValidators({commit}, { fieldData, value })
    },

    resetField({state, getters, commit, dispatch}, fieldName) {
        const {
            'getCurrentTabFields': currenTabFields
        } = getters;

        const fieldData = currenTabFields[fieldName];
        if (fieldData.value) {
            resetDependedFields({commit, dispatch}, {currenTabFields, fieldData})
            const defaultValue = getDefaultValue(fieldData.value);
            commit(UPDATE_AVERAGE_PRICE_FIELD_VALUE, {name: fieldName, value: defaultValue})
        }
    },

    updateAVGPriceLoggerData({commit}, payload) {
        commit(UPDATE_AVERAGE_PRICE_LOGGER_DATA, payload);
    },

    async submitForm({commit, getters, dispatch, rootGetters}) {
        const {'lang/id': langId} = rootGetters;

        const {
            'getPeriodFieldData': periodData,
            'getCurrentTabFields': currenTabFields
        } = getters;

        const period = periodData.value || periodData.defaultValue;
        const formIsValid = checkRequiredField({commit}, {fields: currenTabFields});

        if (formIsValid) {
            commit(`landing/averagePrice/${SET_AVERAGE_PRICE_NOTICES}`, null, {root: true});
            commit(`landing/averagePrice/${SET_LOADER_STATE}`, true, {root: true});
            const params = getSubmitFormRequestData(Object.values(currenTabFields));

            try {
                const response = await _laxios.postAverageFormFieldData.request({data: {langId, period, params}});
                if(response) {
                    dispatch(`landing/averagePrice/resetResponseData`, {}, {root: true})
                }

                if (response.searchType) commit(`landing/averagePrice/${SET_AVERAGE_PRICE_RESPONSE_TYPE}`, response.searchType, {root: true})
                if (response.noticeData?.length) commit(`landing/averagePrice/${SET_AVERAGE_PRICE_NOTICES}`, response.noticeData, {root: true});
                if (response.periodSelectorData?.elements?.length) commit(SET_PERIOD_FIELD_OPTIONS, response.periodSelectorData.elements)
                if (response.chipsData) {
                    response.chipsData.chips.forEach((el) => {
                        dispatch('updateFieldData', { name: el.entity, value: el.value })
                    })
                    commit(`landing/averagePrice/${SET_AVERAGE_PRICE_CHIPS_DATA}`, response.chipsData, {root: true});
                }
                if (response.statisticData?.length) commit(`landing/averagePrice/${SET_AVERAGE_PRICE_STATISTIC_INFO}`, response.statisticData, {root: true});
                if (response.graphData?.length) commit(`landing/averagePrice/${SET_AVERAGE_PRICE_CHART_DATA}`, response.graphData.slice(-30), {root: true});
                if (response.similarCars?.length) commit(`landing/averagePrice/${SET_AVERAGE_PRICE_SIMILAR_CARS_DATA}`, response.similarCars, {root: true});
                if (response.meta) commit(`landing/averagePrice/${SET_AVERAGE_PRICE_META_DATA}`, response.meta, {root: true});
            } catch (e) {
                console.log('Error submitForm', e)
            }
            commit(`landing/averagePrice/${SET_LOADER_STATE}`, false, {root: true});
        }
    },

    async setFormByQuery({ commit, getters, dispatch }, params) {
        const {
            'getAllFields': fields
        } = getters;
        const normalizedQuery = getValidParams({ fields, params});
        try {
            for (const [name, value] of Object.entries(normalizedQuery)) {
                await dispatch('updateFieldData', { name, value })
            }
        } catch (e) {
            console.log('Set query error')
        }


    }
}
