'use strict';

module.exports = {
	extends: require('./picture'),
	data() {
		return {
			wasInView: false
		};
	},
	render(createElement) {
		return this.wasInView ?
			createElement('picture', this.$attrs, this.$slots.default) :
			createElement('div', Object.assign({style: {minWidth: '1px', minHeight: '1px'}}), [createElement('picture', this.$attrs)])
			;
	},
	mounted() {
		try {
			this.IOW = require('../../helpers/IntersectionObserver_screen_333_01');
			this.IOW.add(this.$el, (entry = {}, observer) => {
				if (entry.isIntersecting) {
					this.wasInView = true;
					this.$emit('viewed', {entry, observer});
					this.IOW.del(this.$el);
				}
			});
		} catch (error) {
			this.wasInView = true;
			this.$emit('viewed', {entry, observer});
			console.error(error);
		}
	},
	beforeDestroy() {
		this.IOW.del(this.$el);
	},
};
