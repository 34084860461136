import devices from '../../constants/devices'

export function deviceFromWidth(width) {
	return Object.values(devices).find(({min, max}) => width >= min && width <= max);
}

export function idFromWidth(width) {
	let {id} = deviceFromWidth(width) || {};
	return id
}

export function nameFromWidth(width) {
	let {name} = deviceFromWidth(width) || {};
	return name
}
