import {zipObject} from '../../helpers/_lodash';
import Vue         from 'vue';

export const MODULE_PREFIX = 'VinCheck';
let state                  = {
	autotestData: {},
	accessDenied: {},
	reportPhotos: {},
	reportData  : {}
};

export default {
	state,
	namespaced: true,
	mutations : {
		set: (state, payload) => {
			try {
				for (let name in payload) {
					name in state
					? (state[name] = payload[name])
					: console.error(
							`Not correct Data with name <<${name}>> in the state, set default value in store/VinCheck.js; Payload =>`,
							payload);
				}
			}
			catch (e) {
				console.error('Set data to store : ', e);
			}

		}
	},
	getters   : zipObject(Object.keys(state),
			Object.keys(state)
						.map((name) => {
							return (state) => {
								return state[name];
							};
						}))
};
