export default {
  indexName: {
    default: 'auto,order_auto,newauto_search',
    type: 'string',
  },
  'verified.VIN': {
    delete: 0,
    type: 'number',
  },
  'plateNumber.length.gte': {
    delete: 0,
    type: 'number',
  },
  'categories.main.id': {
    delete: 0,
    type: 'number',
  },
  'body.id[i]': {
    delete: [],
    type: 'array',
  },
  'country.origin.id[i]': {
    delete: [],
    type: 'array',
  },

  'year[i].gte': {
    type: 'array',
    label: 'group',
  },
  'year[i].lte': {
    type: 'array',
    label: 'group',
  },

  'year[i].gte.not': {
    type: 'array',
    label: 'group',
  },
  'year[i].lte.not': {
    type: 'array',
    label: 'group',
  },

  series: {
    delete: [],
    type: 'array',
  },

  'country.origin': {
    delete: [],
    type: 'array',
  },

  // Стоимость
  'price.USD.gte': {
    delete: 0,
    type: 'number',
    label: 'group',
  },
  'price.USD.lte': {
    delete: 0,
    type: 'number',
    label: 'group',
  },
  'price.EUR.gte': {
    delete: 0,
    type: 'number',
    label: 'group',
  },
  'price.EUR.lte': {
    delete: 0,
    type: 'number',
    label: 'group',
  },
  'price.UAH.gte': {
    delete: 0,
    type: 'number',
    label: 'group',
  },
  'price.UAH.lte': {
    delete: 0,
    type: 'number',
    label: 'group',
  },
  'price.currency': {
    delete: 0,
    type: 'number',
  },

  'post.exchanges.type.id': {
    delete: 0,
    type: 'number',
  },
  auction: {
    delete: 0,
    type: 'number',
  },
  'post.exchanges.payment.id': {
    delete: 0,
    type: 'number',
  },
  'post.exchanges.filters.category': {
    delete: 0,
    type: 'number',
  },
  'post.exchanges.filters.subCategory': {
    delete: 0,
    type: 'number',
  },
  'post.exchanges.filters.brand': {
    delete: 0,
    type: 'number',
  },
  'post.exchanges.filters.model': {
    delete: 0,
    type: 'number',
  },

  region: {
    delete: [],
    type: 'array',
    group: 'locations',
  },
  city: {
    delete: [],
    type: 'array',
    group: 'locations',
  },
  distance_from_city_km: {
    delete: 0,
    type: 'number',
    group: 'locations',
  },
  locations: {
    delete: [],
    type: 'array',
  },

  // Стан
  'abroad.not': {
    default: -1,
    type: 'number',
  },
  'country.import.usa.not': {
    delete: -1,
    type: 'number',
  },
  'custom.not': {
    default: -1,
    type: 'number',
  },
  credit: {
    delete: -1,
    type: 'number',
  },
  confiscated: {
    delete: -1,
    type: 'number',
  },
  'damage.not': {
    delete: -1,
    type: 'number',
  },
  technicalCondition: {
    delete: 0,
    type: 'number',
  },
  paintCondition: {
    delete: 0,
    type: 'number',
  },
  spareParts: {
    delete: -1,
    type: 'number',
  },
  sellerType: {
    delete: 0,
    type: 'number',
  },
  'country.import.id': {
    delete: 0,
    type: 'string',
  },
  // Технічні характеристики
  'fuel.id[i]': {
    delete: [],
    type: 'array',
  },
  'gearbox.id[i]': {
    delete: [],
    type: 'array',
  },
  'drive.id[i]': {
    delete: [],
    type: 'array',
  },
  'eco_standart[i]': {
    delete: [],
    type: 'array',
  },
  'color.type[i]': {
    delete: [],
    type: 'array',
  },
  'fuel.consumption.city.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'fuel.consumption.city.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'fuel.consumption.route.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'fuel.consumption.route.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'fuel.consumption.combine.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'fuel.consumption.combine.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'fuel.consumption.accumulatorCapacity.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'fuel.consumption.accumulatorCapacity.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'fuel.consumption.powerReserve.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'fuel.consumption.powerReserve.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'engine.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'engine.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'power.hp.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'power.hp.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'power.kW.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'power.kW.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'mileage.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'mileage.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'carrying.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'carrying.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  axle: {
    delete: -1,
    type: 'number',
  },
  'wheel.id': {
    delete: -1,
    type: 'number',
  },
  'doors.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'doors.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'seats.gte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'seats.lte': {
    delete: undefined,
    type: 'number',
    label: 'group',
  },
  'color.metallic': {
    delete: 0,
    type: 'number',
  },
  'color.id[i]': {
    delete: [],
    type: 'array',
  },
  'options.id[i]': {
    delete: [],
    type: 'array',
  },
  // Результати пошуку
  'sort[0].order': {
    delete: '',
    type: 'string',
  },
  with_autotest_tech_check: {
    delete: 0,
    type: 'number',
  },
  with_autotest_vin_check: {
    delete: 0,
    type: 'number',
  },
  with_autotest_corporate_check: {
    delete: 0,
    type: 'number',
  },
  top: {
    delete: 0,
    type: 'number',
  },
  'dates.sold.not': {
    delete: '',
    type: 'string',
  },
  'dates.sold': {
    delete: '',
    type: 'string',
  },
  size: {
    delete: 10,
    type: 'number',
  },
  'photos.all.id.gte': {
    delete: 0,
    type: 'number',
  },
  'video.key.gte': {
    delete: 0,
    type: 'number',
  },
  vat: {
    delete: 0,
    type: 'number',
  },
  installment: {
    delete: 0,
    type: 'number',
  },
  newSubscribe: {
    delete: 0,
    type: 'number',
  },
  editSubscribe: {
    delete: '',
    type: 'string',
  },
  channelId: {
    delete: 0,
    type: 'number',
  },
  firstTime: {
    delete: 0,
    type: 'number',
  },
  'headlights.id': {
    delete: 0,
    type: 'number',
  },
  'interiorMaterials.id': {
    delete: 0,
    type: 'number',
  },
  'interiorColors.id': {
    delete: 0,
    type: 'number',
  },
  'seatAdjustment.id': {
    delete: 0,
    type: 'number',
  },
  'seatVentilation.id': {
    delete: 0,
    type: 'number',
  },
  'seatsHeated.id': {
    delete: 0,
    type: 'number',
  },
  'memorySeatModule.id': {
    delete: 0,
    type: 'number',
  },
  'windowLifter.id': {
    delete: 0,
    type: 'number',
  },
  'conditioner.id': {
    delete: 0,
    type: 'number',
  },
  'powerSteering.id': {
    delete: 0,
    type: 'number',
  },
  'steeringWheelAdjustment.id': {
    delete: 0,
    type: 'number',
  },
  'spareWheel.id': {
    delete: 0,
    type: 'number',
  },
  omniId: {
    delete: '',
    type: 'string',
  },
};
