import { mapActions, mapGetters } from 'vuex';
import { get, set, unset } from '../../../../helpers/cookie';

export default {
	name: 'MultiAccounts',
	components: {
		'account': require('./components/Account/index.vue').default,
	},
	data() {
		return {
			showIframe: false,
			showModal: false,
			isRegistrationIframe: false,
			iframeHeight: 0,
			receiveData: false,
			showMore: false,
			iframeQueryString: '',
		};
	},
	computed: {
		...mapGetters({
			userData_: 'Common/userData',
			getAccounts_: 'accountSwitches/getAccounts'
		}),
		userData() {
			return this.userData_ || {};
		},
		userId() {
			return this._result(this.userData_, 'userId', 0);
		},
		count() {
			return Object.keys(this.accounts).length || 0;
		},
		accounts() {
			let accounts = this.getAccounts_;
			delete accounts[this.userId];
			return accounts;
		},
		userPhoneOrEmail() {
			return this._result(this.userData, 'userPhones', [])[0] || this._result(this.userData, 'userEmail', '');
		},
		userName() {
			return this._result(this.userData, 'ownerName') || this._result(this.userData, 'userName') || this.$t('Немає імені');
		},
		isCabinet() {
			return /cabinet/.test(this.$route.path);
		},

	},
	methods: {
		...mapActions({
			fetchAllUsers_: 'accountSwitches/fetch',
			checkPspId_: 'accountSwitches/checkPspId',
		}),
		fetchAllUsers(userId) {
			this.fetchAllUsers_({userId});
		},
		toggleIframe(scenario) {
			if (scenario === 'add-account') {
				this.generateIframeQueryString();
				this.sendSlonikLogs(5);
			}
			if (scenario === 'close') {
				this.sendSlonikLogs(3, 3);
			}
			this.showIframe = !this.showIframe;
		},
		eventHandler(event) {
			let data = {};
			if (typeof event.data === 'object') {
				data = event.data;
			} else if (typeof event.data === 'string') {
				try {
					data = JSON.parse(event.data);
				} catch (e) {
					console.log('Parse event.data error', e);
				}
			}

			const { login_user_id: loginUserId = 0 } = data;

			switch (true) {
				case data.login === 'ok' && data.type === 'add-user':
					if (Number(loginUserId)) {
						this.reLogin(Number(loginUserId))
					}
					this.showIframe = false;
					break;
				case data.page === 'add-user-registration':
					this.isRegistrationIframe = true;
					break;
				case data.page === 'add-user-login':
					this.isRegistrationIframe = false;
					break;
			}
			if (data.height) {
				this.iframeHeight = data.height;
			}
		},
		reLogin(userId) {
			const cookies = get('PSPS_IDS');
			let pspIds = {};
			try {
				pspIds = JSON.parse(cookies);
			} catch (e) {
				console.log('Parse PSPS_IDS error', e);
			}
			const { PSP_ID = '' } = pspIds[userId] || {};
			if (PSP_ID) {
				const params = { switchAuth: 1 };
				this.checkPspId_({userId, params}).then(() => {
					this.setStorageData();
					this.$router.go(this.$router.currentRoute);
				}).catch(e => {
					console.log('Error check PSP_ID ', e);
					this.showIframe = true;
				});
			} else {
				this.generateIframeQueryString({ userId, scenario: 'login' });
				this.showIframe = true;
			}
			this.sendSlonikLogs(1, 1);
		},
		closeModal() {
			this.showModal = false;
			this.sendSlonikLogs(2);
		},
		setStorageData() {
			const haveMultiAccounts = localStorage.getItem('haveMultiAccounts');
			if (!haveMultiAccounts) {
				localStorage.setItem('haveMultiAccounts', 1);
			}
		},
		generateIframeQueryString(params = { scenario: 'add-account' }) {
			this.iframeQueryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		},
		sendSlonikLogs(clickAction, actionBlock) {
			this._slonik({
				event_id: 889,
				click_action: clickAction,
				action_block: actionBlock,
				previous_url: clickAction === 1 ? document.referrer : undefined,
				current_url: window.location.pathname,
				screentype: this.isDesktop ? 1 : 2,
				owner_id: this.userId,
			});
		},
	},
	watch: {
		showIframe(newValue) {
			if (newValue) {
				window.addEventListener('message', this.eventHandler, false);
			} else {
				window.removeEventListener('message', this.eventHandler, false);
				this.isRegistrationIframe = false;
			}
		},
		showModal(newValue) {
			if (newValue) {
				this.receiveData = true;
				this.sendSlonikLogs(1);
			}
			if (!newValue && this.showIframe) {
				this.showIframe = false;
			}
		},
		receiveData(newValue) {
			if (newValue) {
				this.fetchAllUsers(this.userId);
			}
		},
		showMore(newValue) {
			if (!newValue) {
				const wrap = this._result(this.$refs, 'showAll', {});
				wrap.scrollTo(0, 0);
			}
			this.sendSlonikLogs(newValue ? 7 : 6);
		}

	},
	i18n: {
		messages: {
			uk: {
				'Створити новий аккаунт': 'Створити новий аккаунт',
				'Додати акаунт': 'Додати акаунт',
				'Ваші акаунти': 'Ваші акаунти',
				'Перейти в кабінет': 'Перейти в кабінет',
				'Показати ще': 'Показати ще',
				'Приховати': 'Приховати',
				'Немає імені': 'Немає імені',
			},
			ru: {
				'Створити новий аккаунт': 'Создать новый аккаунт',
				'Додати акаунт': 'Добавить аккаунт',
				'Ваші акаунти': 'Ваши аккаунты',
				'Перейти в кабінет': 'Перейти в кабинет',
				'Показати ще': 'Показать еще',
				'Приховати': 'Скрыть',
				'Немає імені': 'Нет имени',
			}
		}
	}
};
