import Vue from 'vue';
import _laxios from '../_laxios';

export default {
	fetch: ({commit, rootGetters, getters}, {_target, type, filter, subFilter, size} = {}) => {
		let {'lang/id': langId} = rootGetters;
		const params = {langId, type, filter, subFilter, size};
		const id = JSON.stringify(params);
		_laxios.mainPageCatalogs.stay = () => getters.response(id);
		return _laxios.mainPageCatalogs.request({params, responseType: 'json'})
			.then((body) => {
				if (body) {
					commit('save', {id, payload: body})
				}
				commit('toggle', {toggle: _target, id});
			});
	}
};
