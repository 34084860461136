import Vue from 'vue';

import actions from './actions';

export default {
	namespaced: true,
	state: {
		counter: '',
		categoryInformer: {}
	},
	mutations: {
		counter(state, payload) {
			state.counter = payload
		},
		categoryInformer(state, payload) {
			if (payload && typeof payload === 'object') {
				Object.keys(payload)
					.map(key => {
						let {[key]: value} = payload;
						Vue.set(state.categoryInformer, key, value)
					})
			}
		},
	},
	actions,
	getters: {
		counter: ({counter}) => counter,
		categoryInformer: ({categoryInformer}) => categoryInformer,
	}
}


