export default {
  status: (state) => state.status,
  stopPing: (state) => state.stopPing,
  getVisiblePopDiia: (state) => state.popVisible,
  getAuthDiiaLink: (state) => state.authLink,
  token: (state) => state.token,
  index: (state) => state.index,
  getDiiaShareLink: (state) => state.shareLink,
  getDiiaShareRequestId: (state) => state.shareRequestId,
  typeCheck: (state) => state.typeCheck,
  advId: (state) => state.advId,
  statusConfirmedCar: (state) => state.statusConfirmedCar,
}
