export default {
  messages: {
    ru: {
      'common.exit': 'Выход',
      'html.buAuto.new3': 'новое|новых|новых',
      'html.buAuto.uvedomleniya3': 'уведомление|уведомления|уведомлений',
      'html.buAddForm.header1': 'Сделать все прочитанным',
      'html.buAddForm.header2': 'У Вас нет новых уведомлений',
      'html.buAuto.personalArea': 'Личный кабинет',
      'html.buAuto.personalAreaEntry': 'Вход в кабинет',
      'common.car.counted': 'Автомобили',
      'html.buAuto.nedvijimost': 'Недвижимость',
      'html.buAuto.avtotovaru': 'Автотовары',
      'html.buAuto.avtozapchasti': 'Автозапчасти',
      'html.buAuto.prodatavto': 'Продать авто',
      'mainPage.header.usedCars': 'Автомобили б/у',
      'catalogCar.newCars': 'Новые авто',
      'html.buAuto.novosti': 'Новости',
      'mainPage.services.orderingSparesTable': 'Стол заказа запчастей',
      'html.buAuto.katalog': 'Каталог',
      'html.buAuto.all.about.checks': 'Все о проверках на AUTO.RIA',
      'html.buAuto.verification.center': 'Центр проверок AUTO.RIA',
      'common.provenCars': 'Проверка авто по VIN-коду',
      'html.buAuto.Check.the.car': 'Проверка авто на СТО',
      'html.buAuto.Check.packages': 'Пакеты проверок',
      'html.buAuto.recommended.5': 'Подсчет средней цены на б/у авто',
      'mainPage.services.autoCustomsRate': 'Таможенный калькулятор',
      'html.buAuto.poisk': 'Поиск',
      'common.and': 'и',
      'common.avtotovarov': 'автотовар|автотовара|автотоваров',
      'mainPage.services.usedCarsFromDealer': 'Авто с пробегом от Автодилеров',
      'company.add.common.foreignCars': 'Авто из-за рубежа',
      'common.reviews': 'Отзывы об авто',
      'common.goods': 'Товары|Товаров|Товары',
      'html.buAuto.mobilnoeprilojenie': 'мобильное приложение',
      'common.download': 'Загрузить',
      'mainPage.services.reviews': 'Отзывы об авто',
      'userMenu.moveToArchive': 'Удалить в архив',
      'Наконец-то!': 'Наконец-то!',
      'Каталог авторазборок': 'Каталог авторазборок',
      'Покупайте и продавайте авто без ограничений': 'Покупайте и продавайте авто без ограничений',
      'Смотреть все': 'Смотреть все',
      'Услуги для авто': 'Услуги для авто',
      уведомления: 'уведомления',
      Уведомления: 'Уведомления',
      Избранное: 'Избранное',
      'Добавить объявление': 'Добавить объявление',
      'Продать авто': 'Продать авто',
      'У Вас 1 нове повідомлення': 'У Вас 1 новое уведомление',
      'Увійдіть на AUTO.RIA, так зручніше': 'Войдите на AUTO.RIA, так удобней',
      'Ви зможете підписуватись на свіжододані': 'Вы сможете подписываться на свежедобавленные',
      'та отримувати більш точні рекомендації': 'и получать более точные рекомендации',
      'Це 100% допоможе знайти ідеальне авто': 'Это 100% поможет найти идеальное авто',
      Увійти: 'Войти',
      Закрити: 'Закрыть',
      'Як не переплатити за омріяне авто?': 'Как не переплатить за мечтательный автомобиль?',
      'Розрахуйте вартість на основі актуальних пропозицій на AUTO.RIA': 'Рассчитайте стоимость на основе актуальных предложений на AUTO.RIA',
      Розрахувати: 'Рассчитать',
      'Яка ринкова ціна вашого авто?': 'Какая рыночная цена вашего автомобиля?',
      'Щоб не продешевити — розрахуйте вартість': 'Чтобы не удешевить — рассчитайте стоимость на основе актуальных предложений на AUTO.RIA',
      'Дізнайтесь історію автомобіля': 'Узнайте историю автомобиля',
      'Замовте перевірку VIN-коду': 'Закажите проверку VIN-кода! Только достоверные данные из официальных баз данных',
      Подробиці: 'Подробности',
      'і про': 'и о',
      'html.buAuto.financing': 'Финансирование авто на AUTO.RIA'
    },
    uk: {
      'common.exit': 'Вихід',
      'html.buAuto.new3': 'нове|нових|нових',
      'html.buAuto.uvedomleniya3': 'повідомлення|повідомлення|повідомлень',
      'html.buAddForm.header1': 'Зробити все прочитаним',
      'html.buAddForm.header2': 'У Вас немає нових повідомлень',
      'html.buAuto.personalArea': 'Особистий кабінет',
      'html.buAuto.personalAreaEntry': 'Увійти в кабінет',
      'common.car.counted': 'Автомобілі',
      'html.buAuto.nedvijimost': 'Нерухомість',
      'html.buAuto.avtotovaru': 'Автотовари',
      'html.buAuto.avtozapchasti': 'Автозапчастини',
      'html.buAuto.prodatavto': 'Продати авто',
      'mainPage.header.usedCars': 'Вживані авто',
      'catalogCar.newCars': 'Нові авто',
      'html.buAuto.novosti': 'Новини',
      'mainPage.services.orderingSparesTable': 'Стіл замовлення запчастин',
      'html.buAuto.katalog': 'Каталог',
      'html.buAuto.all.about.checks': 'Усе про перевірки на AUTO.RIA',
      'html.buAuto.verification.center': 'Центр перевірок AUTO.RIA',
      'common.provenCars': 'Перевірка авто по VIN-коду',
      'html.buAuto.Check.the.car': 'Перевірка авто на СТО',
      'html.buAuto.Check.packages': 'Пакети перевірок',
      'html.buAuto.recommended.5': 'Підрахунок середньої ціни на авто',
      'mainPage.services.autoCustomsRate': 'Митний калькулятор',
      'html.buAuto.poisk': 'Пошук',
      'common.and': 'та',
      'common.avtotovarov': 'автотовар|автотовара|автотоварів',
      'mainPage.services.usedCarsFromDealer': 'Авто з пробігом від Автодилерів',
      'company.add.common.foreignCars': 'Авто з-за кордону',
      'common.reviews': 'Відгуки про авто',
      'common.goods': 'Товари|Товарів|Товари',
      'html.buAuto.mobilnoeprilojenie': 'мобільний додаток',
      'common.download': 'Завантажити',
      'mainPage.services.reviews': 'Відгуки про авто',
      'userMenu.moveToArchive': 'Видалити в архів',
      'Наконец-то!': 'Нарешті!',
      'Каталог авторазборок': 'Каталог авторозбірок',
      'Покупайте и продавайте авто без ограничений': 'Купуйте та продавайте авто без обмежень',
      'Смотреть все': 'Переглянути всі',
      'Услуги для авто': 'Послуги для авто',
      уведомления: 'сповіщення',
      Уведомления: 'Сповіщення',
      Избранное: 'Обране',
      'Добавить объявление': 'Додати оголошення',
      'Продать авто': 'Продати авто',
      'У Вас 1 нове повідомлення': 'У Вас 1 нове повідомлення',
      'Увійдіть на AUTO.RIA, так зручніше': 'Увійдіть на AUTO.RIA, так зручніше',
      'Ви зможете підписуватись на свіжододані': 'Ви зможете підписуватись на свіжододані',
      'та отримувати більш точні рекомендації': 'та отримувати більш точні рекомендації',
      'Це 100% допоможе знайти ідеальне авто': 'Це 100% допоможе знайти ідеальне авто',
      Увійти: 'Увійти',
      Закрити: 'Закрити',
      'Як не переплатити за омріяне авто?': 'Як не переплатити за омріяне авто?',
      'Розрахуйте вартість на основі актуальних пропозицій на AUTO.RIA': 'Розрахуйте вартість на основі актуальних пропозицій на AUTO.RIA',
      Розрахувати: 'Розрахувати',
      'Яка ринкова ціна вашого авто?': 'Яка ринкова ціна вашого авто?',
      'Щоб не продешевити — розрахуйте вартість': 'Щоб не продешевити — розрахуйте вартість на основі актуальних пропозицій на AUTO.RIA',
      'Дізнайтесь історію автомобіля': 'Дізнайтесь історію автомобіля',
      'Замовте перевірку VIN-коду': 'Замовте перевірку VIN-коду! Тільки достовірні дані з офіційних баз даних',
      Подробиці: 'Подробиці',
      'і про': 'і про',
      'html.buAuto.financing': 'Фінансування авто на AUTO.RIA'
    },
  },
};
