import _laxios from '../_laxios';

export default {
	rotator({commit, rootGetters}, {ids, limit = 50} = {}) {
		ids = Array.isArray(ids) ? ids.join(',') : '';
		let {'lang/id': langId, 'shared/url/current': url} = rootGetters;
		const data = {query: require('../_graphql/queries/rotator').default, variables: {ids, langId, limit}};
		return _laxios.graphql.request({data}).then(({data: {rotator} = {}} = {}) => commit('rotator', rotator))
			.catch((e) => {
				console.log('[GRAPH_QL API] method (rotator)', e.message, {url});
			});
	},
	rotatorVideo({commit, rootGetters}, {ids} = {}) {
		let {'lang/id': langId, 'shared/url/current': url} = rootGetters;
		const data = {query: require('../_graphql/queries/advertisementBase'), variables: {ids, langId}};
		return _laxios.graphql.request({data}).then(({data: {get} = {}} = {}) => {
			commit('rotatorVideo', get);
			return get;
		}).catch((e) => {
			console.error('[GRAPH_QL API] method (rotatorVideo)', e.message, {url});
		});
	},
	rotatorIdsOnly({commit, rootGetters}, {ids, limit = 50} = {}) {
		ids = Array.isArray(ids) ? ids.join(',') : '';
		let {'lang/id': langId, 'shared/url/current': url} = rootGetters;
		const data = {query: require('../_graphql/queries/rotator').idsOnly, variables: {ids, langId, limit}};
		return _laxios.graphql.request({data}).then(({data} = {}) => data)
			.catch((e) => {
				console.log('[GRAPH_QL API] method (rotatorIdsOnly)', e.message, {url});
			});
	},
};
