import Vue from 'vue'
import _laxios from '../_laxios'

export default {
	namespaced: true,
	state: {
		responses: {}, // Ответы на запросы к api
		targets: {}, // Назначения ответов
	},
	actions: {
		fetch({commit, rootGetters, getters}, params = {}) {
			let {'lang/id': langId} = rootGetters;
			const {_target} = params;
			params = Object.assign({}, params, {langId});
			const hash = JSON.stringify(params);
			return _laxios.seoCommon.expandUrl({target: _target}).request({params})
				.then(payload => {
						if (payload) {
							commit('save', {payload, id: hash});
						}
						commit('target', {id: _target, payload: hash})
					}
				)
				.catch(()=> console.error('Ошибка при получении seo'))
		}
	},
	mutations: {
		save: (state, {payload, id} = {}) => {
			Vue.set(state.responses, id, payload)
		},
		target: (state, {payload, id} = {}) => {
			Vue.set(state.targets, id, payload)
		},
	},
	getters: {
		getByHash(state) {
			return function (hash) {
				return state.responses[hash]
			}
		},
		getTargetHashById(state) {
			return function (id) {
				return state.targets[id]
			}
		},
		getTargetData(state, getters) {
			return function (id) {
				return getters.getByHash(getters.getTargetHashById(id))
			}
		},
	}
}
