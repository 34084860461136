module.exports = [
	{
		id: 1,
		label: '$',
		name: 'USD'
	},
	{
		id: 2,
		label: '€',
		name: 'EUR'
	},
	{
		id: 3,
		label: 'грн',
		name: 'UAH'
	}
];
