import Vue from 'vue';

import { getInsuranceCheckedEvents } from '../../../queries';

export default {
  namespaced: true,
  state: {
    reportId: 0,
    checkedEventsCount: 0,
  },
  actions: {
    async fetchCheckedEventsCount({ commit }, advId) {
      const { checkedEventsCount, reportId } = await getInsuranceCheckedEvents(advId);
      if (reportId) commit('setCheckedEventsCount', checkedEventsCount);
      if (checkedEventsCount) commit('setReportId', reportId);
    },
  },
  mutations: {
    setCheckedEventsCount(state, count) {
      Vue.set(state, 'checkedEventsCount', count);
    },
    setReportId(state, reportId) {
      Vue.set(state, 'reportId', reportId);
    },
  },
  getters: {
    getCheckedEventsCount({ checkedEventsCount }) {
      return checkedEventsCount;
    },
    getReportId({ reportId }) {
      return reportId;
    },
  },
};
