export default {
    listAutomarketCategory: (state) => {
        return state.autoMarketCategories ? state.autoMarketCategories : [];
    },
    listAutomarketCategoryLoading: (state) => {
        return Boolean(!state.autoMarketCategories || state.autoMarketCategories?.loading) || false;
    },
    listAutomarketCategoryError: (state) => {
        return state.autoMarketCategories?.error;
    },
    activeCategoryId(state) {
        return state.activeCategoryId || null;
    },
    categoryProducts: (state, getters) => {
        if(!getters.activeCategoryId && !state.autoMarketProducts?.length) return [];

        if(!getters.activeCategoryId) return state.autoMarketProducts;

        return state.autoMarketProducts.filter(el => getters.activeCategoryId === el.groupId);
    },
    categoryProductsLoading: (state) => {
        return Boolean(!state.autoMarketProducts || state.autoMarketProducts?.loading)|| false;
    },
    categoryProductsError: (state) => {
        return state.autoMarketProducts?.error;
    },
}


