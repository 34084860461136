import state from './state';
import state2 from './state2';
import advertisement from './advertisement';
import history from './history';
import items from './items';
import result from './result';

export default {
	namespaced: true,
	modules: {
		state,
		state2,
		advertisement,
		history,
		items,
		result
	}
};
