export default {
	namespaced: true,
	state: {
		height: 0
	},
	mutations: {
		height(state, payload) {
			state.height = payload;
		}
	},
	getters: {
		height({height}) {
			return height;
		}
	}
};
