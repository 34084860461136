export default {
    getLoader: (state) => state.loader,
    getChipsData: (state) => state.responseAveragePrice.chipsData || [],
    getChartPriceData: (state) => state.responseAveragePrice.chartData || [],
    getSimilarCars: (state) => state.responseAveragePrice.similarCars || [],
    getSearchUrl: (state) => state.responseAveragePrice.searchUrl || '',
    getAveragePriceStatistics: (state) => state.responseAveragePrice.statistics || [],
    getResponseType: (state) => state.responseAveragePrice.responseType,
    getAveragePriceNoticeData: (state) => state.responseAveragePrice.notices || [],
    getMetaData: (state) => state.responseAveragePrice.metaData || {},
    getSearchType:(state) =>  state.responseAveragePrice.responseType || {},
}
