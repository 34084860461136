/* eslint-disable global-require */
const get = require('../../helpers/Object/get');

module.exports = {
  mixins: [
    require('./slonDataLogger'),
  ],
  methods: {
    sendLog(click, logData = {}) {
      const href = get(click, 'target.parentElement.parentElement.href');
      const href2 = get(click, 'target.href');
      const href3 = get(click, 'target.parentElement.href');
      const id1 = get(click, 'target.id');
      const id2 = get(click, 'target.parentElement.id');
      const id3 = get(click, 'target.parentElement.parentElement.id');

      const url = href || href2 || href3;
      const id = id1 || id2 || id3;
      if (url) {
        this._slonik({
          ...logData,
          item_type: 2,
          button_type: 1,
          current_point_action: this.clearAutoRiaUrl(url),
          current_url: this.clearAutoRiaUrl(window.location.pathname),
          previous_url: this.getPathUrl(this.clearAutoRiaUrl(document.referrer)),
          search_text: id,
        });
      }
    },
    loggerHeader(click) {
      try {
        this.sendLog(click, { event_id: 1021 });
      } catch (e) {
        console.error('===ERROR in loggerHeader===', e.toString());
      }
    },
    loggerFooter(click) {
      try {
        this.sendLog(click, { event_id: 1102 });
      } catch (e) {
        console.error('===ERROR in loggerFooter===', e.toString());
      }
    },
    logAppBanner(clickAction) {
      const labels = {
        event_id: 1101,
        action_block: 2,
        click_action: clickAction,
        current_url: window.location.pathname,
        previous_url: document.referrer,
      };
      return this._slonik.bind(null, labels);
    },
    listenElementClicks(element, handler) {
      element?.addEventListener('click', handler);
      element?.addEventListener('auxclick', handler);
    },
    observerFooterLinks() {
      document.querySelectorAll('#wrapperFooter a')
        .forEach((link) => {
          this.listenElementClicks(link, this.loggerFooter);
        });
      document.querySelectorAll('.social-section.container .f-btn')
        .forEach((link, index) => {
          this.listenElementClicks(link, this.logAppBanner(index + 4));
        });
      const appLandingLink = document.querySelector('.social-section.container .f-img a');
      this.listenElementClicks(appLandingLink, this.logAppBanner(1));

      const observer = new IntersectionObserver((entries) => {
        const [obsTarget] = entries;
        if (obsTarget && obsTarget.intersectionRatio !== 0 && obsTarget.isIntersecting) {
          observer.disconnect();
          this.logAppBanner()();
        }
      }, { threshold: 0.9 });
      const social = document.querySelector('.social-section.container');
      if (social) observer.observe(social);
    },
  },
  mounted() {
    this.$nextTick(this.observerFooterLinks);
  },
};
