let host;
let scheme;

switch (process.env.NODE_ENV) {
  case 'development':
    host = 'autolocal.ria.com';
    scheme = process.env.RIA_SCHEME || 'https';
    break;
  case 'dev-cluster':
    host = 'dev-auto.ria.com';
    scheme = 'https';
    break;
  case 'production':
  default:
    host = 'auto.ria.com';
    scheme = 'https';
    break;
}
const base = `${scheme}://${host}`;
module.exports = {
  scheme,
  host,
  base,
};
