import Vue from 'vue';
import Vuex from 'vuex';

import shared from './shared';
import lang from './lang';
import search from './search';
import Common from './Common';
import rotator from './rotator';
import news from './news';
import market from './market';
import catalogs from './catalogs';
import currencies from './currencies';
import others from './others';
import TechnicalCheck from './TechnicalCheck';
import LawyerCheck from './LawyerCheck';
import AddForm from './AddForm';
import VinCheck from './VinCheck';
import Kind16Check from './Kind16Check';
import AppVideoCalls from './appVideoCalls';
import feed from './feed';
import publication from './publication';
import compareCars from './compareCars';
import accountSwitches from './accountSwitches';
import impression from './impression';
import autoInter from './autoInter';
import landing from './landing';
import proAccount from './ProAccount';
import infoBlock from './infoBlock';
import donation from './donation';

const contact = require('./contact');
const logs = require('./logs');
const graphql = require('./graphql').default;

Vue.use(Vuex);

export default function createStore() {
  return new Vuex.Store({
    modules: {
      shared,
      contact,
      logs,
      graphql,
      Common,
      search,
      Kind16Check,
      AppVideoCalls,
      lang,
      rotator,
      market,
      catalogs,
      currencies,
      others,
      TechnicalCheck,
      LawyerCheck,
      AddForm,
      VinCheck,
      feed,
      publication,
      compareCars,
      accountSwitches,
      impression,
      autoInter,
      news,
      landing,
      proAccount,
      donation,
      infoBlock,
    },
  });
}
