import Vue from 'vue';
import { getABtestVariants } from '../../../queries';

export default {
  namespaced: true,
  state: {
    abTestVariant: {},
  },
  actions: {
    async fetchABtestVariants({ commit }, { testName, params, headers }) {
      const { variant } = await getABtestVariants(testName, params, headers);
      commit('setABtestVariant', { testName, variant });
      return variant;
    },
  },
  mutations: {
    setABtestVariant(state, { testName, variant }) {
      Vue.set(state.abTestVariant, testName, variant);
    },
  },
  getters: {
    getABtestVariant(state) {
      return function (testName) {
        return state.abTestVariant[testName];
      };
    },
  },
};
