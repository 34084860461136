export default {
    // вставляємо ЛИШЕ внутрянку SVG
    chat: '<path fill-rule="evenodd" clip-rule="evenodd" d="M5.414 12 7 13.586V12h3a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h3.414zM8 14.793a.5.5 0 0 1-.854.353L5 13H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H8v1.793z" fill="#fff"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 1a1 1 0 0 0-1 1v1H4V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-1V8h1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6z" fill="#fff"></path>',
	check: '<path fill="none" stroke-width="2.5" d="M3 8l3 3 7-7"></path>',
    info: '<circle cx="8" cy="8" r="7.5" stroke="#256799"/><circle cx="8" cy="4" r="0.5" stroke="#256799"/><rect x="7.5" y="6.5" width="1" height="6" rx="0.5" stroke="#256799"/>',
    clock: '<path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 15C4.1 15 1 11.9 1 8C1 4.1 4.1 1 8 1C11.9 1 15 4.1 15 8C15 11.9 11.9 15 8 15Z"/><path d="M8 5.5C8 5.22386 7.77614 5 7.5 5C7.22386 5 7 5.22386 7 5.5V9H11.5C11.7761 9 12 8.77614 12 8.5C12 8.22386 11.7761 8 11.5 8H8V5.5Z"/>',
    location: '<path fill-rule="evenodd" clip-rule="evenodd" d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8ZM8 9C9.65685 9 11 7.65685 11 6C11 4.34315 9.65685 3 8 3C6.34315 3 5 4.34315 5 6C5 7.65685 6.34315 9 8 9Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10.2191 12.2503C11.6896 10.4123 13 8.14202 13 6C13 3.23858 10.7614 1 8 1C5.23858 1 3 3.23858 3 6C3 8.14202 4.31044 10.4123 5.78087 12.2503C6.49841 13.1472 7.21841 13.8975 7.75958 14.4236C7.84452 14.5062 7.9249 14.5831 8 14.6541C8.0751 14.5831 8.15548 14.5062 8.24042 14.4236C8.78159 13.8975 9.50159 13.1472 10.2191 12.2503ZM8 16C8 16 14 11 14 6C14 2.68629 11.3137 0 8 0C4.68629 0 2 2.68629 2 6C2 11 8 16 8 16Z"/>',
    'iconShortLink': '<path fill="#256799" d="M7,3H4C3.4,3,3,3.4,3,4v8c0,0.6,0.4,1,1,1h8c0.6,0,1-0.4,1-1V9h1v3c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V4 c0-1.1,0.9-2,2-2h3V3z"></path><path fill="#256799" d="M9.5,8L8,6.5l3-3L9.5,2H14v4.5L12.5,5L9.5,8z"></path>',
    'checkGreen': '<path fill="none" stroke-width="2.5" d="M3 8l3 3 7-7"></path>',
    'shieldCheck': '<path d="M1 2C4 2 8 0 8 0C8 0 12 2 15 2V5C15 11 12 14 8 16C4 14 1 11 1 5V2Z" fill="#256799"/><path d="M4.75 7.25L7 9.5L11.75 4.75" stroke="white" stroke-width="2"/>',

// name: '',
};
