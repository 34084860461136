import {
    UPDATE_SEARCH_FIELD_LIST,
    UPDATE_SEARCH_FIELD_VALUE,
    RESET_SEARCH_FIELD_VALUE,
    RESET_SEARCH_FIELD_LIST,
} from "./mutations-types";

export default {
    [UPDATE_SEARCH_FIELD_LIST](state, { field, data, concatFlag = false }){
        concatFlag ?
            state[field].fieldList = {...state[field].value, ...data}
            :
            state[field].fieldList = data
    },
    [UPDATE_SEARCH_FIELD_VALUE](state, { field, value, concatFlag = false }){
        concatFlag ?
            state[field].value = {...state[field].value, ...value}
            :
            state[field].value = value
    },
    [RESET_SEARCH_FIELD_VALUE] (state, field) {
        state[field].value = state[field].defaultValue || null;
    },
    [RESET_SEARCH_FIELD_LIST] (state, field) {
        state[field].fieldList = [];
    },
}
