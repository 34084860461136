import laxios from '../../_laxios';
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		lib: {},
	},
	actions: {
		fetch: ({commit, rootGetters, getters}, {all = 1,} = {}) => {
			let {'lang/id': langId} = rootGetters;
			const params = {lang_id: langId};
			return laxios.categories_newAuto.request({params})
				.then((response) => {
					if (response) {
						return commit('set', {payload: response, langId});
					}
				})
				.catch(()=> console.error('Ошибка. категории авто. новые'));
		}
	},
	mutations: {
		set (state, {payload, langId} = {}) {
			payload.forEach((item = {}) => {
				const {category_id: id, name} = item;
				const {[id]: {name: nameOld} = {}} = state.lib;
				item.name = Object.assign({}, nameOld, {[langId]: name});
				Vue.set(state.lib, id, item)
			});
		},
	},
	getters: {
		categoriesAll(state){
			return state.lib
		},
		category(state) {
			return function (id) {
				return state.lib[id]
			}
		}
	}
}
