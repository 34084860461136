import _laxios from '../_laxios';

module.exports = {
  state: Object.assign({

  }),
    actions: {
      sendPhone({ commit }, {phone,segment=''}) {
          return _laxios
              .sendPhoneSms
              .request({ data: {phone,segment} })
              .then(payload => {
                  return true;
              })
              ;
      }
    },
    mutations: {

    },
    getters: {

    },
    namespaced: true
};
