import Vue from 'vue';
import { cloneDeep } from '../../../../helpers/_lodash';
import laxios from '../../_laxios';

export default {
  namespaced: true,
  state: {
    lib: {},
  },
  actions: {
    fetch: ({ commit, rootGetters, getters }) => {
      const { 'lang/id': langId } = rootGetters;
      laxios.fuels.stay = () => Object.values(getters.l10n).length;
      return laxios.fuels.request({ params: { langId } })
        .then((payload) => commit('set', { payload, langId }))
        .catch(() => console.error('Ошибка. тип топлива'));
    },
  },
  mutations: {
    set(state, { payload = [], langId } = {}) {
      payload
        .forEach((item, index) => {
          const { value, name } = item;
          const { [value]: { name: nameOld = {} } = {} } = state.lib;
          item.name = Object.assign(nameOld, { [langId]: name });
          item.index = index;
          Vue.set(state.lib, value, item);
        });
    },
  },
  getters: {
    l10n: (state, getters, rootState, rootGetters) => {
      const { 'lang/id': langId } = rootGetters;
      const stateCloned = cloneDeep(state.lib);
      Object
        .values(stateCloned)
        .forEach((item = {}) => {
          const { name: { [langId]: name } = {}, value } = item;
          if (name) {
            item.name = name;
          } else {
            delete stateCloned[value];
          }
        });
      return stateCloned;
    },
    fuel(state, getters, rootState, rootGetters) {
      return function (id) {
        const { 'lang/id': langId } = rootGetters;
        const fuel = cloneDeep(state.lib[id]) || {};
        const { name: { [langId]: name } = {} } = fuel;
        fuel.name = name;
        return fuel;
      };
    },
  },
};
