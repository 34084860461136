// типи запитів для івенту 787
const event787 = {
  openCheckByVinPage: 1,
  validationError: 2,
  availableInfotechData: 3,
  notAvailableInfotechData: 4,
  anotherCarCheckOrdering: 5,
  successAnotherCarCheck: 6,
  failedAnotherCarCheck: 7,
};

export default {
  event787,
};
