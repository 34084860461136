import Vue from 'vue';
import actions from './actions';

function reorder(feed) {
	const pool = [
		feed.filter(({type} = {}) => type === 'UsedAuto'),
		feed.filter(({type} = {}) => type === 'NewAuto')
	];

	const data = [];
	for (
		let i = (new Date().getHours()) % pool.length; // Определяем где начинаем выборку (новые или б/у)
		pool.length; // Выборка происходит пока есть откуда выбирать
		i++
	) {
		const index = i % pool.length;
		let {[index]: bunch} = pool;
		const item = bunch.shift();
		if (item) {
			data.push(item);
		} else {
			pool.splice(index, 1);
		}
	}
	return data;
}

export default {
	namespaced: true,
	state: {
		rotator: [],
		rotatorVideo: [],
	},
	actions,
	mutations: {
		rotatorVideo(state, data) {
			Vue.set(state, 'rotatorVideo', data);
		},
		rotator(state, {feed, version} = {}) {
			if (Array.isArray(feed)) {
				feed = feed.filter(({payload: {photos} = {}} = {}) => photos && (photos.main || (Array.isArray(photos.all) && photos.all.length)));
				state.rotator.splice(0, state.rotator.length, ...reorder(feed));
				Vue.set(state, 'version', version);
			}
		},
		killRotatorItem(state, {id, type}) {
			const index = state.rotator.findIndex(({type: itemType, payload: {id: itemId} = {}} = {}) => itemId === id && itemType === type);
			if (index > -1) {
				state.rotator.splice(index, 1);
			}
		},
	},
	getters: {
		rotator: (state) => state.rotator,
		version: (state) => state.version,
		rotatorVideo: (state) => state.rotatorVideo,
	}
};
