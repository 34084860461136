
export default function normalizeAutoInterSearchFieldData(field, data) {
    switch (field){
        case 'category': {
            return data.reduce((acc, el) => {
                acc.push({
                    id: el.topReviewHits?.vehicleClass_info?.vehicleClass_id,
                    name: el.topReviewHits?.vehicleClass_info?.name || '',
                    eng: el.topReviewHits?.vehicleClass_info?.eng || '',
                    vehicleId: el.topReviewHits?.vehicleClass_info?.vehicleClass_id || 2, // Id category car
                    count: el.doc_count || 0,
                })
                return acc
            }, [])
        }
        case 'subcategory': {
            return data.reduce((acc, el) => {
                if(el?.topReviewHits?.category_id){
                    acc.push({
                        id: el.topReviewHits?.category_id,
                        name: el.topReviewHits?.category_info?.name || '',
                        eng: el.topReviewHits?.category_info?.eng || '',
                        vehicleId: el.topReviewHits?.category_info?.vehicleClass_id,
                    })
                }
                return acc
            }, [])
        }
        case 'brand': {
            return data.reduce((acc, el) => {
                acc.push({
                    id: el.topReviewHits?.make_id,
                    name: el.topReviewHits?.make_info?.name || '',
                    slang: el.topReviewHits?.make_info?.slang || '',
                    eng: el.topReviewHits?.make_info?.eng || '',
                    vehicleId: el.topReviewHits?.make_info?.vehicleClass_id,
                    count: el.doc_count
                })
                return acc
            }, [])
        }
        case 'model': {
            return data.reduce((acc, el) => {
                if(el.topReviewHits?.model_info?.model_id) {
                    acc.push({
                        id: el.topReviewHits?.model_info?.model_id,
                        idBu: el.topReviewHits?.model_info?.modelBu || null,
                        name: el.topReviewHits?.model_info?.name || '',
                        slang: el.topReviewHits?.model_info?.slang || '',
                        eng: el.topReviewHits?.model_info?.eng || '',
                        vehicleId: el.topReviewHits?.model_info?.vehicleClass_id,
                        count: el.doc_count
                    })
                }
                return acc
            }, [])
        }
        case 'region': {
            return data.reduce((acc, el) => {
                if(el.value) {
                    acc.push({
                        id:  el.value,
                        name: el.name
                    })
                }
                return acc
            }, [])
        }
        case 'country': {
            return data.reduce((acc, el) => {
                if(el.topReviewHits?.country_info?.country_int) {
                    acc.push({
                        id: el.topReviewHits?.country_info?.country_int,
                        name: el.topReviewHits?.country_info?.name || '',
                        locative: el.topReviewHits?.country_info?.locative || '',
                        eng: el.topReviewHits?.country_info?.eng || '',
                        genitive: el.topReviewHits?.country_info?.genitive || '',
                        country_code: el.topReviewHits?.country_info?.country_code || '',
                        dative: el.topReviewHits?.country_info?.dative || '',
                    })
                }
                return acc
            }, [])
        }
        case 'city': {
            return data.reduce((acc, el) => {
                if(el.topReviewHits?.cities_info?.city_id) {
                    acc.push({
                        id: el.topReviewHits?.cities_info?.city_id,
                        name: el.topReviewHits?.cities_info?.name || '',
                        locative: el.topReviewHits?.cities_info?.locative || '',
                        eng: el.topReviewHits?.cities_info?.eng || '',
                        genitive: el.topReviewHits?.cities_info?.genitive || '',
                        country_code: el.topReviewHits?.cities_info?.country_code || '',
                        dative: el.topReviewHits?.cities_info?.dative || '',
                    })
                }
                return acc
            }, [])
        }
        case 'year': {
            return data.reduce((acc, el) => {
                acc.push({
                    id: el.topReviewHits?.year,
                    name: el.topReviewHits?.year || '',
                    value: el.topReviewHits?.year || '',
                    count: el.doc_count || 0,
                })
                return acc
            }, [])
        }
        default: {
            return []
        }
    }
}
