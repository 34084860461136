import Vue from 'vue';
import _laxios from "../../_laxios";

export default {
	namespaced: true,
	state: {
	},
	actions: {
		bodyModelFetch: ({commit, getters, rootGetters}, {model} = {}) => {
			if (model && !getters.bodiesByModel(model)) {
				return _laxios.bodyModel.request({params: {modelId: model}})
					.then((payload) => {
						if (payload) {
							commit('set', {payload, model})
						}
					}).catch(() => {
						console.error('Ошибка получения modelBody');
					})
			}
		},
	},
	mutations: {
		set(state, {payload, model} = {}) {
			Vue.set(state, model, payload);
		},
	},
	getters: {
		bodiesByModel(state) {
			return function (model) {
				return state[model];
			};
		}
	}
}


