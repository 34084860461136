'use strict';
module.exports = (fn, thisArg) =>
	function () {
		return new Promise((resolve, reject) => {
			try {
				resolve(fn.apply(thisArg, arguments));
			} catch (error) {
				reject(error);
			}
		});
	};
