import laxios from '../../_laxios';

export default {
	fetchCenters: ({commit, rootGetters, getters}) => {
		let {'lang/id': langId} = rootGetters;
		laxios.regionCenters.stay = () => Object.values(getters.l10n).length;
		return laxios.regionCenters.request({params: {langId}})
			.then((payload) => commit('setCenters', {payload, langId}))
			.catch(() => console.error('Ошибка. центры'));
	},
	citiesInState({commit, getters, rootGetters}, {id} = {}) {
		let {'lang/id': langId} = rootGetters;
		const citiesInState = getters.citiesInState(id);

		return Array.isArray(citiesInState) && citiesInState.length ?
			Promise.resolve(citiesInState) :
			laxios.citiesInState.expandUrl({id}).request({params: {langId}})
				.then((payload) => commit('citiesInState', {payload, id}));
	},
	suggestCity: ({commit, rootGetters}, text) => {
		if (text) {
			let {'lang/id': langId} = rootGetters;
			const params = {
				q: text,
				fields: 'name,value,state',
				langId
			};
			return laxios.citySuggest.request({params})
				.then((response) => {
					if (response) {
						commit('suggestCity', response);
						response.forEach((item) => commit('city', {payload: item, langId}));
					}
				})
				.catch(() => console.error('Ошибка. Центры. autocomplit'));
		}
	},
	fetchZones: ({commit, rootGetters, getters}) => {
		let {'lang/id': langId} = rootGetters;
		laxios.zones.stay = () => Object.values(getters.zonel10n).length;
		return laxios.zones.request({params: {langId}})
			.then((payload) => commit('setZones', {payload, langId}))
			.catch(() => console.error('Ошибка. регионы'));
	}
};
