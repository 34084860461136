import {
   SET_AUTOMARKET_ACTIVE_CATEGORY,
   SET_AUTOMARKET_CATEGORIES,
   SET_AUTOMARKET_DATA,
   SET_AUTOMARKET_PRODUCTS,
} from "./mutations-types";

export default {
   [SET_AUTOMARKET_DATA] (state, payload) {
      state.autoMarketData = payload;
   },
   [SET_AUTOMARKET_ACTIVE_CATEGORY](state, payload) {
      state.activeCategoryId = payload;
   },
   [SET_AUTOMARKET_CATEGORIES](state, payload) {
      state.autoMarketCategories = payload;
   },
   [SET_AUTOMARKET_PRODUCTS](state, payload) {
      state.autoMarketProducts = payload;
   }
}
