/* eslint-disable global-require */
module.exports = {
  mixins: [require('./slonDataLogger')],
  methods: {
    loggerExit(sourceId) {
      try {
        if (sourceId === 20) this.gaEvent('MainPageNew', 'ClickOn_Exit', 'Header');
        this._slonik({
          project_id: 1,
          event_id: 802,
          click_action: sourceId,
          owner_id: this.getUserId(),
          current_url: encodeURI(this.clearAutoRiaUrl(window.location.href)),
          previous_url: encodeURI(this.clearAutoRiaUrl(document.referrer)),
        });
      } catch (e) {
        console.error('=== ERROR in loggerExit ===', e.toString());
      }
    },
  },
};
