export default {
  index: 0,
  stopPing: false,
  status: false,
  popVisible: false,
  bindingLink: '',
  authLink: '',
  token: '',
  timeoutId: null,
  shareLink: '',
  shareRequestId: '',
  typeCheck: '',
  advId: 0,
  statusConfirmedCar: [],
}
