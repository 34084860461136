import Vue from 'vue';

import {
  SET_QRCODE_SVG,
  CHANGE_LOADING_STATUS,
} from './mutation-types';

export default {
  [SET_QRCODE_SVG](state, payload) {
    Vue.set(state, 'svg', payload);
  },
  [CHANGE_LOADING_STATUS](state, payload) {
    Vue.set(state, 'loading', payload);
  },
};
