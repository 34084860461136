import {
    UPDATE_COUNTRY_PARTNERS,
    UPDATE_AUTO_INTER_CARS,
    UPDATE_AUTO_INTER_BRANDS,
    UPDATE_AUTO_INTER_NEWS
} from "./mutations-types";

export default {
    [UPDATE_AUTO_INTER_CARS]: (state, { data, isDesktop }) => {
        isDesktop || ((data?.loading || data.errors) && !state.autoInterCars?.length) ?
            state.autoInterCars = data
        :
            state.autoInterCars = [...state.autoInterCars, ...data];

    },
    [UPDATE_COUNTRY_PARTNERS]: (state, { data }) => state.countryPartners = data,
    [UPDATE_AUTO_INTER_BRANDS]: (state, { data }) => state.brands = data,
    [UPDATE_AUTO_INTER_NEWS]: (state, {data}) => state.news = data
}
