module.exports = `
query get($ids: [ID], $langId: ID) {
  get(ids: $ids, langId: $langId) {
    id
    title
    uri
    VIN
    brand {
      id
      eng
    }
    model {
      id
      eng
    }
    photos {
      main {
        id
      }
    }
    price {
      main {
        value
        currency {
          sign
          abbreviated
        }
      }
    }
    autotest {
      technicalPackage {
        orderId
        addDate
      }
    }
    race
  }
}
`;
