import {zipObject} from '../../helpers/_lodash';
import _la from './_laxios';

export const MODULE_PREFIX = 'TechnicalCheck';
let state = {
	autotestData: {},
	checkBoxes: {},
	accessDenied: {},
	reportData: {},
	reportPhotos: {}
};

export default {
	state,
	namespaced: true,
	actions: {
		async fetchReviews({ commit }, orderId) {
			const req = _la.reviewsTechCheckExists.expandUrl({ id: orderId });

			const response = await req.request();
			return response;
		},
		async sendReview({ commit, getters, rootGetters }, data) {
			const { 'Common/userData': user } = rootGetters;
			const { autotestData } = getters;

			const payload = {
				text: data.text,
				email: user.userEmail,
				name: user.userName,
				userId: user.userId,
				webId: user.webClientId,
				stationId: autotestData?.stoData?.station_id,
				orderId: autotestData?.orderData?.orderId,
				advertId: autotestData?.autoData?.autoId,
				mark: data.rating,
				type: data.type,
			};

			try {
				const response = await _la.sendReviewTechCheck.request({ data: payload });
				return response;
			} catch(error) {
				console.error(error);
				return { saved: false };
			}
		}
	},
	mutations: {
		set: (state, payload) => {
			try {
				for (let name in payload) {
					name in state
						? (state[name] = payload[name])
						: console.error(`Not correct Data with name <<${name}>> in the state, set default value in store/TechnicalCheck.js; Payload =>`, payload);
				}
			} catch (e) {
				console.error('Set data to store : ', e);
			}

		}
	},
	getters: zipObject(Object.keys(state), Object.keys(state)
		.map((name) => {
			return (state) => {
				return state[name];
			};
		}))
};
