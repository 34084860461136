const _la = require('../_laxios');

module.exports = {
	namespaced: true,
	state: {},
	actions: {
		phoneAccess(_, params) {
			return _la._phoneAccessStat.request({params}).catch(console.error);
		}
	}
};
