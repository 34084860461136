import getGenerationKey from "../helpers/getGenerationKey";

export default {
    allGenerationsData: (state) => state.generations || {},
    generationsByIndexBlock: (state) => (blockIndex, exclude, brandId) => {
        if(!brandId) return [];
        const blockKey = getGenerationKey({blockIndex, exclude, brandId})

        const generationByBlocks = Object.keys(state.generations).reduce((acc, el) => {
            if(el.includes(blockKey)) acc.push(state.generations[el]);
            return acc
        },[])

        return generationByBlocks
    },
    generationsIds(state){
        if(!Object.values(state.generations).length) return null;
        return Object.values(state.generations).reduce((acc, modelData) => {
            modelData.generations.forEach(generation => {
                acc[generation.generationId] = generation
            })
            return acc;
        }, {});
    }
}
