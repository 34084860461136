import Vue from 'vue';
import Router from 'vue-router';
import { stringifyQuery } from '../helpers/stringifyQuery';

Vue.use(Router);

// !!! ВАЖНО !!! Перед добавлением нового пути ознакомься с механизмом работы модуля l10n
const routes = [
  {
    name: 'mainPage',
    path: '',
    meta: { footer: 1 },
    component: () => require.ensure([], () => require('../components/Main/index.vue'), 'main'),
  },
  {
    name: 'mainPageAutointer',
    path: 'autointer/',
    meta: { footer: 1 },
    component: () => require.ensure([], () => require('../components/MainAutointer/index.vue'), 'autointer'),
  },
  {
    name: 'AudioVideoAPP',
    path: 'audio-video-landing/',
    meta: { footer: 0 },
    component: () => require.ensure([], () => require('../components/AudioVideoLanding/index.vue'), 'audio-video-landing'),
  },
  {
    name: 'AuctionLanding',
    path: 'auction-rules/',
    meta: { footer: 0 },
    component: () => require.ensure([], () => require('../components/AuctionLanding/index.vue'), 'auction-rules'),
  },
  {
    name: 'InspectorsRating',
    path: 'internet-inspector-rating/',
    meta: { footer: 0 },
    component: () => require.ensure([], () => require('../components/InspectorsRating/index.vue'), 'internet-inspector-rating'),
  },
  {
    name: 'AdvancedSearch',
    path: 'advanced-search',
    component: () => require.ensure([], () => require('../components/AdvancedSearch/index.vue'), 'advanced-search'),
  },
  {
    path: 'vue/_',
    component: () => require.ensure([], () => require('../components/Test/index.vue'), 'test'),
  },
  {
    name: 'technicalcheckVue',
    path: 'vue/technicalcheck/:orderId(\\d+)/',
    component: () => require.ensure([], () => require('../components/TechnicalCheck/index.vue'), 'technicalcheck'),
  },
  {
    name: 'technicalcheck',
    path: 'technicalcheck/:orderId(\\d+)/',
    component: () => require.ensure([], () => require('../components/TechnicalCheck/index.vue'), 'technicalcheck'),
  },
  {
    name: 'lawyercheckVue',
    path: 'vue/lawyercheck/:orderId(\\d+)/',
    component: () => require.ensure([], () => require('../components/LawyerCheck/index.vue'), 'lawyercheck-vue'),
  },
  {
    name: 'lawyercheck',
    path: 'lawyercheck/:orderId(\\d+)/',
    component: () => require.ensure([], () => require('../components/LawyerCheck/index.vue'), 'lawyercheck'),
  },
  {
    name: 'naischeck',
    path: 'naischeck/:plateNumber/',
    component: () => require.ensure([], () => require('../components/ReportNais/index.vue'), 'naischeck'),
  },
  {
    name: 'company',
    path: ':type(banks|dealers|avto-pod-zakaz|avtovikup)/:name/:id(\\d+)',
    component: () => require.ensure([], () => require('../components/Company2/index.vue'), 'company'),
  },
  {
    name: 'vincheckVue',
    path: 'vue/vincheck/:orderId(\\d+)/',
    component: () => require.ensure([], () => require('../components/VinCheck/index.vue'), 'vincheck-vue'),
  },
  {
    name: 'vincheck',
    path: 'vincheck/:orderId(\\d+)/',
    component: () => require.ensure([], () => require('../components/VinCheck/index.vue'), 'vincheck'),
  },
  {
    name: 'VideoLanding',
    path: 'mms/',
    meta: { footer: 0 },
    component: () => require.ensure([], () => require('../components/VideoLanding/index.vue'), 'mms'),
  },
  {
    name: 'MobileApp',
    path: 'mobile_app/',
    meta: { footer: 1 },
    component: () => require.ensure([], () => require('../components/MobileApp/index.vue'), 'mobile-app'),
  },
  {
    name: 'OrderCar',
    path: 'auto-order',
    component: () => require.ensure([], () => require('../components/LandingOrderCar/index.vue'), 'auto-order'),
    meta: { footer: 0 },
  },
  {
    name: 'LandingDiscounts',
    path: 'autoseller/addpackage',
    component: () => require.ensure([], () => require('../components/LandingDiscounts/index.vue'), 'autoseller-addpackage'),
  },
  {
    path: 'personalCabinet',
    component: () => require.ensure([], () => require('../components/Cabinet/index.vue'), 'personal-cabinet'),
    children: [
      {
        name: 'personalCabinet',
        path: '',
        component: () => require.ensure([], () => require('../components/Cabinet/Main/index.vue'), 'personal-cabinet-main'),
        meta: { footer: 1 },
      },
    ],
  },
  {
    path: 'cabinet',
    component: () => require.ensure([], () => require('../components/Cabinet/index.vue'), 'cabinet'),
    children: [
      {
        name: 'CabinetMain',
        path: '',
        component: () => require.ensure([], () => require('../components/Cabinet/Main/index.vue'), 'cabinet-main'),
        meta: { footer: 1 },
      },
      {
        name: 'CabinetMainDiia',
        path: 'confirm-diia',
        component: () => require.ensure([], () => require('../components/Cabinet/Main/index.vue'), 'cabinet-main-diia'),
        meta: { footer: 1 },
      },
      {
        name: 'MyAdvertisementsPublished',
        path: 'advertisements/active',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/index.vue'), 'my-published'),
        meta: {
          type: 'active',
          parentName: 'MyAdvertisements',
          name_b2cAutoSalon: 'UsedAutoAdvertisementsPublished',
          parentName_b2cAutoSalon: 'UsedAutoAdvertisements',
          footer: 1,
        },
      },
      {
        name: 'MyAdvertisementsUnpayed',
        path: 'advertisements/await_for_payment',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/index.vue'), 'my-unpayed'),
        meta: {
          type: 'awaitForPayment',
          parentName: 'MyAdvertisements',
          footer: 1,
        },
      },
      {
        name: 'MyAdvertisementsDraft',
        path: 'advertisements/draft',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/index.vue'), 'my-draft'),
        meta: {
          type: 'draft',
          parentName: 'MyAdvertisements',
          footer: 1,
        },
      },
      {
        name: 'MyAdvertisementsAUTORIA24',
        path: 'advertisements/24',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/index.vue'), 'my-autoria'),
        meta: {
          type: 'autoBuy',
          parentName: 'MyAdvertisements',
          footer: 1,
        },
      },
      {
        name: 'MyAdvertisementsArchive',
        path: 'advertisements/archive',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/index.vue'), 'my-archive'),
        meta: {
          type: 'archive',
          parentName: 'MyAdvertisements',
          name_b2cAutoSalon: 'UsedAutoAdvertisementsArchive',
          parentName_b2cAutoSalon: 'UsedAutoAdvertisements',
          footer: 1,
        },
      },
      {
        name: 'MyAdvertisementsManage',
        path: 'advertisements/manage',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/Manage/index.vue'), 'my-manage'),
        meta: { footer: 1 },
      },
      {
        name: 'Subscriptions',
        path: 'subscribe',
        component: () => require.ensure([], () => require('../components/Cabinet/Subscribes/index.vue'), 'subscriptions'),
        meta: { footer: 1 },
      },
      {
        name: 'UsedAutoAdvertisementsPublished',
        path: 'advertisements/active',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/index.vue'), 'used-auto-published'),
        meta: {
          type: 'active',
          parentName: 'UsedAutoAdvertisements',
          footer: 1,
        },
      },
      {
        name: 'UsedAutoAdvertisementsUnpayed',
        path: 'advertisements/await_for_payment',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/index.vue'), 'used-auto-unpayed'),
        meta: {
          type: 'awaitForPayment',
          parentName: 'UsedAutoAdvertisements',
          footer: 1,
        },
      },
      {
        name: 'RegularPayments',
        path: 'regular-payments',
        component: () => require.ensure([], () => require('../components/Cabinet/RegularPayments/index.vue'), 'regular-payments'),
        meta: { footer: 1 },
      },
      {
        name: 'UsedAutoAdvertisementsDraft',
        path: 'advertisements/draft',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/index.vue'), 'used-auto-draft'),
        meta: {
          type: 'draft',
          parentName: 'UsedAutoAdvertisements',
          footer: 1,
        },
      },
      {
        name: 'UsedAutoAdvertisementsArchive',
        path: 'advertisements/archive',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/index.vue'), 'used-auto-archive'),
        meta: {
          type: 'archive',
          parentName: 'UsedAutoAdvertisements',
          footer: 1,
        },
      },
      {
        name: 'EditProfile',
        path: 'edit',
        component: () => require.ensure([], () => require('../components/Cabinet/Profile/Edit/index.vue'), 'edit'),
        meta: { footer: 1 },
      },
      {
        name: 'RatingProfile',
        path: 'rating',
        component: () => require.ensure([], () => require('../components/Cabinet/Profile/Rating/index.vue'), 'rating'),
        meta: { footer: 1 },
      },
      //
      // {
      //   name: 'Notifications',
      //   path: 'notifications',
      //   component: () => require.ensure([], () => require('../components/Cabinet/Notifications/index.vue'), 'notifications'),
      //   meta: {
      //     type: 'all',
      //     typeId: 0,
      //     parentName: 'Notifications',
      //     footer: 1,
      //   },
      // },
      {
        name: 'Notifications',
        path: 'notifications',
        component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/index.vue'), 'notifications'),
        meta: {
          type: 'all',
          typeId: 0,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsEmergency',
        path: 'notifications/emergency',
        component: () => require.ensure([], () => require('../components/Cabinet/Notifications/index.vue'), 'notifications-emergency'),
        meta: {
          type: 'emergency',
          typeId: 1,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsImportant',
        path: 'notifications/important',
        component: () => require.ensure([], () => require('../components/Cabinet/Notifications/index.vue'), 'notifications-require'),
        meta: {
          type: 'important',
          typeId: 2,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsInforming',
        path: 'notifications/informing',
        component: () => require.ensure([], () => require('../components/Cabinet/Notifications/index.vue'), 'informing'),
        meta: {
          type: 'informing',
          typeId: 3,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsArchive',
        path: 'notifications/archive',
        component: () => require.ensure([], () => require('../components/Cabinet/Notifications/index.vue'), 'notifications-archive'),
        meta: {
          type: 'archive',
          typeId: 4,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsSettings',
        path: 'notifications/settings',
        component: () => require.ensure([], () => require('../components/Cabinet/Notifications/Settings/index.vue'), 'notifications-settings'),
        meta: {
          type: 'settings',
          typeId: 5,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      // TODO del after test
      // {
      //   name: 'NotificationsV2',
      //   path: 'notificationsV2',
      //   component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/index.vue'), 'notifications'),
      //   meta: {
      //     type: 'all',
      //     typeId: 0,
      //     parentName: 'Notifications',
      //     footer: 1,
      //   },
      // },
      {
        name: 'NotificationsActions',
        path: 'notifications/actions',
        component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/index.vue'), 'notifications-actions'),
        meta: {
          type: 'actions',
          categoryId: 1,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsSelling',
        path: 'notifications/selling',
        component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/index.vue'), 'notifications-selling'),
        meta: {
          type: 'selling',
          categoryId: 2,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsBuying',
        path: 'notifications/buying',
        component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/index.vue'), 'notifications-buying'),
        meta: {
          type: 'buying',
          categoryId: 3,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsFavorites',
        path: 'notifications/favorites',
        component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/index.vue'), 'notifications-favorites'),
        meta: {
          type: 'favorites',
          categoryId: 4,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsInform',
        path: 'notifications/inform',
        component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/index.vue'), 'notifications-inform'),
        meta: {
          type: 'inform',
          categoryId: 5,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsEmergencyV2',
        path: 'notifications/emergencyV2',
        component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/index.vue'), 'notifications-emergency'),
        meta: {
          type: 'emergency',
          typeId: 1,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsImportantV2',
        path: 'notifications/importantV2',
        component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/index.vue'), 'notifications-require'),
        meta: {
          type: 'important',
          typeId: 2,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsInformingV2',
        path: 'notifications/informingV2',
        component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/index.vue'), 'informing'),
        meta: {
          type: 'informing',
          typeId: 3,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsArchiveV2',
        path: 'notifications/archiveV2',
        component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/index.vue'), 'notifications-archive'),
        meta: {
          type: 'archive',
          typeId: 4,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'NotificationsSettingsV2',
        path: 'notifications/settingsV2',
        component: () => require.ensure([], () => require('../components/Cabinet/NotificationsV2/Settings/index.vue'), 'notifications-settings'),
        meta: {
          type: 'settings',
          typeId: 5,
          parentName: 'Notifications',
          footer: 1,
        },
      },
      {
        name: 'MyCompanyCallsJournal',
        path: 'calls-journal',
        component: () => require.ensure([], () => require('../components/Cabinet/CallsJournal/index.vue'), 'calls-journal'),
        meta: {
          parentName: 'MyCompany',
          footer: 1,
        },
      },
      {
        name: 'CompanyStatistic',
        path: 'company/statistic',
        component: () => require.ensure([], () => require('../components/Cabinet/CompanyStatistic/index.vue'), 'company-statistic'),
        meta: {
          parentName: 'MyCompany',
          footer: 1,
        },
      },
      {
        name: 'PlacementEfficiency',
        path: 'efficiency-placement',
        component: () => require.ensure([], () => require('../components/Cabinet/PlacementEfficiency/index.vue'), 'efficiency-placement'),
        meta: {
          parentName: 'MyAdvertisements',
          footer: 1,
        },
      },
      {
        name: 'CallsJournal',
        path: 'calls-journal',
        component: () => require.ensure([], () => require('../components/Cabinet/CallsJournal/index.vue'), 'calls-journal'),
        meta: { footer: 1 },
      },
      {
        name: 'MyAdvertisementsPublicationsReport',
        path: 'publications/statistic',
        component: () => require.ensure([], () => require('../components/Cabinet/PublicationsStatistic/index.vue'), 'publications-statistic'),
        meta: {
          parentName: 'MyAdvertisements',
          footer: 1,
        },
      },
      {
        name: 'AutoSaleSalonPublicationsReport',
        path: 'publications/statistic',
        component: () => require.ensure([], () => require('../components/Cabinet/PublicationsStatistic/index.vue'), 'publications-statistic'),
        meta: {
          parentName: 'AutoSaleSalon',
          footer: 1,
        },
      },
      {
        name: 'AutoSaleSalonAdvertisementsControl',
        path: 'advertisements/manage',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/Manage/index.vue'), 'auto-sale-salon-manage'),
        meta: {
          parentName: 'AutoSaleSalon',
          footer: 1,
        },
      },
      {
        name: 'Styleguide',
        path: 'styleguide',
        component: () => require.ensure([], () => require('../components/Cabinet/Styleguide/index.vue'), 'styleguide'),
        meta: { footer: 1 },
      },
      {
        name: 'HistoryPayment',
        path: 'history-payment',
        component: () => require.ensure([], () => require('../components/Cabinet/HistoryPayment/index.vue'), 'history-payment'),
        meta: { footer: 1 },
      },
      {
        name: 'Promotion',
        path: 'layout/promotion',
        component: () => require.ensure([], () => require('../components/Cabinet/Layout__Promotion/index.vue'), 'promotion'),
        meta: { footer: 1 },
      },
      {
        name: 'ManagementCommercialServices',
        path: 'managements',
        component: () => require.ensure([], () => require('../components/Cabinet/ManagementCommercialServices/index.vue'), 'managements'),
        meta: { footer: 1 },
      },
      {
        name: 'AdvertisementsStatistics',
        path: 'advertisements/statistics/:advertisementId',
        component: () => require.ensure([], () => require('../components/Cabinet/Advertisements/Statistics/index.vue'), 'statistics'),
        meta: {
          footer: 1,
          parentName: 'MyAdvertisements',
        },
      },
      {
        name: 'Customization',
        path: 'customization',
        component: () => require.ensure([], () => require('../components/Cabinet/Customization/index.vue'), 'customization'),
        meta: { footer: 1 },
      },
      {
        name: 'UserCallsAuction',
        path: 'usercallsauction',
        component: () => require.ensure([], () => require('../components/Cabinet/UserCallsAuction/index.vue'), 'user-calls-auction'),
        meta: { footer: 1 },
      },
      {
        name: 'Auction',
        path: 'auction',
        component: () => require.ensure([], () => require('../components/Cabinet/Auction/index.vue'), 'auction'),
        meta: { footer: 1 },
      },
      {
        name: 'AutoCheck',
        path: 'reports',
        component: () => require.ensure([], () => require('../components/Cabinet/AutoCheck/index.vue'), 'reports'),
        meta: {
          parentName: 'AutoCheck',
          footer: 1,
        },
      },
      {
        name: 'MyChecks',
        path: 'my-checks',
        component: () => import('../components/Cabinet/MyChecks/index.vue'),
        meta: { footer: 1 },
      },
      {
        name: 'InspectionPackages',
        path: 'inspection-packages',
        component: () => import('../components/Cabinet/InspectionPackages/index.vue'),
        meta: { footer: 1 },
      },
      {
        name: 'EditCompany',
        path: 'edit-company',
        component: () => require.ensure([], () => require('../components/Cabinet/EditCompany/index.vue'), 'edit-company'),
        meta: { footer: 1 },
      },
      {
        name: 'SpecifyСards',
        path: 'cards',
        component: () => require.ensure([], () => require('../components/Cabinet/SpecifyCards/index.vue'), 'cards'),
        meta: { footer: 1 },
      },
    ],
  },
  {
    name: 'check_selection',
    path: 'check_selection/:advertisementId',
    component: () => require.ensure([], () => require('../components/CheckCar_v2/index.vue'), 'check-selection'),
  },
  {
    name: 'StepCongratulations',
    path: 'addauto/congratulations/:autoId(\\d+)',
    component: () => require.ensure([], () => require('../components/StepCongratulations/index.vue'), 'step-congratulations'),
  },
  {
    name: 'StepPublication',
    path: 'addauto/v2/(publication|republication)/autoId/:autoId(\\d+)',
    component: () => require.ensure([], () => require('../components/StepPublication/index.vue'), 'republication'),
  },
  {
    name: 'StepCongratulationsVue',
    path: '/vue/addauto/congratulations/:autoId(\\d+)',
    component: () => require.ensure([], () => require('../components/StepCongratulations/index.vue'), 'congratulations'),
  },
  {
    name: 'Autotest2',
    path: 'autocheck2',
    component: () => require.ensure([], () => require('../components/Autotest/index.vue'), 'autocheck2'),
  },
  {
    name: 'Autotest',
    path: 'autocheck',
    component: () => require.ensure([], () => require('../components/Autotest/index.vue'), 'autocheck'),
  },

  {
    name: 'Estimator',
    path: 'estimator',
    meta: {
      estimatorId: 1,
    },
    component: () => require.ensure([], () => require('../components/Estimator/index.vue'), 'estimator'),
  },
  {
    name: 'AutoEstimator',
    path: 'autoEstimator',
    meta: {
      estimatorId: 2,
    },
    component: () => require.ensure([], () => require('../components/Estimator/index.vue'), 'auto-estimator'),
  },
  {
    name: 'IsReseller',
    path: 'is-reseller',
    meta: {
      estimatorId: 3,
      withoutAdditionalOptions: true,
    },
    component: () => require.ensure([], () => require('../components/Estimator/index.vue'), 'is-reseller'),
  },
  {
    name: 'AutoBuy',
    path: 'autoBuy/:id(\\d+)',
    component: () => require.ensure([], () => require('../components/AutoBuy/index.vue'), 'auto-buy'),
    meta: { footer: 1 },
  },
  {
    name: 'BuyoutStatus',
    path: 'buyout-status/:id(\\d+)',
    component: () => require.ensure([], () => require('../components/AutoRia24/SecondStep/index.vue'), 'buyout-status'),
    meta: { footer: 1 },
  },
  {
    name: 'AutoRia24',
    path: '24',
    component: () => require.ensure([], () => require('../components/AutoRia24/Main/index.vue'), 'auto-ria'),
    meta: { footer: 1 },
  },
  {
    name: 'AutoRia24Partners',
    path: '24/partners/',
    component: () => require.ensure([], () => require('../components/AutoRia24/partners/index.vue'), 'partners'),
    meta: { footer: 1 },
  },
  {
    name: 'AutoRia24PlateNumber',
    path: '24/:plateNumber',
    component: () => require.ensure([], () => require('../components/AutoRia24/CompletedForm/index.vue'), 'plate-number'),
    meta: { footer: 1 },
  },
  {
    name: 'AutoAuction',
    path: 'auction/status/:autoId',
    component: () => require.ensure([], () => require('../components/AutoAuction/index.vue'), 'auction-status'),
    meta: { footer: 1 },
  },
  {
    name: 'avgPriceCalc',
    path: 'average-price-calculator/',
    component: () => require.ensure([], () => require('../components/avgPriceCalc/index.vue'), 'average-price-calculator'),
    meta: { footer: 1 },
  },
  {
    name: 'AveragePrice',
    path: 'price/average/',
    component: () => require.ensure([], () => require('../components/AveragePrice/index.vue'), 'average'),
    meta: { footer: 1 },
  },
  {
    name: 'NewBrandModel',
    path: 'new-brand-model',
    component: () => require.ensure([], () => require('../components/NewBrandModel/index.vue'), 'new-brand-model'),
  },
  {
    name: 'Rules',
    path: 'agreement/buyer',
    component: () => require.ensure([], () => require('../components/Rules/index.vue'), 'rules'),
  },
  {
    name: 'AboutTop',
    path: 'help/position',
    component: () => require.ensure([], () => require('../components/AboutTop/index.vue'), 'position'),
    meta: { footer: 1 },
  },
  {
    name: 'PromoView',
    path: 'help/promo-view',
    component: () => require.ensure([], () => require('../components/PromoView/index.vue'), 'promo-view'),
    meta: { footer: 1 },
  },
  {
    name: 'AuctionID',
    path: 'auction/:id(\\d+)',
    component: () => require.ensure([], () => require('../components/FinalPage/index.vue'), 'auction-id'),
    meta: { footer: 1 },
  },
  {
    name: 'PublicContractSeller',
    path: 'public-contract/seller',
    component: () => require.ensure([], () => require('../components/PublicContracts/Seller/index.vue'), 'seller'),
    meta: { footer: 0 },
  },
  {
    name: 'PublicContractBuyer',
    path: 'public-contract/buyer',
    component: () => require.ensure([], () => require('../components/PublicContracts/Buyer/index.vue'), 'buyer'),
    meta: { footer: 0 },
  },
  {
    name: 'PublicContractResponsibility',
    path: 'public-contract/responsibility',
    component: () => require.ensure([], () => require('../components/PublicContracts/Responsibility/index.vue'), 'responsibility'),
    meta: { footer: 0 },
  },
  {
    name: 'AdvantagesPostingLanding',
    path: 'best-place',
    component: () => require.ensure([], () => require('../components/AdvantagesPostingLanding/index.vue'), 'best-place'),
    meta: { footer: 0 },
  },
  {
    name: 'ListSTO',
    path: 'sto',
    component: () => require.ensure([], () => require('../components/STO/index.vue'), 'sto'),
  },
  {
    name: 'Dealer',
    path: 'company/add/dealer',
    component: () => require.ensure([], () => require('../components/LandingPageDealer/index.vue'), 'dealer'),
  },
  {
    name: 'OrderCarTest',
    path: 'new-test/auto-order',
    component: () => require.ensure([], () => require('../components/LandingOrderCar/index.vue'), 'order-car'),
    meta: { footer: 0 },
  },
  {
    name: 'SellerEstimate',
    path: 'seller-estimate',
    component: () => require.ensure([], () => require('../components/SellerEstimate/index.vue'), 'seller-estimate'),
  },
  {
    name: 'Marketplace',
    path: 'marketplace/:id(\\d+).html',
    component: () => require.ensure([], () => require('../components/Marketplace/index.vue'), 'marketplace'),
  },
  {
    name: 'CompareCar',
    path: 'compare/:link',
    component: () => require.ensure([], () => require('../components/CompareCar/index.vue'), 'compare'),
    meta: { footer: 1 },
  },
  {
    name: 'Notepad',
    path: 'new-notepad',
    component: () => require.ensure([], () => require('../components/Notepad/index.vue'), 'new-notepad'),
  },
  {
    name: 'SuccessInternetInspectors',
    path: 'success-internet-inspectors',
    component: () => require.ensure([], () => require('../components/SuccessInternetInspectors/index.vue'), 'success-inspectors'),
    meta: { footer: 0 },
  },
  {
    name: 'AutoDlyaZSU',
    path: 'auto-for-zsu',
    component: () => require.ensure([], () => require('../components/LandingForZSU/index.vue'), 'auto-for-zsu'),
    meta: { footer: 0 },
  },
  {
    name: 'CentrPerevirok',
    path: 'verification-center/',
    component: () => require.ensure([], () => require('../components/LandingVerificationCenter/index.vue'), 'verification-center'),
    meta: { footer: 0 },
  },
];

export default function createRouter(target, { context } = {}) {
  return new Router({
    mode: 'history',
    stringifyQuery,
    routes: [
      {
        path: '/:langCode(uk)?',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: routes,
      },
    ],
  });
}
