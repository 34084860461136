import {mapGetters} from 'vuex';

module.exports = {
	name: 'router-link-wrapper',
	props: {
		to: {
			type: [String, Object],
			required: true
		}
	},
	computed: {
		...mapGetters({
			langId: 'lang/id'
		})
	},
	render(createElement) {
		let to = this.to;
		if (Number(this.langId) === 4) {
			if (!to.params) {
				to.params = {};
			}
			to.params.langCode = 'uk';
		}
		return createElement('router-link', {
			props: {
				to
			}
		}, this.$slots.default);
	}
};
