import Vue from 'vue';
import _laxios from '../../_laxios';

export default {
  namespaced: true,
  state: {
    lib: {},
    popular: {},
    suggests: [],
  },
  actions: {
    fetchByCategory({ commit, rootGetters }, { category, orderCount = 0, limit = 10000 } = {}) {
      const { 'lang/id': langId } = rootGetters;
      const params = {
        lang_id: langId, category_id: category, orderCount, limit,
      };
      return _laxios.bransForCategory_newAuto.request({ params })
        .then((response = {}) => {
          const { marks } = response;
          if (marks) {
            commit('save', { payload: marks, langId });
          }
        })
        .catch(() => console.error('Ошибка. марки. новые'));
    },
    fetchPopularByCategory: ({ commit, getters }, id = 0) => {
      _laxios.popularBrandsNew.stay = () => Array.isArray(getters.popular(id));
      return _laxios.popularBrandsNew.request({ params: { id } })
        .then((response = {}) => {
          if (response) {
            commit('popular', { payload: response.marka, id });
          }
        })
        .catch((error) => console.error('Ошибка. популярные марки:', _laxios.popularBrandsNew.query.url, error.message));
    },
    suggest({ commit }, { text = '', category }) {
      const params = {
        q: text,
        'context[category]': category,
        fields: 'name,value,count,category',
      };
      Vue.http.get('/api/suggest/brands_new', { params })
        .then(({ body } = {}) => {
          if (Array.isArray(body)) {
            commit('suggest', body);
            const brands = body.reduce((result, item = {}) => {
              const { value, category: { 0: categoryResponse } = [] } = item;
              item.category = category || categoryResponse;
              result[value] = item;
              return result;
            }, {});
            commit('save', brands);
          }
        });
    },
  },
  mutations: {
    save(state, { payload } = {}) {
      Object
        .values(payload)
        .forEach((item = {}) => {
          const { marka_id: id } = item;
          Vue.set(state.lib, id, item);
        });
    },
    popular(state, { id, payload } = {}) {
      if (Array.isArray(payload)) {
        Vue.set(state.popular, id, payload);
      }
    },
    suggest(state, payload) {
      state.suggests.splice(0, state.suggests.length, ...payload);
    },
  },
  getters: {
    getAll: (state) => state.lib,
    popular(state) {
      return function (id) {
        return state.popular[id];
      };
    },
    getByCategory(state, getters) {
      return function (category) {
        return category
          ? Object
            .keys(state.lib)
            .reduce((result, key) => {
              const { [key]: { main_category: itemCategory } = {} } = state.lib;
              if (itemCategory === category) {
                result[key] = state.lib[key];
              }
              return result;
            }, {})
          : getters.getAll;
      };
    },
    suggests: (state) => state.suggests,
  },
};
