import { mapActions } from 'vuex';

export default {
	name: 'Account',
	props: {
		account: Object,
		id: Number,
		reLogin: Function,
	},
	data() {
		return {
			showSingInModal: false,
			showCloseModal: false,
		};
	},
	computed: {
		phoneOrEmail() {
			return this._result(this.account, 'user_phones', [])[0] || this._result(this.account, 'email_normalized_orig', '');
		},
		userName() {
			return this._result(this.account, 'first_name') || this._result(this.account, 'second_name') ? `${this._result(this.account, 'first_name') || ''} ${this._result(this.account, 'second_name') || ''}` : this.$t('Немає імені')
		},
	},
	methods: {
		...mapActions({
			deleteUserFromGroup_: 'accountSwitches/deleteUserFromGroup',
		}),
		getImgSrc(url) {
			return url ? `https://cdn.riastatic.com/photos/${url.replace(/(\.jpg|\.png)/, 'c$1')}` : 'https://img6.auto.ria.com/images/nophoto/avatar-50x50.png';
		},
		deleteUserFromGroup() {
			const { user_id: userId = 0 } = this.account;
			this.deleteUserFromGroup_({userId});
			this.slonikElevator(1, 2);
		},
		closeSignInModal() {
			this.showSingInModal = false;
			this.slonikElevator(2, 1);
		},
		closeCloseModal() {
			this.showCloseModal = false;
			this.slonikElevator(2, 2);
		},
		slonikElevator(clickAction, actionBlock) {
			this.$emit('slonikElevator', clickAction, actionBlock);
		},
	},
	i18n: {
		messages: {
			uk: {
				'Виберіть дію': 'Виберіть дію',
				'Увійти': 'Увійти',
				'Видалити': 'Видалити',
				'Відмінити': 'Відмінити',
				'Видалити цей аккаунт зі списку': 'Видалити цей аккаунт зі списку',
				'Немає імені': 'Немає імені',
			},
			ru: {
				'Виберіть дію': 'Выберите действие',
				'Увійти': 'Войти',
				'Видалити': 'Удалить',
				'Відмінити': 'Отменить',
				'Видалити цей аккаунт зі списку': 'Удалить этот аккаунт из списка',
				'Немає імені': 'Нет имени',
			}
		}
	}
};
