export default function () {
    return {
        loader: false,
        responseAveragePrice: {
            responseType: null,
            notices: null,
            chipsData: null,
            searchUrl: null,
            statistics: null,
            chartData: null,
            similarCars: null,
            metaData: null,
        }
    }
}
