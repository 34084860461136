(function () {
	module.exports = {get, set, unset, deleted};

    function set(name, value, options) {
      options = Object.assign({
        path: '/',
        sameSite: 'none',
        secure: true
      }, options);

      var expires = options.expires;

      if (typeof expires === 'number' && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
      }
      if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
      }

      value = encodeURIComponent(value);

      var updatedCookie = name + "=" + value;

      for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
          updatedCookie += "=" + propValue;
        }
      }

      document.cookie = updatedCookie;
    }

	function unset(name, options) {
		set(name, "", Object.assign({
			expires: -1
		}, options))
	}

    function deleted(name, path, domain, options) {
      document.cookie = name + "=deleted; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=" + (path || '/') + "; domain=" + (domain || document.domain) + ";" + options;
    }

	function get(name, cookie) {
		let cook = cookie || document.cookie;
		var matches = cook.match(new RegExp(
			"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}
})();

