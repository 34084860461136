module.exports = ({origin} = {}) => {
	return {
		query: {
			method: 'POST',
			url: `${origin}/graphql/`,
			headers: {
				'Content-type': 'application/json'
			},
			timeout: 8000
		}
	};
};
