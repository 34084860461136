module.exports  = {
    bind (el, binding, vnode) {
        document.body.addEventListener('click', (event) => {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        })
    },
    unbind() {
        document.body.removeEventListener('click', () => {})
    },
}
