import {mapGetters} from 'vuex';

export default {
	computed: {
		...mapGetters({
			langCode: 'lang/code'
		})
	},
	methods: {
		setLocale(value) {
			this.m.locale(value);
		}
	},
	watch: {
		langCode(value) {
			this.setLocale(value);
		}
	},
	created() {
		this.setLocale(this.langCode);
	}
};
