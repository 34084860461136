import getSSIInclude from './getSSIInclude';

export default {
    name: 'HeaderMessages',
    computed: {
        rawHtml() {
            return getSSIInclude({langId: this.langId});
        }
    }
};
