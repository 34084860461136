import _laxios from '../../_laxios';

export const STORAGE_KEY = 'searchHistoryV2';

export default {
	namespaced: true,
	state: {
		lastSearchesServiceStatus: true,
		lastSearches: [],
	},
	actions: {
		fetchLastSearches({commit, rootGetters}, {limit = 2} = {}) {
			try {
				const localSearches = JSON.parse(localStorage.getItem(STORAGE_KEY));
				let {'lang/id': langId} = rootGetters;
				return _laxios.searchHistoryLast.request({data: localSearches, params: {langId, limit}})
					.then((response) => commit('saveLastSearches', response))
					.catch(error => {
						commit('setLastSearchesServiceStatus', false);
						console.error('Ошибка получения последних поисков', error);
					})
				;
			} catch (error) {
				// console.error(error); // Не выводим, чтобы не пугать google
			}
		}
	},
	mutations: {
		setLastSearchesServiceStatus: (state, payload)=> {
			state.lastSearchesServiceStatus = payload;
		},
		saveLastSearches: (state, payload) => {
			if (Array.isArray(payload)) {
				const lastSearches = state.lastSearches;
				lastSearches.splice(0, lastSearches.length, ...payload)
			}
		},
	},
	getters: {
		lastSearchesServiceStatus: ({lastSearchesServiceStatus} = {}) => lastSearchesServiceStatus,
		lastSearchesFilled: ({lastSearches} = {}) => Array.isArray(lastSearches) ? lastSearches : [],
	}
}
