export default {
    autoInterCars: (state) => state.autoInterCars || [],
    autoInterCarsLoading: (state) => state.autoInterCars?.loading || false,
    countryPartners: (state) => state.countryPartners || [],
    countryPartnersLoading: (state) => state.countryPartners?.loading || false,
    brands: (state) => state.brands || [],
    brandsLoading: (state) => state.brands?.loading || false,
    news: (state) => state.news || [],
    newsLoading: (state) => state.news?.loading || false,
}
