/* eslint-disable prefer-rest-params,no-underscore-dangle,global-require,
import/no-extraneous-dependencies,no-param-reassign */
import _laxios from '../store/_laxios';

export default {
  mixins: [
    require('./lang'),
  ],
  data: () => ({
    m: require('moment'),
  }),
  methods: {
    t: () => {
      console.error('Ошибка при попытке перевода');
      console.error('Использование устаревшего метода');
      return '';
    },
    gaEvent() {
      const gaParams = {};
      const [category, action, label] = arguments;
      if (category) gaParams.log_ga_universal_category = category;
      if (action) gaParams.log_ga_universal_action = action;
      if (label) gaParams.log_ga_universal_label = label;
      this.$gtag.event('log_ga_universal', gaParams);
    },
    _slonik(data = {}) {
      _laxios._slonik.request({ data }).catch((e) => console.error('=== ERROR in _slonik ===', e.toString()));
      const {
        event_id: eventId,
        action_block: actionBlock,
        click_action: clickAction,
        request_type: requestType,
        block_type: blockType,
        is_pop_up: isPopup,
      } = data;

      if (eventId) {
        const gaParams = {};
        gaParams.log_clickhouse_event_id = eventId;
        if (actionBlock) gaParams.log_clickhouse_action_block = actionBlock;
        if (clickAction) gaParams.log_clickhouse_click_action = clickAction;
        if (requestType) gaParams.log_clickhouse_request_type = requestType;
        if (blockType) gaParams.log_clickhouse_block_type = blockType;
        if (isPopup) gaParams.log_clickhouse_is_popup = isPopup;
        if (actionBlock || clickAction || requestType || isPopup || blockType) {
          this.$gtag.event('log_clickhouse', gaParams);
        }
      }
    },
    days(value) {
      const dateValue = new Date(value);
      if (new Date() <= dateValue) {
        return (Math.floor((dateValue - new Date()) / 1000 / 60 / 60 / 24)) || 1;
      }
      return 0;
    },
    dateFormat(value) {
      const locale = this.$store.getters['lang/code'];
      const dayFormatter = new Intl.DateTimeFormat(locale, {
        day: 'numeric',
      });
      const monthFormatter = new Intl.DateTimeFormat(locale, {
        month: 'short',
      });
      const currYear = new Date().getFullYear();
      const year = new Date(value).getFullYear();
      return `${dayFormatter.format(value)} ${String(monthFormatter.format(value)).replace('.', '').substring(0, 3)}${year === currYear ? '' : ` ${year}`}`;
    },
    formatNumber(num = 0) {
      const convertedNum = Number(num) || 0;
      return Math.ceil(convertedNum).toLocaleString().replace(/,/g, ' ');
    },
    _result(object, path, placeholder) {
      const pathArray = path.split('.');
      pathArray.forEach((keyName) => {
        if (object === undefined || object === null
            || object[keyName] === undefined || object[keyName] === null) {
          object = placeholder;
        } else {
          object = object[keyName];
        }
      });

      return object;
    },
    _first(array, n) {
      return n === undefined ? array[0] : array.slice(0, n);
    },
    groupByKey(array, key) {
      return array.reduce((acc, item) => {
        acc[item[key]] = acc[item[key]] || [];
        acc[item[key]].push(item);
        return acc;
      }, {});
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters['shared/device/isMobile'];
    },
    isDesktop() {
      return this.$store.getters['shared/device/isDesktop'];
    },
  },
  mounted() {
    try {
      if (window) window.gaEvent = this.gaEvent;
    } catch (e) {
      console.error('=== ERROR in STUB[gaEvent] Footer SSI ===', e.toString());
    }
  },
};
