import {
    SET_AVERAGE_PRICE_CHIPS_DATA,
    SET_AVERAGE_PRICE_STATISTIC_INFO,
    SET_AVERAGE_PRICE_CHART_DATA,
    SET_AVERAGE_PRICE_SIMILAR_CARS_DATA,
    SET_AVERAGE_PRICE_RESPONSE_TYPE,
    RESET_AVERAGE_PRICE_RESPONSE,
    SET_AVERAGE_PRICE_NOTICES,
    SET_LOADER_STATE,
    SET_AVERAGE_PRICE_META_DATA,
} from "./mutations-types";

export default {
    [SET_LOADER_STATE]: (state, payload) => state.loader = payload,
    [SET_AVERAGE_PRICE_CHIPS_DATA]: (state, payload) => {
        state.responseAveragePrice.chipsData = payload;
    },
    [SET_AVERAGE_PRICE_NOTICES]: (state, payload) => {
        state.responseAveragePrice.notices = payload;
    },
    [SET_AVERAGE_PRICE_STATISTIC_INFO]: (state, payload) => {
        state.responseAveragePrice.statistics = payload;
    },
    [SET_AVERAGE_PRICE_CHART_DATA]: (state, payload) => {
        state.responseAveragePrice.chartData = payload;
    },
    [SET_AVERAGE_PRICE_SIMILAR_CARS_DATA]: (state, payload) => {
        state.responseAveragePrice.similarCars = payload;
    },
    [SET_AVERAGE_PRICE_RESPONSE_TYPE]: (state, payload) => {
        state.responseAveragePrice.responseType = payload;
    },
    [RESET_AVERAGE_PRICE_RESPONSE]: (state) => {
        Object.keys(state.responseAveragePrice).map(el => {
            state.responseAveragePrice[el] = null
        })
    },
    [SET_AVERAGE_PRICE_META_DATA]: (state, payload) => {
        state.responseAveragePrice.metaData = payload;
    },
}
