const vinPlusItems = [
    'Основные характеристики и данные о комплектации',
    'История всех зафиксированных пробегов и проверка на скручивание',
    'ДТП в Украине, Европе, США и Южной Корее',
    'Наличие страхового полиса',
    'История регистраций в Украине, США и Европе',
    'Проверка количества собственников в Украине, США и Европе',
    'Угоны, аресты и другие обременения'
];

const checkItems = {
    1: vinPlusItems,
    2: [
        ...vinPlusItems,
        'Ремонты у официального дилера',
        'Сервисное обслуживание'
    ],
    5: [
        'Історія всіх страхових випадків (КАСКО, ОСЦПВ)',
        'Суми й дати виплат чи відмови від відшкодування збитків'
    ]
};

const prices = {
    vinPlus: {
        id: 1,
        checkName: 'vinPlus',
        price: 450,
        cost: 300,
        name: 'VIN +',
        type: 1,
        key: 'vinPlus',
        profit: 150,
    },
    vinMax: {
        id: 2,
        checkName: 'vinMax',
        price: 850,
        cost: 700,
        name: 'VIN MAX',
        type: 2,
        key: 'vinMax',
        profit: 150,
    },
    carfax: {
        id: 3,
        checkName: 'carFax',
        price: 85,
        cost: 49.06,
        name: 'CARFAX',
        type: 3,
        key: 'carfax',
        profit: 35.94,
    },
    autocheck: {
        id: 4,
        checkName: 'autoCheck',
        price: 85,
        cost: 49.06,
        name: 'AUTOCHECK',
        type: 4,
        key: 'autocheck',
        profit: 35.94,
    },
    pvbki: {
        id: 5,
        checkName: 'insurance',
        price: 150,
        cost: 100,
        name: 'INSURANCE',
        type: 5,
        key: 'pvbki',
        profit: 50,
    },
};

export default {
    checkItems,
    prices,
}
