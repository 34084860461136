import {
    UPDATE_SEARCH_FIELD_VALUE,
    UPDATE_SEARCH_FIELD_LIST,
    RESET_SEARCH_FIELD_LIST,
    RESET_SEARCH_FIELD_VALUE,
} from "../mutations/mutations-types";
import _laxios from "../../../../_laxios";
import normalizeAutoInterSearchFieldData from "./normalize";
import getAutoInterSearchRequestParams from "./fieldRequestParams";



export default {
    async fetchFieldData(ctx, field) {
        const { commit } = ctx
        const params = getAutoInterSearchRequestParams(ctx, field)

        let data
        if(params) {
            try{
                const response = await _laxios
                    .autoInterSearch
                    .request(params)
                data = normalizeAutoInterSearchFieldData(field, response.data)
            } catch(e) {
                data = { errors: 'Error: AutoInterFields. Error fetch field list data.' }
            } finally {
                commit(UPDATE_SEARCH_FIELD_LIST, { field: field, data })
            }
        }
    },

    updateSearchFieldValue ({state, commit, dispatch}, payload) {
        const concatFieldsList = [
            'region',
            'price',
            'year'
        ]
        payload.concatFlag = concatFieldsList.includes(payload.field) && !!payload.value

        dispatch('resetDependentFields', payload.field)

        commit(UPDATE_SEARCH_FIELD_VALUE, payload)

        payload.value && dispatch('fetchDependentFieldData', payload.field)
    },

    async fetchDependentFieldData({state, dispatch}, field) {
        // Виконуємо запит даних для поля яке залежить від вхідного поля
        const dependencyOn = state[field].dependencies.to
        if(!dependencyOn.length) return;

        dependencyOn.forEach((el) => {
            dispatch('fetchFieldData', el )
        })
    },

    resetDependentFields({state, commit, dispatch}, field) {
        // Виконуємо очищення даних при зміні поля від якого залежало поле
        const dependencyOn = state[field].dependencies.to

        if(!dependencyOn.length) return;
        dependencyOn.forEach((el) => {
            const value = state[el].value
            if (value) {
                commit(RESET_SEARCH_FIELD_VALUE, el)
            }
            commit(RESET_SEARCH_FIELD_LIST, el)
            dispatch('resetDependentFields', el)
        })
    },

    resetSearchForm({state, commit, dispatch}) {
        const omitResetValueList = ['searchType'];
        let needClearValue = Object.values(state).filter(el => !omitResetValueList.includes(el.name)); // поля які потрібно очистити за виключенням вказаних в omitResetValueList

        needClearValue.forEach(({name}) => { //очищення полей
            if(state[name].value) {
                commit(RESET_SEARCH_FIELD_VALUE, name)
            }
        })

    },
}

