import {
  SET_MAIN_BANNER_DATA,
  SET_MAIN_BRK_DATA,
  SET_MAIN_TODAY_DATA,
} from './mutation-types';

export default {
  [SET_MAIN_BANNER_DATA](state, payload) {
    state.mainBanner = payload;
  },
  [SET_MAIN_BRK_DATA](state, payload) {
    state.mainBRK = payload;
  },
  [SET_MAIN_TODAY_DATA](state, payload) {
    state.mainToday = payload;
  },
};
